import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Chip,
  Tooltip,
  Pagination,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Drawer,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChatIcon from '@mui/icons-material/Chat';
import loaderImage from '../assects/Images/Logos/CRVlogo.png'; // Ensure the path is correct
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import TopBar from './TopBar';
import SideBar from './SideBar';

const drawerWidth = 240;

// Styled Main component for layout
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const ManagerSpecialApproved = () => {
  const [open, setOpen] = useState(true);
  const [requests, setRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [expandedRequests, setExpandedRequests] = useState({});
  const [loading, setLoading] = useState(true);
  const [requestDetails, setRequestDetails] = useState({});
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Fetch approved special requests from API
  const fetchRequests = async () => {
    const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
    const jwtToken = userDetailData?.jwt_token;

    if (!jwtToken) {
      console.error('No JWT token found');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}C01SpecialRequestsList`,
        { request_type: 2 }, // Fetch approved requests
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request) {
        setRequests(response.data.DATA.request);
        setTotalCount(response.data.DATA.total_count);
      } else {
        console.warn('No request data found in response.');
      }
    } catch (error) {
      console.error('Failed to fetch requests:', error);
      setSnackbarMessage('Failed to fetch requests. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch request details based on request ID
  const fetchRequestDetails = async (request_id) => {
    const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
    const jwtToken = userDetailData?.jwt_token;

    if (!jwtToken) {
      console.error('No JWT token found');
      setSnackbarMessage('Authentication token missing. Please log in again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    try {
      const response = await axios.post(
        `${API_BASE_URL}C01SpecialRequestDetail`,
        { request_id },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA) {
        setRequestDetails((prevDetails) => ({
          ...prevDetails,
          [request_id]: response.data.DATA, // Store details by request ID
        }));
      } else {
        console.warn(`No data found for request_id: ${request_id}`);
      }
    } catch (error) {
      console.error('Failed to fetch request details:', error);
      setSnackbarMessage('Failed to fetch request details.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  // Handle expand/collapse
  const handleExpandClick = (request_id) => {
    setExpandedRequests((prev) => ({
      ...prev,
      [request_id]: !prev[request_id],
    }));

    // Fetch request details when expanding
    if (!expandedRequests[request_id]) {
      fetchRequestDetails(request_id); // Only fetch if expanding
    }
  };

  // Handle live search functionality
  const filteredRequests = requests.filter((request) => {
    const search = searchQuery.toLowerCase();
    const siteLabel = request.site_label ? request.site_label.toLowerCase() : '';
    const submittedByName = request.submitted_by_name
      ? request.submitted_by_name.toLowerCase()
      : '';
    const requestTitle = request.request_title
      ? request.request_title.toLowerCase()
      : '';

    return (
      (request.request_id?.toString().includes(search)) ||
      submittedByName.includes(search) ||
      requestTitle.includes(search) ||
      siteLabel.includes(search)
    );
  });

  // Group requests by site label
  const groupedRequests = {};
  filteredRequests.forEach((request) => {
    const siteLabel = request.site_label || 'Unknown Site';
    if (!groupedRequests[siteLabel]) {
      groupedRequests[siteLabel] = [];
    }
    groupedRequests[siteLabel].push(request);
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const currentRequests = filteredRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const formatDateTime = (dateTime) => {
    return dateTime ? format(new Date(dateTime), 'dd MMM yy HH:mm') : 'N/A';
  };

  // Handle Chat Icon Click
  const handleChatClick = (request_id, request_type = 2, request_status_id) => {
    // Navigate to ManagementChat with consistent query parameters
    navigate(`/managementchat?request_id=${request_id}&requestStatusId=${request_status_id}&requestType=${request_type}`);
 
  };



 

  return (
    <Box sx={{ display: 'flex' }}>
      {/* Sidebar Drawer */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>

      {/* Main Content Area */}
      <Main open={open}>
        <TopBar toggleSidebar={handleDrawerToggle} />
        <Box sx={{ padding: '20px' }}>
          {/* Search Field and Items Per Page Container */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '20px',
              flexWrap: 'wrap',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <TextField
                label="Search Requests"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Requests"
                sx={{ width: 300 }}
              />
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                sx={{ minWidth: 70, height: 40 }}
              >
                {[25, 50, 100, 250, 500].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

          {/* Loader Image Overlay */}
          {loading && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'fixed', // Fixed to overlay entire screen
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
                zIndex: 9999,
              }}
            >
              <img
                src={loaderImage}
                alt="Loading..."
                style={{
                  width: '100px',
                  height: '100px',
                  borderRadius: '50%',
                  animation: 'spin 2s linear infinite',
                }}
              />
              <style>
                {`
                  @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                  }
                `}
              </style>
            </Box>
          )}

          {/* Requests Table */}
          {!loading && (
            <TableContainer
              component={Paper}
              sx={{
                maxHeight: '700px',
                overflowY: 'auto',
                '::-webkit-scrollbar': { display: 'none' },
                scrollbarWidth: 'none',
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Site Label
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Request ID
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Approved By
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Request Date
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Status
                    </TableCell>
                    <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(groupedRequests).map(([siteLabel, requests]) => (
                    <React.Fragment key={siteLabel}>
                      {/* Group Header Row */}
                      <TableRow>
                        <TableCell
                          colSpan={6}
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '1.2em',
                            textAlign: 'center',
                            backgroundColor: '#f0f0f0',
                          }}
                        >
                          {siteLabel}
                        </TableCell>
                      </TableRow>
                      {requests.map((request) => (
                        <React.Fragment key={request.request_id}>
                          <TableRow>
                            <TableCell align="center">{siteLabel}</TableCell>
                            <TableCell align="center">{request.request_id}</TableCell>
                            <TableCell align="center">{request.approved_by}</TableCell>
                            <TableCell align="center">
                              {formatDateTime(request.request_date_time)}
                            </TableCell>
                            <TableCell align="center">
                              <Chip label="Approved" color="success" />
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip
                                title={expandedRequests[request.request_id] ? 'Collapse' : 'Expand'}
                              >
                                <IconButton
                                  onClick={() => handleExpandClick(request.request_id)}
                                  size="small"
                                  sx={{ padding: '4px' }}
                                >
                                  {expandedRequests[request.request_id] ? (
                                    <ExpandLessIcon fontSize="small" />
                                  ) : (
                                    <ExpandMoreIcon fontSize="small" />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Chat">
                                <IconButton
                                  onClick={() =>
                                    handleChatClick(
                                      request.request_id,
                                      request.request_type,
                                      request.request_status_id
                                    )
                                  }
                                  size="small"
                                  sx={{ padding: '4px' }}
                                >
                                  <ChatIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                          

                          {/* Expanded Row */}
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Collapse in={expandedRequests[request.request_id]} timeout="auto" unmountOnExit>
                                <Box sx={{ padding: 2 }}>
                                  {/* Display Grower Details */}
                                  <TextField
                                    label="Grower Name"
                                    value={
                                      requestDetails[request.request_id]?.request_detail?.[0]
                                        ?.grower_name || ''
                                    }
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                  />

                                  <TextField
                                    label="Passbook"
                                    value={
                                      requestDetails[request.request_id]?.request_detail?.[0]?.pass_book ||
                                      ''
                                    }
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                  />

                                  <TextField
                                    label="CNIC"
                                    value={
                                      requestDetails[request.request_id]?.request_detail?.[0]?.cnic || ''
                                    }
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                  />

                                  {/* Display Site Cane Head Comment */}
                                  <TextField
                                    label="Site Cane Head Comment"
                                    value={
                                      requestDetails[request.request_id]?.request_detail?.[0]?.comment ||
                                      ''
                                    }
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                  />

                                  {/* Display Auditor's Comment if available */}
                                  {requestDetails[request.request_id]?.request_detail?.[0]
                                    ?.audit_comments && (
                                    <TextField
                                      label="Audit Comment"
                                      value={
                                        requestDetails[request.request_id]?.request_detail?.[0]
                                          ?.audit_comments || ''
                                      }
                                      multiline
                                      fullWidth
                                      margin="normal"
                                      variant="outlined"
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{ backgroundColor: '#f5f5f5' }}
                                    />
                                  )}

                                  {/* Competitor Data Display */}
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: 2,
                                      overflowX: 'auto',
                                      whiteSpace: 'nowrap',
                                      marginTop: 2,
                                    }}
                                  >
                                    {requestDetails[request.request_id]?.request_detail?.[0]
                                      ?.Competitors?.length > 0 ? (
                                      requestDetails[
                                        request.request_id
                                      ]?.request_detail?.[0]?.Competitors.map((competitor) => (
                                        <Box
                                          key={competitor.competitor_id}
                                          sx={{
                                            border: '1px solid #ccc',
                                            minWidth: '250px',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              background: '#bdbdbd',
                                              color: 'white',
                                              padding: '10px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <strong
                                              style={{
                                                fontSize: 'large',
                                                fontFamily: 'sans-serif',
                                              }}
                                            >
                                              {competitor.competitor_label}
                                            </strong>
                                          </Box>
                                          <Box sx={{ padding: 2, textAlign: 'center' }}>
                                            <div>
                                              <strong style={{ color: 'black' }}>
                                                Requested Rates:
                                              </strong>
                                              <div style={{ color: 'gray' }}>
                                                Trolly: {competitor.present_trolly_rate} | Farsha:{' '}
                                                {competitor.present_farsha_rate}
                                              </div>
                                            </div>
                                          </Box>
                                        </Box>
                                      ))
                                    ) : (
                                      <Typography>No competitors data available.</Typography>
                                    )}
                                  </Box>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {/* Pagination Controls */}
          {!loading && (
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              variant="outlined"
              shape="rounded"
              sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}
            />
          )}

          {/* Success/Error Snackbar */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Main>
    </Box>
  );
};

export default ManagerSpecialApproved;
