// import React, { useState, useEffect } from 'react';
// import { Grid, Box, TextField, Button, Typography, Alert, Card, CardContent, IconButton, InputAdornment, Dialog, DialogContent, DialogActions } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import '../Styles/LoginPage.css';
// import LogoWhite from './../assects/Images/Logos/almoiz-logo.jpg';
// import BackgroundImage from './../assects/Images/Logos/login-img.png';
// import LoaderImage from '../assects/Images/Logos/CRVlogo.png';
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import API_BASE_URL from '../config';

// function saveObjectToLocal(key, obj) {
//   const jsonString = JSON.stringify(obj);
//   localStorage.setItem(key, jsonString);
// }

// const LoginForm = () => {
//   const navigate = useNavigate();
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const [showAlert, setShowAlert] = useState(false);
//   const [alertType, setAlertType] = useState('error');
//   const [errorMessage, setErrorMessage] = useState('');
//   const [usernameError, setUsernameError] = useState(false);
//   const [passwordError, setPasswordError] = useState(false);
//   const [loading, setLoading] = useState(true);
//   const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false);
//   const [openNotificationModal, setOpenNotificationModal] = useState(false);

//   useEffect(() => {
//     const checkNotificationPermission = async () => {
//       const permission = Notification.permission;
//       if (permission === 'granted') {
//         setNotificationPermissionGranted(true);
//       } else {
//         setOpenNotificationModal(true);
//       }
//       setLoading(false);
//     };

//     const requestNotificationPermission = async () => {
//       try {
//         const permission = await Notification.requestPermission();
//         if (permission === 'granted') {
//           setNotificationPermissionGranted(true);
//         } else {
//           setOpenNotificationModal(true);
//         }
//       } catch (error) {
//         console.error('Error requesting notification permission:', error);
//       }
//     };

//     checkNotificationPermission();
//     fetchFCMToken();
//   }, []);

//   const fetchFCMToken = async () => {
//     try {
//       const token = localStorage.getItem('fcm_token');
//       if (!token) {
//         localStorage.setItem('fcm_token', 'your-fcm-token');
//       }
//     } catch (error) {
//       console.error('Error fetching FCM token:', error);
//     }
//   };

//   const handleLogin = async () => {
//     setUsernameError(false);
//     setPasswordError(false);

//     if (!username) {
//       setUsernameError(true);
//       setErrorMessage("User ID is required");
//       setShowAlert(true);
//       return;
//     }

//     if (!password) {
//       setPasswordError(true);
//       setErrorMessage("Password is required");
//       setShowAlert(true);
//       return;
//     }

//     const fcmToken = localStorage.getItem('fcm_token');

//     try {
//       const response = await fetch(`${API_BASE_URL}authentication/C01ProgramOfficerAuthenticate`, {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//           user_id: username,
//           password: password,
//           fcm_token: fcmToken || "",
//           device_id: '123456',
//         }),
//       });

//       if (response.ok) {
//         const result = await response.json();
//         if (result.ERROR_CODE === 103) {
//           setAlertType('error');
//           setErrorMessage(result.USER_MESSAGE);
//           setShowAlert(true);
//           setTimeout(() => setShowAlert(false), 5000);
//         } else if (result.ERROR_CODE === 0) {
//           saveObjectToLocal('UserDetailData', result.DATA);
//           setAlertType('success');
//           setErrorMessage('Login successful, redirecting...');
//           setShowAlert(true);

//           const role = result.DATA.user_role;
//           if (role === 1) navigate('/dashboard', { replace: true });
//           else if (role === 3) navigate('/management', { replace: true });
//           else if (role === 4) navigate('/audit', { replace: true });
//           else if (role === 9) navigate('/userreport', { replace: true });
//           else {
//             setErrorMessage("Unauthorized access.");
//             setShowAlert(true);
//             setTimeout(() => navigate('/unauthorized', { replace: true }), 1500);
//           }
//         }
//       } else {
//         setAlertType('error');
//         setErrorMessage("Login failed. Please check your credentials and try again.");
//         setShowAlert(true);
//       }
//     } catch (error) {
//       setAlertType('error');
//       setErrorMessage("An error occurred during login. Please try again later.");
//       setShowAlert(true);
//     }
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleLogin();
//     }
//   };

//   const handleClickShowPassword = () => setShowPassword(!showPassword);
//   const handleMouseDownPassword = (event) => event.preventDefault();

//   const handleCloseNotificationModal = () => setOpenNotificationModal(false);

//   return (
//     <Box className="login-container" sx={{ position: 'relative' }}>
//       {loading && (
//         <Box
//           sx={{
//             position: 'absolute',
//             top: 0,
//             left: 0,
//             width: '100%',
//             height: '100%',
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             zIndex: 10,
//           }}
//         >
//           <img src={LoaderImage} alt="Loading..." className="rotating-loader" />
//         </Box>
//       )}

//       <Grid container className="login-grid" sx={{ minHeight: '100vh' }}>
//         <Grid
//           item
//           xs={12}
//           sm={7}
//           className="login-left"
//           sx={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
//         />
//         <Grid
//           item
//           xs={12}
//           sm={5}
//           className="login-right"
//           sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
//         >
//           <Box sx={{ textAlign: 'center', mb: 2 }}>
//             <img src={LogoWhite} alt="Company Logo" style={{ width: '150px', height: 'auto' }} />
//           </Box>
//           <Card sx={{ width: '100%', maxWidth: 400, padding: 4, borderRadius: 2, boxShadow: 3 }}>
//             <CardContent>
//               <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
//                 <Typography component="h1" variant="h5" sx={{ fontFamily: 'sans-serif', fontSize: 'large', fontWeight: 'lighter' }}>
//                   Sign in
//                 </Typography>
//               </Box>

//               <Box component="form" noValidate sx={{ mt: 2 }} onKeyDown={handleKeyPress}>
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   id="userID"
//                   label="User ID"
//                   name="userID"
//                   placeholder="Enter your user ID"
//                   variant="outlined"
//                   size="small"
//                   error={usernameError}
//                   helperText={usernameError ? "User ID is required" : ""}
//                   InputLabelProps={{
//                     style: { color: '#000' },
//                     shrink: true,
//                   }}
//                   InputProps={{
//                     style: { fontSize: 14, padding: '8px 10px' },
//                   }}
//                   value={username}
//                   onChange={(e) => setUsername(e.target.value)}
//                 />
//                 <TextField
//                   margin="normal"
//                   required
//                   fullWidth
//                   name="password"
//                   label="Password"
//                   placeholder="Enter your password"
//                   type={showPassword ? 'text' : 'password'}
//                   id="password"
//                   error={passwordError}
//                   helperText={passwordError ? "Password is required" : ""}
//                   variant="outlined"
//                   size="small"
//                   InputLabelProps={{
//                     style: { color: '#000' },
//                     shrink: true,
//                   }}
//                   InputProps={{
//                     style: { fontSize: 14, padding: '8px 10px' },
//                     endAdornment: (
//                       <InputAdornment position="end">
//                         <IconButton
//                           sx={{ color: '#a8e063' }}
//                           onClick={handleClickShowPassword}
//                           onMouseDown={handleMouseDownPassword}
//                         >
//                           {showPassword ? <Visibility /> : <VisibilityOff />}
//                         </IconButton>
//                       </InputAdornment>
//                     ),
//                   }}
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <Button
//                   fullWidth
//                   variant="contained"
//                   sx={{
//                     mt: 3,
//                     mb: 2,
//                     background: 'linear-gradient(to right, #a8e063, #56ab2f)',
//                     color: 'white',
//                     '&:hover': {
//                       background: 'linear-gradient(to right, #56ab2f, #a8e063)',
//                     },
//                     padding: '8px 0'
//                   }}
//                   onClick={handleLogin}
//                 >
//                   Submit
//                 </Button>
//               </Box>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>

//       {showAlert && (
//         <Alert severity={alertType} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
//           {errorMessage}
//         </Alert>
//       )}

//       <Dialog
//         open={openNotificationModal}
//         onClose={handleCloseNotificationModal}
//         aria-labelledby="notification-permission-dialog"
//       >
//         <DialogContent>
//           <Typography variant="h6" gutterBottom>
//             Notification Permission Denied
//           </Typography>
//           <Typography variant="body2">
//             Please enable notifications in your browser settings:
//             <ol>
//               <li>Click on the lock icon near the address bar.</li>
//               <li>Go to 'Site settings' or 'Notifications'.</li>
//               <li>Find and enable notifications for this site.</li>
//             </ol>
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button 
//             onClick={handleCloseNotificationModal} 
//             sx={{
//               background: 'linear-gradient(to right, #a8e063, #56ab2f)',
//               color: 'white',
//               '&:hover': {
//                 background: 'linear-gradient(to right, #56ab2f, #a8e063)',
//               },
//             }}
//           >
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default LoginForm;


import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Button, Typography, Alert, Card, CardContent, IconButton, InputAdornment, Dialog, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../Styles/LoginPage.css';
import LogoWhite from './../assects/Images/Logos/almoiz-logo.jpg';
import BackgroundImage from './../assects/Images/Logos/login-img.png';
import LoaderImage from '../assects/Images/Logos/CRVlogo.png';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import API_BASE_URL from '../config';

function saveObjectToLocal(key, obj) {
  const jsonString = JSON.stringify(obj);
  localStorage.setItem(key, jsonString);
}

const LoginForm = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('error');
  const [errorMessage, setErrorMessage] = useState('');
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);

  useEffect(() => {
    const checkNotificationPermission = async () => {
      const permission = Notification.permission;
      if (permission === 'granted') {
        setNotificationPermissionGranted(true);
      } else {
        setOpenNotificationModal(true);
      }
      setLoading(false);
    };

    const requestNotificationPermission = async () => {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          setNotificationPermissionGranted(true);
        } else {
          setOpenNotificationModal(true);
        }
      } catch (error) {
        console.error('Error requesting notification permission:', error);
      }
    };

    checkNotificationPermission();
    fetchFCMToken();
  }, []);

  const fetchFCMToken = async () => {
    try {
      const token = localStorage.getItem('fcm_token');
      if (!token) {
        localStorage.setItem('fcm_token', 'your-fcm-token');
      }
    } catch (error) {
      console.error('Error fetching FCM token:', error);
    }
  };

  const handleLogin = async () => {
    setUsernameError(false);
    setPasswordError(false);

    if (!username) {
      setUsernameError(true);
      setErrorMessage("User ID is required");
      setShowAlert(true);
      return;
    }

    if (!password) {
      setPasswordError(true);
      setErrorMessage("Password is required");
      setShowAlert(true);
      return;
    }

    const fcmToken = localStorage.getItem('fcm_token');

    try {
      const response = await fetch(`${API_BASE_URL}authentication/C01ProgramOfficerAuthenticate`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          user_id: username,
          password: password,
          fcm_token: fcmToken || "",
          device_id: '123456',
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.ERROR_CODE === 103) {
          setAlertType('error');
          setErrorMessage(result.USER_MESSAGE);
          setShowAlert(true);
          setTimeout(() => setShowAlert(false), 5000);
        } else if (result.ERROR_CODE === 0) {
          saveObjectToLocal('UserDetailData', result.DATA);
          setAlertType('success');
          setErrorMessage('Login successful, redirecting...');
          setShowAlert(true);

          const role = result.DATA.user_role;
          if (role === 1) navigate('/caneApp/dashboard', { replace: true });
          else if (role === 3) navigate('/caneApp/management', { replace: true });
          else if (role === 4) navigate('/caneApp/audit', { replace: true });
          else if (role === 9) navigate('/caneApp/userreport', { replace: true });
          else {
            setErrorMessage("Unauthorized access.");
            setShowAlert(true);
            setTimeout(() => navigate('/caneApp/unauthorized', { replace: true }), 1500);
          }
        }
      } else {
        setAlertType('error');
        setErrorMessage("Login failed. Please check your credentials and try again.");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertType('error');
      setErrorMessage("An error occurred during login. Please try again later.");
      setShowAlert(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleCloseNotificationModal = () => setOpenNotificationModal(false);

  return (
    <Box className="login-container" sx={{ position: 'relative' }}>
      {loading && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
          }}
        >
          <img src={LoaderImage} alt="Loading..." className="rotating-loader" />
        </Box>
      )}

      <Grid container className="login-grid" sx={{ minHeight: '100vh' }}>
        <Grid
          item
          xs={12}
          sm={7}
          className="login-left"
          sx={{ backgroundImage: `url(${BackgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        />
        <Grid
          item
          xs={12}
          sm={5}
          className="login-right"
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}
        >
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <img src={LogoWhite} alt="Company Logo" style={{ width: '150px', height: 'auto' }} />
          </Box>
          <Card sx={{ width: '100%', maxWidth: 400, padding: 4, borderRadius: 2, boxShadow: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
                <Typography component="h1" variant="h5" sx={{ fontFamily: 'sans-serif', fontSize: 'large', fontWeight: 'lighter' }}>
                  Sign in
                </Typography>
              </Box>

              <Box component="form" noValidate sx={{ mt: 2 }} onKeyDown={handleKeyPress}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="userID"
                  label="User ID"
                  name="userID"
                  placeholder="Enter your user ID"
                  variant="outlined"
                  size="small"
                  error={usernameError}
                  helperText={usernameError ? "User ID is required" : ""}
                  InputLabelProps={{
                    style: { color: '#000' },
                    shrink: true,
                  }}
                  InputProps={{
                    style: { fontSize: 14, padding: '8px 10px' },
                  }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  error={passwordError}
                  helperText={passwordError ? "Password is required" : ""}
                  variant="outlined"
                  size="small"
                  InputLabelProps={{
                    style: { color: '#000' },
                    shrink: true,
                  }}
                  InputProps={{
                    style: { fontSize: 14, padding: '8px 10px' },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ color: '#a8e063' }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 2,
                    background: 'linear-gradient(to right, #a8e063, #56ab2f)',
                    color: 'white',
                    '&:hover': {
                      background: 'linear-gradient(to right, #56ab2f, #a8e063)',
                    },
                    padding: '8px 0'
                  }}
                  onClick={handleLogin}
                >
                  Submit
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {showAlert && (
        <Alert severity={alertType} sx={{ position: 'absolute', top: '10px', right: '10px' }}>
          {errorMessage}
        </Alert>
      )}

      <Dialog
        open={openNotificationModal}
        onClose={handleCloseNotificationModal}
        aria-labelledby="notification-permission-dialog"
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Notification Permission Denied
          </Typography>
          <Typography variant="body2">
            Please enable notifications in your browser settings:
            <ol>
              <li>Click on the lock icon near the address bar.</li>
              <li>Go to 'Site settings' or 'Notifications'.</li>
              <li>Find and enable notifications for this site.</li>
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseNotificationModal} 
            sx={{
              background: 'linear-gradient(to right, #a8e063, #56ab2f)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(to right, #56ab2f, #a8e063)',
              },
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LoginForm;
