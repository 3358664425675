import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Snackbar,
  Alert,
  Collapse,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  IconButton,
  Select,
  MenuItem,
  Pagination,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ForwardIcon from '@mui/icons-material/Forward';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import LightGreen from '@mui/material/colors/lightGreen';
import blue from '@mui/material/colors/blue';
import loaderImage from '../assects/Images/Logos/CRVlogo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';

const ManagementRequests = () => {
  // Define a greenish and blue theme
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: LightGreen[500],
          },
          secondary: {
            main: blue[500],
          },
        },
      }),
    []
  );

  // Existing State Variables
  const [trackingIds, setTrackingIds] = useState([]);
  const [expandedTrackingIds, setExpandedTrackingIds] = useState({});
  const [requestDetails, setRequestDetails] = useState({});
  const [expandedRequests, setExpandedRequests] = useState({});
  const [competitorsData, setCompetitorsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCommentsDialog, setOpenCommentsDialog] = useState(false);
  const [currentComments, setCurrentComments] = useState({});
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // State to keep track of actions for each request
  const [requestActions, setRequestActions] = useState({});

  // State for editable comments
  const [auditComments, setAuditComments] = useState({});

  // State for editable revised rates
  const [editedRevisedRates, setEditedRevisedRates] = useState({});

  // State to show success message after rate change
  const [rateChangeSuccess, setRateChangeSuccess] = useState({});

  // New State Variables for Search and Pagination
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  // **New State Variable for Chat Users**
  const [chatUsers, setChatUsers] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    fetchTrackingIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTrackingIds = async () => {
    try {
      setLoading(true);
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setSnackbarMessage('Authentication token missing. Please log in again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const response = await axios.post(
        `${API_BASE_URL}C01RequestsList`,
        { request_type: 1 },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request_list) {
        setTrackingIds(response.data.DATA.request_list);
      }
    } catch (error) {
      console.error('Failed to fetch tracking IDs:', error);
      setSnackbarMessage('Failed to fetch tracking IDs. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchRequestDetails = async (tracking_id) => {
    try {
      setLoading(true);
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setSnackbarMessage('Authentication token missing. Please log in again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const response = await axios.post(
        `${API_BASE_URL}C01TrackIdRequestDetail`,
        { request_type: 1, track_id: tracking_id },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request_list) {
        setRequestDetails((prev) => ({
          ...prev,
          [tracking_id]: {
            request_list: response.data.DATA.request_list,
            audit_comments: response.data.DATA.audit_comments,
            site_cane_head_comments: response.data.DATA.site_cane_head_comments, // Added this line
          },
        }));

        // Initialize audit comments state
        setAuditComments((prev) => ({
          ...prev,
          [tracking_id]: response.data.DATA.audit_comments || '',
        }));

        // Initialize edited revised rates
        const initialEditedRates = {};
        response.data.DATA.request_list.forEach((request, requestIndex) => {
          request.request_detail.forEach((center, centerIndex) => {
            initialEditedRates[`${tracking_id}-${requestIndex}-${centerIndex}`] = {
              revised_trolly_rate: center.revised_trolly_rate,
              revised_farsha_rate: center.revised_farsha_rate,
            };
          });
        });
        setEditedRevisedRates((prev) => ({
          ...prev,
          ...initialEditedRates,
        }));

        // **Extract and Store Chat Users**
        if (Array.isArray(response.data.DATA.chat_users) && response.data.DATA.chat_users.length > 0) {
          setChatUsers((prev) => ({
            ...prev,
            [tracking_id]: response.data.DATA.chat_users.map(user => ({
              user_id: user.user_id,
              user_label: user.user_label,
              user_role_label: user.user_role_label,
            })),
          }));
        } else {
          console.warn(`No chat_users found for tracking_id: ${tracking_id}`);
          setChatUsers((prev) => ({
            ...prev,
            [tracking_id]: [],
          }));
        }
      }
    } catch (error) {
      console.error('Failed to fetch request details:', error);
      setSnackbarMessage('Failed to fetch request details. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleExpandTrackingId = (tracking_id) => {
    setExpandedTrackingIds((prev) => ({
      ...prev,
      [tracking_id]: !prev[tracking_id],
    }));
    if (!expandedTrackingIds[tracking_id]) {
      fetchRequestDetails(tracking_id);
    }
  };

  const handleExpandRequest = (tracking_id, requestIndex) => {
    const key = `${tracking_id}-${requestIndex}`;
    setExpandedRequests((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleViewCompetitors = (competitors) => {
    setCompetitorsData(competitors || []);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * Updated handleAction function to handle action strings and set action_id accordingly
   * @param {string} tracking_id
   * @param {string} request_id
   * @param {string} action - 'approve', 'decline', 'sendToAudit'
   */
  const handleAction = (tracking_id, request_id, action) => {
    let action_id = null;

    switch (action) {
      case 'approve':
        action_id = 1; // Correct action_id for 'approve'
        break;
      case 'decline':
        action_id = 2; // Correct action_id for 'decline'
        break;
      case 'sendToAudit':
        action_id = 5; // Correct action_id for 'audit'
        break;
      default:
        action_id = null;
    }

    if (action_id) {
      // Update the requestActions state with the action_id
      setRequestActions((prev) => ({
        ...prev,
        [request_id]: action_id,
      }));

      // If action is 'sendToAudit', reset the audit_comments
      if (action === 'sendToAudit') {
        setAuditComments((prev) => ({
          ...prev,
          [tracking_id]: '', // Reset audit comments
        }));
      }
    }
  };

  const handleSubmit = async (tracking_id) => {
    // Validation: Check if all requests have an action selected
    const requests = requestDetails[tracking_id]?.request_list || [];
    let allActionsSelected = true;

    for (const request of requests) {
      const request_id = request.request_detail[0]?.request_id;
      if (!requestActions[request_id]) {
        allActionsSelected = false;
        break;
      }
    }

    if (!allActionsSelected) {
      setSnackbarMessage('Please perform an action on all requests before submitting.');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return; // Do not proceed with submission
    }

    try {
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setSnackbarMessage('Authentication token missing. Please log in again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const do_action_requests = requests.map((request, requestIndex) => {
        const request_id = request.request_detail[0]?.request_id;
        const action_id = requestActions[request_id];

        const revise_rates = request.request_detail?.map((center, centerIndex) => {
          const key = `${tracking_id}-${requestIndex}-${centerIndex}`;
          const editedRates = editedRevisedRates[key] || {};

          return {
            center_id: center.center_id,
            revised_farsha_rate: editedRates.revised_farsha_rate || center.revised_farsha_rate,
            revised_trolly_rate: editedRates.revised_trolly_rate || center.revised_trolly_rate,
            comment: center.comment || '',
          };
        });

        return {
          action_id,
          request_id,
          auditComment: auditComments[tracking_id] || '',
          revise_rates,
        };
      });

      const payload = {
        tracking_id,
        audit_comments: auditComments[tracking_id] || '',
        do_action_requests,
      };

      await axios.post(`${API_BASE_URL}C01FinalRequestAction`, payload, {
        headers: { Authorization: `Bearer ${jwtToken}` },
      });

      setSnackbarMessage('Action performed successfully.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Refresh the requests
      fetchTrackingIds();

      // Clear the requestActions for this tracking_id
      setRequestActions((prev) => {
        const updated = { ...prev };
        for (const request of requests) {
          const request_id = request.request_detail[0]?.request_id;
          delete updated[request_id];
        }
        return updated;
      });

      // Clear edited revised rates
      setEditedRevisedRates((prev) => {
        const updated = { ...prev };
        requests.forEach((request, requestIndex) => {
          request.request_detail.forEach((center, centerIndex) => {
            const key = `${tracking_id}-${requestIndex}-${centerIndex}`;
            delete updated[key];
          });
        });
        return updated;
      });

      // Collapse the expanded tracking ID
      setExpandedTrackingIds((prev) => ({
        ...prev,
        [tracking_id]: false,
      }));
    } catch (error) {
      console.error('Failed to submit action:', error);
      setSnackbarMessage('Failed to submit action. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Handlers for revised rates changes
  const handleRevisedRateChange = (
    tracking_id,
    requestIndex,
    centerIndex,
    field,
    value
  ) => {
    const key = `${tracking_id}-${requestIndex}-${centerIndex}`;
    setEditedRevisedRates((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: value,
      },
    }));

    // Show success message for the specific field
    setRateChangeSuccess((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: true,
      },
    }));

    // Hide success message after 2 seconds
    setTimeout(() => {
      setRateChangeSuccess((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          [field]: false,
        },
      }));
    }, 2000);
  };

  // **Updated handleChatClick to include request_status_id**
  /**
   * Updated handleChatClick to include request_status_id
   * @param {string} tracking_id
   * @param {number} request_status_id
   */
  const handleChatClick = (tracking_id, request_status_id , request_type=1) => {
    console.log(request_type)
    navigate(`/managementchat?trackingId=${tracking_id}&requestStatusId=${request_status_id}&requestType=${request_type}`);
  };

  // Handle opening comments dialog
  const handleOpenCommentsDialog = (tracking_id) => {
    const requestList = requestDetails[tracking_id]?.request_list || [];
    const isAudited = requestList.some(
      (request) =>
        ['audited', 'sent to audit'].includes(request.request_status.toLowerCase())
    );
    const comments = {
      tracking_id, // Pass tracking_id to check audit status
      site_cane_head_comments: requestDetails[tracking_id]?.site_cane_head_comments || '',
      audit_comments: isAudited ? requestDetails[tracking_id]?.audit_comments || '' : '',
    };
    setCurrentComments(comments);
    setOpenCommentsDialog(true);
  };

  const handleCloseCommentsDialog = () => {
    setOpenCommentsDialog(false);
  };

  // New: Handle Search Query Changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // New: Handle Items Per Page Change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page on items per page change
  };

  // New: Filtered Tracking IDs based on Search Query
  const filteredTrackingIds = trackingIds.filter((tracking) => {
    const search = searchQuery.toLowerCase();
    return (
      tracking.tracking_id?.toString().includes(search) ||
      tracking.site_name.toLowerCase().includes(search) ||
      tracking.created_by_name.toLowerCase().includes(search)
    );
  });

  // New: Pagination Logic
  const totalPages = Math.ceil(filteredTrackingIds.length / itemsPerPage);
  const currentTrackingIds = filteredTrackingIds.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // New: Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 2, backgroundColor: 'background.default', minHeight: '100vh' }}>
        {/* New: Search and Items Per Page Controls */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <TextField
            label="Search Tracking IDs"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by Tracking ID, Site Name, or Created By"
            sx={{ width: 300 }}
          />
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            size="small"
            sx={{ minWidth: 120 }}
          >
            {[25, 50, 100, 250, 500].map((count) => (
              <MenuItem key={count} value={count}>
                {count} per page
              </MenuItem>
            ))}
          </Select>
        </Box>

        <TableContainer component={Paper} sx={{ borderRadius: 2, overflow: 'hidden' }}>
          <Table>
            <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
              <TableRow>
                <TableCell align="center" sx={{ padding: '6px', fontWeight: 'bold' }}>
                  Site Name
                </TableCell>
                <TableCell align="center" sx={{ padding: '6px', fontWeight: 'bold' }}>
                  Created By
                </TableCell>
                <TableCell align="center" sx={{ padding: '6px', fontWeight: 'bold' }}>
                  Created On
                </TableCell>
                <TableCell align="center" sx={{ padding: '6px', fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentTrackingIds.map((tracking) => (
                <React.Fragment key={tracking.tracking_id}>
                  {/* Tracking ID Header Row */}
                  <TableRow>
                    <TableCell colSpan={4} sx={{ backgroundColor: '#e0f2f1', padding: '4px 8px' }}>
                      <Box
                        display="flex"
                        alignItems="center"
                        onClick={() => handleExpandTrackingId(tracking.tracking_id)}
                        sx={{ cursor: 'pointer' }}
                      >
                        <IconButton size="small">
                          {expandedTrackingIds[tracking.tracking_id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          Tracking ID: {tracking.tracking_id}
                        </Typography>
                      </Box>
                    </TableCell>
                  </TableRow>

                  {/* Tracking Details Row */}
                  <TableRow
                    hover
                    sx={{
                      backgroundColor: expandedTrackingIds[tracking.tracking_id]
                        ? 'action.hover'
                        : 'inherit',
                    }}
                  >
                    <TableCell align="center" sx={{ padding: '6px' }}>
                      {tracking.site_name}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '6px' }}>
                      {tracking.created_by_name}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '6px' }}>
                      {tracking.created_on}
                    </TableCell>
                    <TableCell align="center" sx={{ padding: '6px' }}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleSubmit(tracking.tracking_id)}
                        disabled={!expandedTrackingIds[tracking.tracking_id]} // **Disabled if not expanded**
                        sx={{ mr: 0.5, padding: '4px 8px', textTransform: 'none', fontSize: '0.875rem' }}
                      >
                        Submit
                      </Button>
                      {/* Button to Open Comments Dialog */}
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => handleOpenCommentsDialog(tracking.tracking_id)}
                        disabled={!expandedTrackingIds[tracking.tracking_id]} // **Disabled if not expanded**
                        sx={{ mr: 0.5, padding: '4px 8px', textTransform: 'none', fontSize: '0.875rem' }}
                      >
                        View Comments
                      </Button>
                      <Tooltip title="Chat">
                        <IconButton
                          color="secondary"
                          onClick={() => handleChatClick(tracking.tracking_id, tracking.request_status_id, tracking.request_type)}
                          sx={{ padding: '4px' }}
                        >
                          <ChatIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>

                  {/* Collapse for Tracking Details */}
                  <TableRow>
                    <TableCell colSpan={4} sx={{ padding: 0 }}>
                      <Collapse
                        in={expandedTrackingIds[tracking.tracking_id]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ padding: 1, backgroundColor: 'background.paper' }}>
                          {/* Requests Table */}
                          <TableContainer component={Paper} variant="outlined" sx={{ mb: 1 }}>
                            <Table size="small">
                              <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                <TableRow>
                                  {/* Changed "Request ID" to "Sr No." */}
                                  <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}>
                                    Sr No.
                                  </TableCell>
                                  <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}>
                                    Request Status
                                  </TableCell>
                                  <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}>
                                    Created On
                                  </TableCell>
                                  <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}>
                                    Actions
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {requestDetails[tracking.tracking_id]?.request_list?.map(
                                  (request, requestIndex) => (
                                    <React.Fragment key={request.request_detail[0]?.request_id}>
                                      <TableRow
                                        hover
                                        sx={{
                                          backgroundColor: expandedRequests[
                                            `${tracking.tracking_id}-${requestIndex}`
                                          ]
                                            ? 'action.hover'
                                            : 'inherit',
                                        }}
                                      >
                                        {/* Display Serial Number Instead of Request ID */}
                                        <TableCell sx={{ fontWeight: 'bold', padding: '4px', fontSize: '0.875rem' }}>
                                          {requestIndex + 1}
                                        </TableCell>
                                        <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                                          {request.request_status}
                                        </TableCell>
                                        <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                                          {request.request_detail[0]?.created_on}
                                        </TableCell>
                                        <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                                          <Button
                                            variant="text"
                                            size="small"
                                            onClick={() =>
                                              handleExpandRequest(tracking.tracking_id, requestIndex)
                                            }
                                            sx={{
                                              textTransform: 'none',
                                              mr: 0.5,
                                              padding: '2px 4px',
                                              fontSize: '0.75rem',
                                              fontWeight: 'bolder'
                                            }}
                                          >
                                            {expandedRequests[`${tracking.tracking_id}-${requestIndex}`]
                                              ? 'Hide Details'
                                              : 'Show Details'}
                                          </Button>
                                          {/* Action Buttons with Labels */}
                                          <Box display="flex" alignItems="center" sx={{ mt: 0.5 }}>
                                            {/* Approve */}
                                            <Box display="flex" flexDirection="column" alignItems="center" sx={{ mr: 1 }}>
                                              <IconButton
                                                color={
                                                  requestActions[request.request_detail[0]?.request_id] === 1
                                                    ? 'success'
                                                    : 'default'
                                                }
                                                size="small"
                                                onClick={() =>
                                                  handleAction(
                                                    tracking.tracking_id,
                                                    request.request_detail[0]?.request_id,
                                                    'approve'
                                                  )
                                                }
                                                sx={{ padding: '2px' }}
                                              >
                                                <CheckCircleIcon fontSize="small" />
                                              </IconButton>
                                              <Typography variant="caption">Approve</Typography>
                                            </Box>
                                            {/* Decline */}
                                            <Box display="flex" flexDirection="column" alignItems="center" sx={{ mr: 1 }}>
                                              <IconButton
                                                color={
                                                  requestActions[request.request_detail[0]?.request_id] === 2
                                                    ? 'error'
                                                    : 'default'
                                                }
                                                size="small"
                                                onClick={() =>
                                                  handleAction(
                                                    tracking.tracking_id,
                                                    request.request_detail[0]?.request_id,
                                                    'decline'
                                                  )
                                                }
                                                sx={{ padding: '2px' }}
                                              >
                                                <CancelIcon fontSize="small" />
                                              </IconButton>
                                              <Typography variant="caption">Decline</Typography>
                                            </Box>
                                            {/* Audit */}
                                            <Box display="flex" flexDirection="column" alignItems="center">
                                              <Tooltip title="Send to Audit">
                                                <span>
                                                  {/* Wrapping IconButton with span to handle disabled tooltip */}
                                                  <IconButton
                                                    color={
                                                      requestActions[request.request_detail[0]?.request_id] === 5
                                                        ? 'info'
                                                        : 'default'
                                                    }
                                                    size="small"
                                                    onClick={() =>
                                                      handleAction(
                                                        tracking.tracking_id,
                                                        request.request_detail[0]?.request_id,
                                                        'sendToAudit'
                                                      )
                                                    }
                                                    sx={{ padding: '2px' }}
                                                    disabled={
                                                      ['sent to audit', 'audited'].includes(
                                                        request.request_status.toLowerCase()
                                                      ) ||
                                                      requestActions[request.request_detail[0]?.request_id] === 5
                                                    }
                                                  >
                                                    <ForwardIcon fontSize="small" />
                                                  </IconButton>
                                                </span>
                                              </Tooltip>
                                              <Typography variant="caption">Audit</Typography>
                                            </Box>
                                          </Box>
                                        </TableCell>
                                      </TableRow>

                                      {/* Centers Data */}
                                      <TableRow>
                                        <TableCell colSpan={4} sx={{ padding: 0 }}>
                                          <Collapse
                                            in={expandedRequests[
                                              `${tracking.tracking_id}-${requestIndex}`
                                            ]}
                                            timeout="auto"
                                            unmountOnExit
                                          >
                                            <Box sx={{ margin: 0.5 }}>
                                              <Table size="small">
                                                <TableHead sx={{ backgroundColor: '#a1ffa1' }}>
                                                  <TableRow>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Center Name
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Circle
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Current Trolly Rate
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Current Farsha Rate
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Revised Trolly Rate
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Revised Farsha Rate
                                                    </TableCell>
                                                    <TableCell sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.75rem' }}>
                                                      Competitors
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {request.request_detail?.map(
                                                    (center, centerIndex) => {
                                                      const key = `${tracking.tracking_id}-${requestIndex}-${centerIndex}`;
                                                      const editedRates = editedRevisedRates[key] || {};
                                                      return (
                                                        <TableRow key={centerIndex}>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            {center.center_name}
                                                          </TableCell>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            {center.circle_label}
                                                          </TableCell>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            {center.present_trolly_rate}
                                                          </TableCell>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            {center.present_farsha_rate}
                                                          </TableCell>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            <TextField
                                                              variant="outlined"
                                                              size="small"
                                                              value={editedRates.revised_trolly_rate || ''}
                                                              onChange={(e) =>
                                                                handleRevisedRateChange(
                                                                  tracking.tracking_id,
                                                                  requestIndex,
                                                                  centerIndex,
                                                                  'revised_trolly_rate',
                                                                  e.target.value
                                                                )
                                                              }
                                                              sx={{ width: '100%' }}
                                                            />
                                                            {rateChangeSuccess[key]?.revised_trolly_rate && (
                                                              <Typography variant="caption" color="success.main">
                                                                Changed
                                                              </Typography>
                                                            )}
                                                          </TableCell>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            <TextField
                                                              variant="outlined"
                                                              size="small"
                                                              value={editedRates.revised_farsha_rate || ''}
                                                              onChange={(e) =>
                                                                handleRevisedRateChange(
                                                                  tracking.tracking_id,
                                                                  requestIndex,
                                                                  centerIndex,
                                                                  'revised_farsha_rate',
                                                                  e.target.value
                                                                )
                                                              }
                                                              sx={{ width: '100%' }}
                                                            />
                                                            {rateChangeSuccess[key]?.revised_farsha_rate && (
                                                              <Typography variant="caption" color="success.main">
                                                                Changed
                                                              </Typography>
                                                            )}
                                                          </TableCell>
                                                          <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                            <Tooltip title="View Competitors">
                                                              <IconButton
                                                                color="primary"
                                                                size="small"
                                                                onClick={() =>
                                                                  handleViewCompetitors(center.Competitors)
                                                                }
                                                                sx={{ padding: '2px' }}
                                                              >
                                                                <VisibilityIcon fontSize="small" />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </TableCell>
                                                        </TableRow>
                                                      );
                                                    }
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </Box>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* New: Pagination Controls */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '16px',
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            variant="outlined"
            shape="rounded"
            color="primary"
          />
        </Box>

        {/* Loading Overlay */}
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1300,
            }}
          >
            <img
              src={loaderImage}
              alt="Loading..."
              style={{
                width: '80px',
                height: '80px',
                animation: 'spin 2s linear infinite',
              }}
            />
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
          </Box>
        )}

        {/* Competitors Data Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>Competitors Data</DialogTitle>
          <DialogContent>
            {competitorsData && competitorsData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                    <TableRow>
                      <TableCell>Competitor Name</TableCell>
                      <TableCell>Current Trolly Rate</TableCell>
                      <TableCell>Revised Trolly Rate</TableCell>
                      <TableCell>Current Farsha Rate</TableCell>
                      <TableCell>Revised Farsha Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {competitorsData.map((competitor, index) => (
                      <TableRow key={index}>
                        <TableCell>{competitor.competitor_label}</TableCell>
                        <TableCell>{competitor.present_trolly_rate}</TableCell>
                        <TableCell>{competitor.revised_trolly_rate}</TableCell>
                        <TableCell>{competitor.present_farsha_rate}</TableCell>
                        <TableCell>{competitor.revised_farsha_rate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No competitors data available.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Comments Dialog */}
        <Dialog open={openCommentsDialog} onClose={handleCloseCommentsDialog} maxWidth="sm" fullWidth>
          <DialogTitle>Comments</DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                  Site Cane Head Comment
                </Typography>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {currentComments.site_cane_head_comments || 'No comments available.'}
                </Typography>
              </Grid>
              {currentComments.audit_comments && (
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    Audit Comment
                  </Typography>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                    {currentComments.audit_comments || 'No comments available.'}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCommentsDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default ManagementRequests;
