import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Chip,
  IconButton,
  Tooltip,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Select,
  MenuItem,
  CircularProgress,
  Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import axios from 'axios';
import API_BASE_URL from '../config';
import { format } from 'date-fns';
import swal from 'sweetalert';
import '../Styles/PendingRequests.css';

const RequestsTable = () => {
  const [requests, setRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [editSrNo, setEditSrNo] = useState(null);
  const [editedRates, setEditedRates] = useState({});
  const [requestStatuses, setRequestStatuses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [expandedRequests, setExpandedRequests] = useState({});
  const [comments, setComments] = useState({});
  const [loading, setLoading] = useState(true);
  const [isApproved, setIsApproved] = useState(false);

  // Fetch requests from API
  useEffect(() => {
    const fetchRequests = async () => {
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        const response = await axios.post(
          `${API_BASE_URL}C01ProgramOfficerRequestsList`,
          { request_type: 1 },
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );

        if (response.data.DATA && response.data.DATA.request) {
          setRequests(response.data.DATA.request);

          const initialStatuses = {};
          const initialComments = {};
          response.data.DATA.request.forEach((req) => {
            initialStatuses[req.request_id] = req.status || 'Pending';
            req.requests_detail.forEach((detail) => {
              const commentKey = `${req.request_id}-${detail.sr_no}`;
              initialComments[commentKey] = detail.comment || '';
            });
          });

          setRequestStatuses(initialStatuses);
          setComments(initialComments);
        }
      } catch (error) {
        console.error('Failed to fetch requests:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  // Handle live search functionality
  const filteredRequests = requests.filter((request) => {
    const search = searchQuery.toLowerCase();
    const matchInDetails = request.requests_detail.some((detail) =>
      detail.site_label?.toLowerCase().includes(search) ||
      detail.request_title?.toLowerCase().includes(search) ||
      detail.submitted_by_name?.toLowerCase().includes(search) ||
      detail.sr_no?.toString().includes(search)
    );

    return request.request_id?.toString().includes(search) || matchInDetails;
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const currentRequests = filteredRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle Decline
  const handleDecline = (request_id) => {
    const status = requestStatuses[request_id];
    if (status === 'Approved' || status === 'Declined' || status === 'On Hold') {
      return; // Can't decline if already approved, declined, or on hold
    }
    setRequestStatuses((prevStatuses) => ({
      ...prevStatuses,
      [request_id]: 'Declined',
    }));
  };

  // Handle Hold
  const handleHold = (request_id) => {
    const status = requestStatuses[request_id];
    if (status === 'Approved' || status === 'Declined') {
      return; // Can't hold if already approved or declined
    }
    setRequestStatuses((prevStatuses) => ({
      ...prevStatuses,
      [request_id]: status === 'On Hold' ? 'Pending' : 'On Hold', // Toggle between Hold and Pending
    }));
  };

  // Handle Approve All
  const handleApproveAll = () => {
    const updatedStatuses = { ...requestStatuses };
    Object.keys(updatedStatuses).forEach((id) => {
      if (updatedStatuses[id] === 'Pending') { // Only approve requests that are still pending
        updatedStatuses[id] = 'Approved';
      }
    });
    setIsApproved(true);
    setRequestStatuses(updatedStatuses);
  };

  // Edit
  const handleEditClick = (sr_no, currentTrolly, currentFarsha) => {
    setEditSrNo(sr_no); // Mark this row as being edited
    setEditedRates({
      ...editedRates,
      [sr_no]: {
        trollyRates: currentTrolly,
        farshaRates: currentFarsha,
      },
    });
  };

  const handleExpandClick = (request_id) => {
    setExpandedRequests((prev) => ({ ...prev, [request_id]: !prev[request_id] }));
  };

  // Handle input changes for trolly and farsha rates
  const handleInputChange = (e, sr_no) => {
    const { name, value } = e.target;
    setEditedRates((prev) => ({
      ...prev,
      [sr_no]: {
        ...prev[sr_no],
        [name]: value,
      },
    }));
  };

  // Save the updated rates
  const handleSave = (sr_no, request_id) => {
    const updatedRequests = requests.map((req) => {
      if (req.request_id === request_id) {
        const updatedDetails = req.requests_detail.map((detail) => {
          if (detail.sr_no === sr_no) {
            return {
              ...detail,
              trolly_rate: editedRates[sr_no]?.trollyRates,
              farsha_rate: editedRates[sr_no]?.farshaRates,
            };
          }
          return detail;
        });
        return {
          ...req,
          requests_detail: updatedDetails,
        };
      }
      return req;
    });
    setRequests(updatedRequests);
    setEditSrNo(null);
    setEditedRates((prev) => {
      const newRates = { ...prev };
      delete newRates[sr_no];
      return newRates;
    });
  };

  // Cancel editing the rates
  const handleCancel = (sr_no) => {
    setEditSrNo(null);
    setEditedRates((prev) => {
      const newRates = { ...prev };
      delete newRates[sr_no];
      return newRates;
    });
  };

  // Show confirmation message
  const showApiResponseMessage = (message, status = 'info') => {
    swal({
      title: status === 'success' ? 'Success' : 'Error',
      text: message,
      icon: status === 'success' ? 'success' : 'error',
      timer: 4000, // Show for 4 seconds
      buttons: false,
    });
  };


  const handleSubmit = async () => {
    // Check if there are any pending requests
    const hasPendingRequests = requests.some(
      (req) => requestStatuses[req.request_id] === 'Pending'
    );
  
    if (hasPendingRequests && !isApproved) {
      showApiResponseMessage('You must approve all pending requests before submitting.', 'warning');
      return;
    }
  
    setLoading(true);
    const payload = requests.map((req) => {
      const status = requestStatuses[req.request_id];
      let action_id = 3; // Default action ID for pending
      if (status === 'Approved' || status === 'Edited') action_id = 1;
      else if (status === 'Declined') action_id = 2;
  
      const revise_rates = req.requests_detail.map((detail) => ({
        center_id: detail.center_id,
        revised_trolly_rate: parseFloat(detail.trolly_rate) || 0.0,
        revised_farsha_rate: parseFloat(detail.farsha_rate) || 0.0,
        sr_no: detail.sr_no,
      }));
  
      return {
        action_id,
        request_id: req.request_id,
        revise_rates,
      };
    });
  
    try {
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;
  
      const response = await axios.post(
        `${API_BASE_URL}C01ProgramOfficerRequestAction`,
        payload,
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );
  
      // Show success message from the API response
      if (response.data?.DATA?.message) {
        showApiResponseMessage(response.data.DATA.message, 'success');
      } else {
        showApiResponseMessage('Your requests were submitted successfully', 'success');
      }
  
      // Filter out Declined and Approved requests after submission
      const filteredRequestsAfterSubmit = requests.filter(
        (req) => requestStatuses[req.request_id] === 'On Hold'
      );
      setRequests(filteredRequestsAfterSubmit);
    } catch (error) {
      console.error('Failed to send data:', error);
      showApiResponseMessage('Failed to submit requests', 'error');
    } finally {
      setLoading(false);
    }
  };
  

  // const handleSubmit = async () => {
  //   if (!isApproved) {
  //     showApiResponseMessage('You must approve all requests before submitting.', 'warning');
  //     return;
  //   }
  
  //   setLoading(true);
  //   const payload = requests.map((req) => {
  //     const status = requestStatuses[req.request_id];
  //     let action_id = 3;
  //     if (status === 'Approved' || status === 'Edited') action_id = 1;
  //     else if (status === 'Declined') action_id = 2;
  
  //     const revise_rates = req.requests_detail.map((detail) => ({
  //       center_id: detail.center_id,
  //       revised_trolly_rate: parseFloat(detail.trolly_rate) || 0.0,
  //       revised_farsha_rate: parseFloat(detail.farsha_rate) || 0.0,
  //       sr_no: detail.sr_no,
  //     }));
  
  //     return {
  //       action_id,
  //       request_id: req.request_id,
  //       revise_rates,
  //     };
  //   });
  
  //   try {
  //     const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
  //     const jwtToken = userDetailData?.jwt_token;
  
  //     const response = await axios.post(
  //       `${API_BASE_URL}C01ProgramOfficerRequestAction`,
  //       payload,
  //       { headers: { Authorization: `Bearer ${jwtToken}` } }
  //     );
  
  //     // Show success message from the API response
  //     if (response.data?.DATA?.message) {
  //       showApiResponseMessage(response.data.DATA.message, 'success');
  //     } else {
  //       showApiResponseMessage('Your requests were submitted successfully', 'success');
  //     }
  
  //     // Filter out Declined and Approved requests after submission
  //     const filteredRequestsAfterSubmit = requests.filter(
  //       (req) => requestStatuses[req.request_id] === 'On Hold'
  //     );
  //     setRequests(filteredRequestsAfterSubmit);
  //   } catch (error) {
  //     console.error('Failed to send data:', error);
  //     showApiResponseMessage('Failed to submit requests', 'error');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  
  const formatDateTime = (dateTime) => {
    return dateTime ? format(new Date(dateTime), 'dd MMM yy  HH:mm') : 'N/A';
  };

  return (
    <div>
      {/* Search, Pagination, and Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px', flexWrap: 'wrap' }}>
        {/* Left Side: Search and Items per Page */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            label="Search Requests"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Requests"
          />
          <Select value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)} sx={{ minWidth: 70, height: 40 }}>
            {[25, 50, 100, 250, 500].map((count) => (
              <MenuItem key={count} value={count}>
                {count}
              </MenuItem>
            ))}
          </Select>
        </Box>
  
        {/* Right Side: Approve All and Submit */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Button
            variant="contained"
            startIcon={<CheckCircleIcon />}
            onClick={handleApproveAll}
            sx={{
              background: 'linear-gradient(to right, #00c6ff, #0072ff)',
              color: 'white',
              '&:hover': { background: 'linear-gradient(to right, #00c6ff, #0072ff)' },
            }}
          >
            Approve All
          </Button>
          <Button
            variant="contained"
            startIcon={<SaveIcon />}
            onClick={handleSubmit}
            sx={{
              background: 'linear-gradient(to right, #a8e063, #56ab2f)',
              color: 'white',
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
  
      {/* Loader */}
      {loading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 4 }}>
          <CircularProgress />
        </Box>
      )}
  
      {/* Requests Table */}
      {!loading && (
        <>
          <TableContainer
            component={Paper}
            sx={{
              maxHeight: 'auto',
              overflowY: 'auto',
              '::-webkit-scrollbar': { display: 'none' },
              scrollbarWidth: 'none',
            }}
          >




            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Serial No.</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Site</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Zone</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Submitted By</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Submitted By ID</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Request Time</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
                
              </TableHead>


              <TableBody>
              {currentRequests.length === 0 ? (
    <TableRow>
      <TableCell colSpan={8} align="center">
        <Typography variant="h6" sx={{ textAlign: 'center', marginTop: 4 }}>
          No pending requests found.
        </Typography>
      </TableCell>
    </TableRow>
  ) : (
    currentRequests.map((request, index) =>
                  <React.Fragment key={request.request_id}>
                    <TableRow>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{request.requests_detail[0]?.site_label}</TableCell>
                      <TableCell align="center">{request.requests_detail[0]?.zone_name}</TableCell>
                      <TableCell align="center">{request.requests_detail[0]?.submitted_by_name}</TableCell>
                      <TableCell align="center">{request.requests_detail[0]?.submitted_by_id}</TableCell>
                      <TableCell align="center">{formatDateTime(request.requests_detail[0]?.request_date_time)}</TableCell>
                      <TableCell align="center">
                        <Chip
                          label={requestStatuses[request.request_id]}
                          color={
                            requestStatuses[request.request_id]?.toLowerCase() === 'approved'
                              ? 'success'
                              : requestStatuses[request.request_id]?.toLowerCase() === 'declined'
                                ? 'error'
                                : requestStatuses[request.request_id]?.toLowerCase() === 'on hold'
                                  ? 'info'
                                  : 'warning'
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        {/* Decline Button */}
                        <Tooltip title="Decline">
                          <span>
                            <IconButton
                              color="error"
                              onClick={() => handleDecline(request.request_id)}
                              size="small"
                              disabled={
                                requestStatuses[request.request_id] === 'Approved' ||
                                requestStatuses[request.request_id] === 'Declined' ||
                                requestStatuses[request.request_id] === 'On Hold'
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
  
                        {/* Hold Button */}
                        <Tooltip title="Hold">
                          <IconButton
                            color="info"
                            onClick={() => handleHold(request.request_id)}
                            size="small"
                            disabled={
                              requestStatuses[request.request_id] === 'Approved' ||
                              requestStatuses[request.request_id] === 'Declined'
                            }
                          >
                            <HourglassEmptyIcon />
                          </IconButton>
                        </Tooltip>
  
                        {/* Expand Button */}
                        <Tooltip title="Expand">
                          <IconButton onClick={() => handleExpandClick(request.request_id)} size="small">
                            <ExpandMoreIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
  
                    {/* Expanded Row */}
                    <TableRow>
                      <TableCell colSpan={7}>
                        <Collapse in={expandedRequests[request.request_id]}>
                          <Box
                            sx={{
                              padding: 2,
                              display: 'flex',
                              flexWrap: 'wrap',
                              gap: 2,
                              justifyContent: 'space-between',
                              overflowX: 'auto',
                              whiteSpace: 'normal',
                              maxWidth: '100%',
                              '::-webkit-scrollbar': { display: 'none' },
                              scrollbarWidth: 'none',
                            }}
                          >
                            {request.requests_detail.map((detail) => (
                              <Box
                                key={detail.sr_no}
                                sx={{
                                  border: '1px solid #ccc',
                                  minWidth: '250px',
                                  flex: '1 1 calc(25% - 16px)',
                                  borderRadius: '12px',
                                  marginBottom: '16px',
                                  overflow: 'hidden',
                                }}
                              >
                                {/* Header with Center and Circle Names */}


                                 {/* Header with Center Name */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '12px',
                  background: '#647182',
                  color: '#FFFFFF',
                  borderRadius: '10px 10px 0 0',
                }}
              >
                <Typography variant="h6" sx={{ textTransform: 'uppercase', fontSize: '14px' }}>
                  Center: {detail.center_name}
                </Typography>
              </Box>




                                {/* Body with Rates */}
                                <Box sx={{ padding: 2, textAlign: 'left' }}>

                                <div>
                  <strong style={{ color: 'black' }}>Circle:</strong>
                  <div style={{ color: 'gray' }}>{detail.circle_name || 'N/A'}</div>
                </div>

                                  <div>
                                    <strong style={{ color: 'black' }}>Current Rates:</strong>
                                    <div style={{ color: 'gray' }}>
                                      Trolly: {detail.current_trolly_rate} | Farsha: {detail.current_farsha_rate}
                                    </div>
                                  </div>
                                  <div style={{ marginTop: 1 }}>
                                    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'left' }}>
                                      <strong style={{ color: 'black' }}>Revised Rates:</strong>
                                      <Tooltip title={editSrNo === detail.sr_no ? "Save" : "Edit"}>
                                        <IconButton
                                          color={editSrNo === detail.sr_no ? "primary" : "default"}
                                          onClick={editSrNo === detail.sr_no
                                            ? () => handleSave(detail.sr_no, request.request_id)
                                            : () => handleEditClick(detail.sr_no, detail.trolly_rate, detail.farsha_rate)}
                                          aria-label={editSrNo === detail.sr_no ? "save" : "edit"}
                                          size="small"
                                          sx={{ marginLeft: '5px' }}
                                        >
                                          {editSrNo === detail.sr_no ? <SaveIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                                        </IconButton>
                                      </Tooltip>
                                    </div>
                                    <div style={{ color: 'gray' }}>
                                    {editSrNo === detail.sr_no ? (
  <>
    <div style={{ marginBottom: '10px' }}>
      Trolly:
      <TextField
        name="trollyRates"
        value={editedRates[detail.sr_no]?.trollyRates || ''}
        onChange={(e) => handleInputChange(e, detail.sr_no)}
        size="small"
        sx={{ width: '80px', marginRight: 1 }}
      />
    </div>
    <div>
      Farsha:
      <TextField
        name="farshaRates"
        value={editedRates[detail.sr_no]?.farshaRates || ''}
        onChange={(e) => handleInputChange(e, detail.sr_no)}
        size="small"
        sx={{ width: '80px', marginRight: 1 }}
      />
    </div>
  </>
) : (
  <>
    Trolly: <span>{detail.trolly_rate}</span>
    <br />
    Farsha: <span>{detail.farsha_rate}</span>
  </>
)}

                                    </div>
                                  </div>

                                  <div>
                  <strong style={{ color: 'black' }}>Date & Time:</strong>
                  <div style={{ color: 'gray' }}>{detail.request_date_time || 'N/A'}</div>
                </div>
                                </Box>
  
                                {/* Competitors Section */}
                                <Button
                                  variant="outlined"
                                  startIcon={<ExpandMoreIcon />}
                                  onClick={() => setExpandedRequests(prev => ({
                                    ...prev,
                                    [`${request.request_id}-${detail.center_id}`]: !prev[`${request.request_id}-${detail.center_id}`]
                                  }))}
                                  sx={{ marginTop: '10px', marginBottom: '10px', marginLeft: '10px' }}
                                >
                                  Show Competitors
                                </Button>
  
                                <Collapse in={expandedRequests[`${request.request_id}-${detail.center_id}`]}>
                                  <Box sx={{ padding: '10px' }}>
                                    {detail.Competitors.length > 0 ? (
                                      detail.Competitors.map(competitor => (
                                        <Box key={competitor.competitor_id} sx={{ marginBottom: '10px', padding: '10px', border: '1px solid #ddd', borderRadius: '8px' }}>
                                          <div><strong>Competitor:</strong> {competitor.competitor_label}</div>
                                          <div><strong>Current Trolly Rate:</strong> {competitor.present_trolly_rate}</div>
                                          <div><strong>Revised Trolly Rate:</strong> {competitor.revised_trolly_rate}</div>
                                          <div><strong>Current Farsha Rate:</strong> {competitor.present_farsha_rate}</div>
                                          <div><strong>Revised Farsha Rate:</strong> {competitor.revised_farsha_rate}</div>
                                        </Box>
                                      ))
                                    ) : (
                                      <div>No competitors data available.</div>
                                    )}
                                  </Box>
                                </Collapse>
                              </Box>
                            ))}
  
                            {/* Placeholder Boxes to maintain UI balance */}
                            {Array.from({ length: (4 - (request.requests_detail.length % 4)) % 4 }).map((_, idx) => (
                              <Box
                                key={`placeholder-${idx}`}
                                sx={{
                                  flex: '1 1 calc(25% - 16px)',
                                  visibility: 'hidden'
                                }}
                              />
                            ))}
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
  
          {/* Pagination Component */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Pagination
              count={totalPages} // Total pages calculated based on filteredRequests length and itemsPerPage
              page={currentPage} // Current page state
              onChange={handlePageChange} // Handle page change
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}
    </div>
  );
  
};

export default RequestsTable;
