import React, { useState, useEffect } from 'react';
import {
  Box, Button, MenuItem, Select, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TextField, CircularProgress, Checkbox, ListItemText
} from '@mui/material';
import * as XLSX from 'xlsx';
import axios from 'axios';
import { format } from 'date-fns';

const AuditActivityTable = () => {
  const [requestType, setRequestType] = useState('');
  const [mills, setMills] = useState([]);
  const [selectedMills, setSelectedMills] = useState([]);
  const [millData, setMillData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [hasApplied, setHasApplied] = useState(false);

  const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
  const jwtToken = userDetailData?.jwt_token;

  useEffect(() => {
    if (requestType) {
      fetchMills();
      setMillData([]);
      setSelectedMills([]);
      const today = format(new Date(), 'yyyy-MM-dd');
      setStartDate(today);
      setEndDate(today);
    }
  }, [requestType]);

  const fetchMills = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `http://3.71.87.205:8083/CaneRateApp/C01AuditFieldOfficerRequestList?request-type=${requestType}`,
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );
      setMills(response.data.DATA.mills.map(mill => ({
        millId: mill.mill_id,
        millName: mill.mill_name,
      })));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching mills:', error);
      setLoading(false);
    }
  };

  const fetchMillData = async () => {
    setLoading(true);
    setHasApplied(true); // Mark that the "Apply" button was clicked.
    try {
      const response = await axios.get(
        `http://3.71.87.205:8083/CaneRateApp/C01AuditFieldOfficerRequestList?request-type=${requestType}`,
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      let filteredData = response.data.DATA.result.filter(
        (mill) => selectedMills.includes(mill.millId)
      );

      const start = new Date(startDate);
      const end = new Date(endDate);
      end.setHours(23, 59, 59, 999);

      filteredData = filteredData.filter(mill => {
        const createdOnDate = new Date(mill.createdOn);
        return createdOnDate >= start && createdOnDate <= end;
      });

      filteredData.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
      setMillData(filteredData);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching mill data:', error);
      setLoading(false);
    }
  };

  const handleMillChange = (event) => {
    const value = event.target.value;
    if (value.includes('all')) {
      setSelectedMills(
        selectedMills.length === mills.length ? [] : mills.map((mill) => mill.millId)
      );
    } else {
      setSelectedMills(value);
    }
  };

  const exportDataToExcel = () => {
    const dataToExport = millData.flatMap(mill =>
      mill.centerDetails.flatMap(center =>
        center.competitorDetails.map(competitor => ({
          RequestId: mill.requestId,
          MillName: mill.millName,
          ...(requestType === "1" && { CenterName: center.centerName || 'N/A' }),
          CompetitorName: competitor.competitorName || 'N/A',
          ...(requestType === "1" && {
            TrollyRate: competitor.trollyRate || 'N/A',
            FarshaRate: competitor.farshaRate || 'N/A',
          }),
          Remarks: mill.remarks || 'N/A',
          CreatedBy: mill.createdByName || 'N/A',
          CreatedOn: formatDate(mill.createdOn),
        }))
      )
    );

    if (dataToExport.length === 0) {
      alert('No data available to export.');
      return;
    }

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Audit Data');
    XLSX.writeFile(workbook, 'Audit_Activity_Data.xlsx');
  };

  const formatDate = (dateString) => {
    return dateString ? format(new Date(dateString), 'dd MMM yy  HH:mm') : 'N/A';
  };

  return (
    <Box sx={{ padding: '20px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '20px', marginBottom: '20px' }}>
        <Box sx={{ display: 'flex', gap: '15px', flexWrap: 'wrap' }}>
          <Select
            value={requestType}
            displayEmpty
            onChange={(e) => setRequestType(e.target.value)}
            sx={{ width: 200 }}
          >
            <MenuItem value="" disabled>
              <em>Select Request Type</em>
            </MenuItem>
            <MenuItem value="1">Field</MenuItem>
            <MenuItem value="2">Yard</MenuItem>
          </Select>

          <Select
            multiple
            value={selectedMills}
            onChange={handleMillChange}
            renderValue={(selected) =>
              selected.length === mills.length
                ? 'All Mills'
                : selected.map(id => mills.find(mill => mill.millId === id)?.millName).join(', ')
            }
            sx={{ width: 300 }}
          >
            <MenuItem value="all">
              <Checkbox checked={selectedMills.length === mills.length} />
              <ListItemText primary="Select All Mills" />
            </MenuItem>
            {mills.map((mill) => (
              <MenuItem key={mill.millId} value={mill.millId}>
                <Checkbox checked={selectedMills.includes(mill.millId)} />
                <ListItemText primary={mill.millName} />
              </MenuItem>
            ))}
          </Select>

          <TextField
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: 150 }}
          />
          <TextField
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ width: 150 }}
          />

          <Button
            variant="outlined"
            onClick={fetchMillData}
            sx={{ height: '56px' }}
            disabled={!selectedMills.length || loading}
          >
            Apply
          </Button>
        </Box>

        <Button
          variant="contained"
          onClick={exportDataToExcel}
          sx={{ background: 'linear-gradient(135deg, #67B26F 0%, #4ca2cd 100%)', color: 'white' }}
          disabled={millData.length === 0 || loading}
        >
          Export to Excel
        </Button>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <CircularProgress />
        </Box>
      ) : millData.length === 0 && hasApplied ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Typography variant="h6" color="textSecondary">
            No records found for the selected date.
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ maxHeight: '60vh', overflow: 'auto', marginTop: '20px' }}>
          <Table stickyHeader>
            <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Request ID</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Mill Name</TableCell>
                {requestType === "1" && <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Center Name</TableCell>}
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Competitor Name</TableCell>
                {requestType === "1" && (
                  <>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Trolly Rate</TableCell>
                    <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Farsha Rate</TableCell>
                  </>
                )}
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Remarks</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Submitted By</TableCell>
                <TableCell sx={{ fontWeight: 'bold', textAlign: 'center' }}>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {millData.map((mill) =>
                mill.centerDetails.map((center) =>
                  center.competitorDetails.map((competitor, index) => (
                    <TableRow key={`${mill.requestId}-${index}`}>
                      <TableCell sx={{ textAlign: 'center' }}>{mill.requestId}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{mill.millName}</TableCell>
                      {requestType === "1" && <TableCell sx={{ textAlign: 'center' }}>{center.centerName || 'N/A'}</TableCell>}
                      <TableCell sx={{ textAlign: 'center' }}>{competitor.competitorName || 'N/A'}</TableCell>
                      {requestType === "1" && (
                        <>
                          <TableCell sx={{ textAlign: 'center' }}>{competitor.trollyRate || 'N/A'}</TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>{competitor.farshaRate || 'N/A'}</TableCell>
                        </>
                      )}
                      <TableCell sx={{ textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '200px' }}>{mill.remarks || 'N/A'}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{mill.createdByName || 'N/A'}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>{formatDate(mill.createdOn)}</TableCell>
                    </TableRow>
                  ))
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AuditActivityTable;
