import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, button } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { NavLink, useLocation } from 'react-router-dom';
import LogoWhite from './../assects/Images/Logos/almoiz-logo.jpg';
import '../Styles/SideBar.css';
import CreditCardSharpIcon from '@mui/icons-material/CreditCardSharp';
import AddCommentIcon from '@mui/icons-material/AddComment';
import ReorderIcon from '@mui/icons-material/Reorder';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ListAltIcon from '@mui/icons-material/ListAlt';

const SideBar = ({ isOpen }) => {
  const location = useLocation();
  const userRole = JSON.parse(localStorage.getItem('UserDetailData'))?.user_role;

  return (
    <Box
      sx={{
        width: isOpen ? 250 : 0,
        height: '100vh',
        backgroundColor: 'white',
        transition: 'width 0.3s',
        overflow: 'hidden',
        color: 'black',
        boxShadow: '3px 0px 15px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ padding: isOpen ? '20px' : '0', textAlign: 'left' }}>
        {isOpen && <img src={LogoWhite} alt="Company Logo" style={{ width: '150px', borderRadius: '10px' }} />}
      </Box>
      <List>
        {/* Conditionally render based on user_role */}
        {userRole === 1 && (
          <>
            <NavLink to="/caneApp/dashboard" style={{ textDecoration: 'none' }} className={location.pathname === '/dashboard' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/dashboard' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/dashboard' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/pendingrequests" style={{ textDecoration: 'none' }} className={location.pathname === '/pendingrequests' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/pendingrequests' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <ReorderIcon sx={{ color: location.pathname === '/pendingrequests' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Pending Request" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/requests" style={{ textDecoration: 'none' }} className={location.pathname === '/requests' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/requests' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <AssignmentIcon sx={{ color: location.pathname === '/requests' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Add New Request" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/specialrequests" style={{ textDecoration: 'none' }} className={location.pathname === '/specialrequests' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/specialrequests' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <CreditCardSharpIcon sx={{ color: location.pathname === '/specialrequests' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Special Request" sx={{ color: 'black', whiteSpace: 'nowrap' }} />
              </ListItem>
            </NavLink>
          </>
        )}


{userRole === 3 && (
<>


              <NavLink to="/caneApp/management" style={{ textDecoration: 'none' }} className={location.pathname === '/management' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/management' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/management' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="CRM Pending" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>
            

           

              

            <NavLink to="/caneApp/managementapproved" style={{ textDecoration: 'none' }} className={location.pathname === '/managementapproved' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/managementapproved' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/managementapproved' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="CRM Approved" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/managementdecline" style={{ textDecoration: 'none' }} className={location.pathname === '/managementdecline' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/managementdecline' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/managementdecline' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="CRM Declined" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>


            

            <NavLink to="/caneApp/managementspecailreq" style={{ textDecoration: 'none' }} className={location.pathname === '/managementspecailreq' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/managementspecailreq' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/managementspecailreq' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Special Pending" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>


            <NavLink to="/caneApp/managementspecialapproved" style={{ textDecoration: 'none' }} className={location.pathname === '/managementspecialapproved' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/managementspecialapproved' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/managementspecialapproved' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Special Approved" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/managementspecialdeclined" style={{ textDecoration: 'none' }} className={location.pathname === '/managementspecialdeclined' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/managementspecialdeclined' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/managementspecialdeclined' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Special Declined" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>


</>
)}


        {userRole === 4 && (
          <>

{/* 
               <NavLink to="/auditdashboard" style={{ textDecoration: 'none' }} className={location.pathname === '/auditdashboard' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/auditdashboard' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <DashboardIcon sx={{ color: location.pathname === '/auditdashboard' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Dashboard" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink> */}


            <NavLink to="/caneApp/audit" style={{ textDecoration: 'none' }} className={location.pathname === '/audit' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/audit' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <AssignmentIcon sx={{ color: location.pathname === '/audit' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Requests" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/auditspecial" style={{ textDecoration: 'none' }} className={location.pathname === '/auditspecial' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/auditspecial' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <AddCommentIcon sx={{ color: location.pathname === '/auditspecial' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Special Requests" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

            <NavLink to="/caneApp/auditactivity" style={{ textDecoration: 'none' }} className={location.pathname === '/auditactivity' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/auditactivity' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <ReorderIcon sx={{ color: location.pathname === '/auditactivity' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Audit Activity" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>

          

          </>
        )}


             {userRole === 9 && (
          <>


          
<NavLink to="/caneApp/userreport" style={{ textDecoration: 'none' }} className={location.pathname === '/userreport' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/userreport' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <ManageAccountsIcon sx={{ color: location.pathname === '/userreport' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Management" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>


  <NavLink to="/caneApp/registrationform" style={{ textDecoration: 'none' }} className={location.pathname === '/registrationform' ? 'active' : ''}>
              <ListItem button sx={{ backgroundColor: location.pathname === '/registrationform' ? '#a8e063' : 'transparent', '&:hover': { backgroundColor: '#DAF7A6' } }}>
                <ListItemIcon>
                  <ListAltIcon sx={{ color: location.pathname === '/registrationform' ? 'white' : '#56ab2f' }} />
                </ListItemIcon>
                <ListItemText primary="Registration Form" sx={{ color: 'black' }} />
              </ListItem>
            </NavLink>


              

            </>
        )}


      </List>
    </Box>
  );
};

export default SideBar; 
