import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, Breadcrumbs, Link, CircularProgress, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import TopBar from './TopBar';
import SideBar from './SideBar';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import axios from 'axios';
import API_BASE_URL from '../config';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 

// Register the necessary components for the pie chart
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const drawerWidth = 240;

// Styling for the main content area
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

// KPI Card Component without percentage trends
const KPIBox = ({ label, value, gradientColor, icon }) => (
  <Paper
    elevation={0}
    sx={{
      padding: '20px',
      textAlign: 'left',
      backgroundImage: `linear-gradient(135deg, ${gradientColor[0]}, ${gradientColor[1]})`,
      borderRadius: '12px',
      color: 'black',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '150px',
      position: 'relative',
    }}
  >
    <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
      {icon && (
        <Box component="span" sx={{ fontSize: '3rem', color: 'white' }}>
          {icon}
        </Box>
      )}
    </Box>
    <Typography variant="h6" sx={{ color: 'white', fontWeight: '500' }}>{label}</Typography>
    <Typography variant="h4" sx={{ color: 'white', fontWeight: 'bold', marginTop: '5px' }}>{value}</Typography>
  </Paper>
);

// Pie Chart Card with percentage display
const PieChartCard = ({ data }) => (
  <Paper
    elevation={0}
    sx={{
      padding: '20px',
      textAlign: 'center',
      borderRadius: '12px',
      background: '#f9f9f9', // Lighter background color
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
    }}
  >
    <Typography variant="h6" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
      Requests Distribution
    </Typography>
    <Box sx={{ 
      height: '300px', // Increased height for better visibility
      width: '100%',  // Make the chart responsive to the parent container
      maxWidth: '600px', // Increased max-width for larger display
      margin: '0 auto', // Center the chart within the container
      display: 'flex', 
      justifyContent: 'center' 
    }}>
      <Pie data={data} options={{
        plugins: {
          datalabels: {
            formatter: (value, context) => {
              const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
              const percentage = ((value / total) * 100).toFixed(2) + '%';
              return percentage;
            },
            color: 'white',
            font: {
              weight: 'bold'
            }
          }
        }
      }} />
    </Box>
  </Paper>
);

const Dashboard = () => {
  const [open, setOpen] = useState(true);
  const [apiData, setApiData] = useState(null); // State to store API data
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false); // Notification permission state
  const [openNotificationModal, setOpenNotificationModal] = useState(false); // Modal state for notification permission

  // Function to toggle the sidebar
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Fetching API data with authentication token
  useEffect(() => {
    const fetchRequestsData = async () => {
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const token = userDetailData?.jwt_token;

      if (!token) {
        setError('Authentication token not found');
        setLoading(false);
        return;
      }

      try {
        setLoading(true); // Set loading state
        const response = await axios.get(`${API_BASE_URL}C01RequestCount`, {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          },
        });

        if (response.data.DATA && response.data.DATA.request && response.data.DATA.request.length > 0) {
          setApiData(response.data.DATA.request[0]);
        } else {
          throw new Error('No data received');
        }
      } catch (err) {
        setError('Failed to fetch data');
        console.error('API fetch error:', err);
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    // Function to check notification permission
    const checkNotificationPermission = async () => {
      const permission = Notification.permission;
      if (permission === 'granted') {
        setNotificationPermissionGranted(true);
      } else {
        setOpenNotificationModal(true); // Show modal if permission is denied
      }
    };

    fetchRequestsData();
    checkNotificationPermission(); // Check notification permission on page load
  }, []);

  // Handle closing of the notification modal
  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  };

  // Extract only normal request counts from API data
  const normalPending = apiData?.normal_pending || 0;
  const normalApproved = apiData?.normal_approved || 0;
  const normalDeclined = apiData?.normal_declined || 0;
  const normalOnHold = apiData?.normal_on_hold || 0;

  // Data for the pie chart with updated colors matching KPI cards
  const pieData = {
    labels: ['Total Requests', 'Approved', 'Declined', 'On Hold'],
    datasets: [
      {
        label: 'Requests Distribution',
        data: [normalPending, normalApproved, normalDeclined, normalOnHold],
        backgroundColor: ['#5ac18e', '#a592ff', '#f47f7e', '#f7b556'], // Updated colors to match the swapped KPI cards
        hoverOffset: 4,
      },
    ],
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>
      <Main open={open}>
        <TopBar toggleSidebar={handleDrawerToggle} />
        <Box sx={{ padding: '20px' }}>
          {/* Breadcrumbs */}
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '20px' }}>
            <Link color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="textPrimary">Dashboard</Typography>
          </Breadcrumbs>

          {/* Display loader only for cards and pie chart */}
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {/* KPI Cards */}
              <Grid container spacing={3} sx={{ marginBottom: '40px' }}>
                <Grid item xs={12} sm={3}>
                  <KPIBox
                    label="Total Requests"
                    value={normalPending}
                    gradientColor={['#b2f1d9', '#5ac18e']}
                    icon={<TrendingUpIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KPIBox
                    label="Approved Requests"
                    value={normalApproved}
                    gradientColor={['#cfb9f7', '#a592ff']}
                    icon={<CheckCircleIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KPIBox
                    label="Declined Requests"
                    value={normalDeclined}
                    gradientColor={['#f47f7e', '#f3b6b5']} // Swapped to reddish gradient
                    icon={<CancelIcon />}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <KPIBox
                    label="On-Hold Requests"
                    value={normalOnHold}
                    gradientColor={['#f7b556', '#f4e0b3']} // Swapped to yellow gradient
                    icon={<HourglassEmptyIcon />}
                  />
                </Grid>
              </Grid>

              {/* Pie Chart */}
              <Grid container justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <PieChartCard data={pieData} />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Main>

      {/* Modal for Notification Permission */}
      <Dialog
        open={openNotificationModal}
        onClose={handleCloseNotificationModal}
        aria-labelledby="notification-permission-dialog"
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Notification Permission Denied
          </Typography>
          <Typography variant="body2">
            Please enable notifications in your browser settings:
            <ol>
              <li>Click on the lock icon near the address bar.</li>
              <li>Go to 'Site settings' or 'Notifications'.</li>
              <li>Find and enable notifications for this site.</li>
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseNotificationModal} 
            sx={{
              background: 'linear-gradient(to right, #a8e063, #56ab2f)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(to right, #56ab2f, #a8e063)',
              },
            }}
            >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
