import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Drawer,
  CircularProgress,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputAdornment,
  FormControl,
  OutlinedInput,
  Chip,
  Stack,
  Snackbar,
  DialogContentText,
  InputLabel,
  Tooltip,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import PasswordIcon from "@mui/icons-material/Password";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import axios from "axios";

const drawerWidth = 240;

// Styled Main component for layout
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const UserReports = () => {
  // State variables
  const [selectedSite, setSelectedSite] = useState("all"); // Default to 'all'
  const [users, setUsers] = useState([]);
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Search term state

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPageOptions = [10, 50, 100, 500];
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // State for Change Password Modal
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [selectedUserForPassword, setSelectedUserForPassword] = useState(null);
  const [newPassword, setNewPassword] = useState("");

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // 'success' | 'error' | 'warning' | 'info'
  });

  // State for Confirmation Dialog
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [confirmDialogData, setConfirmDialogData] = useState({
    title: "",
    content: "",
    onConfirm: null,
  });

  // Function to safely retrieve and parse localStorage data
  const getLocalStorageData = () => {
    const dataString = localStorage.getItem("UserDetailData");

    if (dataString) {
      try {
        const parsedData = JSON.parse(dataString);
        if (parsedData && parsedData.jwt_token && parsedData.sites) {
          return parsedData;
        } else {
          console.error("Missing jwt_token or sites in UserDetailData.");
          return null;
        }
      } catch (err) {
        console.error("Error parsing UserDetailData from localStorage:", err);
        return null;
      }
    }
    console.error("UserDetailData is not found in localStorage.");
    return null;
  };

  const localData = getLocalStorageData();
  const jwtToken = localData ? localData.jwt_token : "";

  // Fetch sites and initial users on component mount
  useEffect(() => {
    if (localData) {
      // Load sites from localStorage
      setSites(localData.sites || []);
      // Fetch users initially with 'all'
      fetchUsers("all");
    } else {
      setError("User data not found. Please log in.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetch users whenever selectedSite changes
  useEffect(() => {
    if (localData) {
      // Reset to first page when site changes
      setCurrentPage(1);
      fetchUsers(selectedSite);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSite]);

  // Function to fetch users based on siteId
  const fetchUsers = async (siteId) => {
    setLoading(true);
    setError(null);
    try {
      const BASE_URL = "http://3.71.87.205:8083/CaneRateApp";
      let url = `${BASE_URL}/C01UserList`;
      if (siteId !== "all") {
        url += `?siteId=${siteId}`;
      }

      console.log("Fetching users from URL:", url); // Debugging: Check the URL being called

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      console.log("Response received:", response); // Debugging: Inspect the response

      if (response.data && response.data.CODE === 1 && response.data.DATA.success) {
        const fetchedUsers = response.data.DATA.userDTOList || [];
        // Assign a unique id if not present
        const usersWithId = fetchedUsers.map((user, index) => ({
          ...user,
          id: user.id || index,
        }));
        setUsers(usersWithId);
      } else {
        setError("No users found."); // Update message when no users are found
      }
    } catch (err) {
      console.error("Error fetching users:", err);
      if (err.response) {
        // Server responded with a status other than 2xx
        setError(err.response.data.SYSTEM_MESSAGE || "Server error occurred.");
      } else if (err.request) {
        // Request was made but no response received
        setError("Network error. Please try again.");
      } else {
        // Something else happened
        setError("An unexpected error occurred.");
      }
    } finally {
      setLoading(false);
    }
  };

  // Handle site selection via labels
  const handleSiteSelect = (siteId) => {
    setSelectedSite(siteId);
  };

  // Navigate to edit user page
  const handleEdit = (user) => {
    // Redirect to the RegistrationForm component with prefilled data
    navigate("/registrationform", { state: { user } });
  };

  // Function to toggle user status (Active/Inactive) with confirmation
  const toggleUserStatus = (userId, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1; // Toggle between 1 and 0
    const action = newStatus === 1 ? "Activate" : "Deactivate";

    // Open confirmation dialog
    setConfirmDialogData({
      title: `${action} User`,
      content: `Are you sure you want to ${action.toLowerCase()} this user?`,
      onConfirm: () => updateUserStatus(userId, newStatus),
    });
    setIsConfirmDialogOpen(true);
  };

  // Function to perform the status update
  const updateUserStatus = async (userId, newStatus) => {
    setIsConfirmDialogOpen(false);
    setLoading(true);
    try {
      const BASE_URL = "http://3.71.87.205:8083/CaneRateApp";
      const url = `${BASE_URL}/C01UpdatePasswordOrStatus`;

      const payload = {
        id: userId, // Ensure id is a number
        isActive: newStatus,
      };

      console.log("Updating user status with payload:", payload);

      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Response received:", response);

      if (response.data && response.data.CODE === 1 && response.data.DATA.success) {
        setSnackbar({
          open: true,
          message: `User has been ${newStatus === 1 ? "activated" : "deactivated"} successfully!`,
          severity: "success",
        });
        // Refresh the user list
        fetchUsers(selectedSite);
      } else {
        setSnackbar({
          open: true,
          message: response.data.SYSTEM_MESSAGE || `Failed to ${newStatus === 1 ? "activate" : "deactivate"} user.`,
          severity: "error",
        });
      }
    } catch (err) {
      console.error("Error updating user status:", err);
      const errorMsg =
        err.response?.data?.SYSTEM_MESSAGE ||
        `Error occurred while trying to ${newStatus === 1 ? "activate" : "deactivate"} user.`;
      setSnackbar({
        open: true,
        message: errorMsg,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle drawer toggle
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  // Helper function to map isActive to status string
  const getStatus = (isActive) => (isActive === 1 ? "Active" : "Inactive");

  // Functions to handle Change Password Modal
  const openPasswordModal = (user) => {
    setSelectedUserForPassword(user);
    setNewPassword("");
    setIsPasswordModalOpen(true);
  };

  const closePasswordModal = () => {
    setSelectedUserForPassword(null);
    setNewPassword("");
    setIsPasswordModalOpen(false);
  };

  // Function to handle password change
  const handlePasswordChange = async () => {
    if (!selectedUserForPassword) return;

    if (newPassword.trim() === "") {
      setSnackbar({
        open: true,
        message: "Password cannot be empty.",
        severity: "warning",
      });
      return;
    }

    setLoading(true);
    try {
      const BASE_URL = "http://3.71.87.205:8083/CaneRateApp";
      const url = `${BASE_URL}/C01UpdatePasswordOrStatus`;

      const payload = {
        id: selectedUserForPassword.id,
        password: newPassword,
      };

      console.log("Updating user password with payload:", payload);

      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      console.log("Response received:", response);

      if (response.data && response.data.CODE === 1 && response.data.DATA.success) {
        setSnackbar({
          open: true,
          message: "Password updated successfully.",
          severity: "success",
        });
        // Optionally, refresh the user list
        fetchUsers(selectedSite);
        closePasswordModal();
      } else {
        const errorMsg = response.data.SYSTEM_MESSAGE || "Failed to update password.";
        setSnackbar({
          open: true,
          message: errorMsg,
          severity: "error",
        });
      }
    } catch (err) {
      console.error("Error updating password:", err);
      const errorMsg = err.response?.data?.DATA?.message || "Error updating password.";
      setSnackbar({
        open: true,
        message: errorMsg,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  // Filter users based on search term
  const filteredUsers = users.filter((user) =>
    user.displayName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Calculate pagination
  const indexOfLastUser = currentPage * rowsPerPage;
  const indexOfFirstUser = indexOfLastUser - rowsPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / rowsPerPage);

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle rows per page change
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  return (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        background: "#f4f6f8",
        overflowX: "hidden",
      }}
    >
      {/* Sidebar Drawer */}
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            overflowX: "hidden", // Prevent horizontal scroll within the drawer
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>

      {/* Main Content Area */}
      <Main open={open}>
        {/* Top Bar */}
        <TopBar toggleSidebar={handleDrawerToggle} />

        <Box
          sx={{
            padding: "20px",
            background: "#f4f6f8",
            display: "flex",
            flexDirection: "column",
            height: "calc(100vh - 64px)", // Adjust based on TopBar height
            overflow: "hidden", // Prevent the main container from scrolling
          }}
        >
          {/* Page Title */}
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            align="center"
            sx={{ marginBottom: "20px", fontWeight: "bold", wordBreak: "break-word" }}
          >
            User Management
          </Typography>

          {/* Site Labels */}
          <Box sx={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" gutterBottom>
              Select Site:
            </Typography>
            <Stack direction="row" spacing={1} flexWrap="wrap">
              <Chip
                label="All Users"
                clickable
                color={selectedSite === "all" ? "primary" : "default"}
                onClick={() => handleSiteSelect("all")}
                sx={{ marginBottom: { xs: "8px", sm: 0 } }}
              />
              {sites.map((site) => (
                <Chip
                  key={site.id}
                  label={site.label}
                  clickable
                  color={selectedSite === site.id ? "primary" : "default"}
                  onClick={() => handleSiteSelect(site.id)}
                  sx={{ marginBottom: { xs: "8px", sm: 0 } }}
                />
              ))}
            </Stack>
          </Box>

          {/* Search Bar and Rows Per Page Selector */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            {/* Search Bar */}
            <FormControl variant="outlined" sx={{ width: { xs: "100%", sm: "250px" } }}>
              <InputLabel htmlFor="search">Search by Name</InputLabel>
              <OutlinedInput
                id="search"
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end" disabled>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="Search by Name"
                sx={{
                  "& input": {
                    wordBreak: "break-word",
                  },
                }}
              />
            </FormControl>

            {/* Rows Per Page Selector */}
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
              <InputLabel id="rows-per-page-label">Rows per page</InputLabel>
              <Select
                labelId="rows-per-page-label"
                id="rows-per-page"
                value={rowsPerPage}
                onChange={handleRowsPerPageChange}
                label="Rows per page"
              >
                {rowsPerPageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Loading Indicator */}
          {loading && (
            <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
              <CircularProgress />
            </Box>
          )}

          {/* Error Message */}
          {error && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error">{error}</Alert>
            </Box>
          )}

          {/* User List */}
          {!loading && !error && (
            <Box
              sx={{
                flexGrow: 1,
                overflow: "hidden", // Prevent the container from scrolling
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TableContainer
                sx={{
                  border: "1px solid #e0e0e0",
                  borderRadius: "4px",
                  flexGrow: 1,
                  overflowX: "auto", // Allow horizontal scroll if necessary
                }}
              >
                <Table
                  stickyHeader
                  aria-label="user table"
                  sx={{
                    width: "100%", // Ensure table takes full width
                    tableLayout: "fixed", // Fixed layout for better responsiveness
                  }}
                >
                  <TableHead>
                    <TableRow>
                    <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Login ID</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Name</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Role Name</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Department</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Email</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Mobile No</strong>
                      </TableCell>
                      
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Site Name</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Zone Name</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Circle Name</strong>
                      </TableCell>
                      <TableCell align="center" sx={{ wordBreak: "break-word" }}>
                        <strong>Actions</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentUsers.length > 0 ? (
                      currentUsers.map((user) => (
                        <TableRow key={user.id} hover>

                        <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.id || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.displayName || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.roleName || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.department || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.email || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.mobileNo || "N/A"}
                          </TableCell>
                          {/* <TableCell
                            align="center"
                            sx={{
                              wordBreak: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {user.address || "N/A"}
                          </TableCell> */}
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.siteName || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.zoneName || "N/A"}
                          </TableCell>
                          <TableCell align="center" sx={{ wordBreak: "break-word", whiteSpace: "normal" }}>
                            {user.circleName || "N/A"}
                          </TableCell>
                          <TableCell align="center">
                            <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                              <Tooltip title="Edit User">
                                <IconButton onClick={() => handleEdit(user)}>
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={user.isActive === 1 ? "Deactivate User" : "Activate User"}
                              >
                                <IconButton onClick={() => toggleUserStatus(user.id, user.isActive)}>
                                  {user.isActive === 1 ? (
                                    <ToggleOnIcon sx={{ color: "green" }} />
                                  ) : (
                                    <ToggleOffIcon sx={{ color: "gray" }} />
                                  )}
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Change Password">
                                <IconButton onClick={() => openPasswordModal(user)}>
                                  <PasswordIcon />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={10} align="center">
                          No users found matching your search criteria.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination Controls */}
              {filteredUsers.length > rowsPerPage && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px",
                  }}
                >
                  {/* Show total entries */}
                  <Typography variant="body2">
                    Showing {indexOfFirstUser + 1} to{" "}
                    {indexOfLastUser > filteredUsers.length
                      ? filteredUsers.length
                      : indexOfLastUser}{" "}
                    of {filteredUsers.length} entries
                  </Typography>
                  {/* Pagination Component */}
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              )}
            </Box>
          )}

          {/* Change Password Modal */}
          <Dialog open={isPasswordModalOpen} onClose={closePasswordModal}>
            <DialogTitle>Change Password</DialogTitle>
            <DialogContent>
              <Typography variant="body1" gutterBottom>
                Change password for: <strong>{selectedUserForPassword?.displayName || "N/A"}</strong>
              </Typography>
              <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
                <InputLabel htmlFor="new-password">New Password</InputLabel>
                <OutlinedInput
                  id="new-password"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  label="New Password"
                  endAdornment={
                    <InputAdornment position="end">
                      <PasswordIcon />
                    </InputAdornment>
                  }
                  inputProps={{
                    style: { wordBreak: "break-word" }, // Handle long passwords gracefully
                  }}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={closePasswordModal} color="secondary">
                Cancel
              </Button>
              <Button onClick={handlePasswordChange} variant="contained" color="primary">
                Update Password
              </Button>
            </DialogActions>
          </Dialog>

          {/* Confirmation Dialog */}
          <Dialog
            open={isConfirmDialogOpen}
            onClose={() => setIsConfirmDialogOpen(false)}
          >
            <DialogTitle>{confirmDialogData.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {confirmDialogData.content}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setIsConfirmDialogOpen(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={confirmDialogData.onConfirm} variant="contained" color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          {/* Snackbar for Feedback Messages */}
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </Box>
      </Main>
    </Box>
  );
};

export default UserReports;
