// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   MenuItem,
//   Button,
//   Grid,
//   Box,
//   Typography,
//   Drawer,
//   Breadcrumbs,
//   Paper,
//   FormControl,
//   InputLabel,
//   Select,
//   Snackbar,
//   Alert,
//   InputAdornment,
//   IconButton,
// } from "@mui/material";
// import { useLocation, useNavigate } from "react-router-dom";
// import TopBar from "./TopBar";
// import SideBar from "./SideBar";
// import { styled } from "@mui/material/styles";
// import axios from "axios";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";

// const API_BASE_URL = "http://3.71.87.205:8083/CaneRateApp/";

// const drawerWidth = 240;

// // Styled Main component for layout
// const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create("margin", {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const CustomButton = styled(Button)({
//   backgroundColor: "#4caf50",
//   color: "#fff",
//   fontWeight: "bold",
//   padding: "10px 30px",
//   borderRadius: "25px",
//   "&:hover": {
//     backgroundColor: "#45a049",
//   },
//   transition: "background-color 0.3s ease",
// });

// const FormSection = styled(Paper)({
//   padding: "20px",
//   margin: "20px auto",
//   boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
//   borderRadius: "15px",
//   maxWidth: "900px",
//   backgroundColor: "#f9f9f9",
// });

// const RegistrationForm = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Determine if we're editing based on presence of user data in location state
//   const userToEdit = location.state?.user || null;
//   const isEditMode = Boolean(userToEdit);

//   // Function to safely retrieve and parse localStorage data
//   const getLocalStorageData = () => {
//     const dataString = localStorage.getItem("UserDetailData");
//     if (dataString) {
//       try {
//         const parsedData = JSON.parse(dataString);
//         if (parsedData && parsedData.jwt_token && parsedData.sites) {
//           return parsedData;
//         } else {
//           console.error("Missing jwt_token or sites in UserDetailData.");
//           return null;
//         }
//       } catch (err) {
//         console.error("Error parsing UserDetailData from localStorage:", err);
//         return null;
//       }
//     }
//     console.error("UserDetailData is not found in localStorage.");
//     return null;
//   };

//   const localData = getLocalStorageData();
//   const jwtToken = localData ? localData.jwt_token : "";
//   const userID = localData ? localData.user_id : null;

//   // Initialize form data based on mode (edit or register)
//   const initialFormData = isEditMode
//     ? {
//         id: userToEdit.id || "",
//         firstName: userToEdit.firstName || "",
//         lastName: userToEdit.lastName || "",
//         password: "", // Password is not set when editing
//         email: userToEdit.email || "",
//         mobileNo: userToEdit.mobileNo || "",
//         address: userToEdit.address || "",
//         department: userToEdit.department || "",
//         role: userToEdit.roleId || "",
//         site: userToEdit.siteId || "",
//         circle: userToEdit.circleId || "",
//         zone: userToEdit.zoneId || "",
//         departmentId: userToEdit.departmentId || "",
//         currentReportingTo: userToEdit.currentReportingTo || "",
//       }
//     : {
//         id: "",
//         firstName: "",
//         lastName: "",
//         password: "",
//         email: "",
//         mobileNo: "",
//         address: "",
//         department: "",
//         role: "",
//         site: "",
//         circle: "",
//         zone: "",
//         departmentId: "",
//         currentReportingTo: "",
//       };

//   const [formData, setFormData] = useState(initialFormData);

//   const [open, setOpen] = useState(true);
//   const [sites, setSites] = useState([]);
//   const [circles, setCircles] = useState([]);
//   const [zones, setZones] = useState([]);
//   const [departments, setDepartments] = useState([]);
//   const [roles, setRoles] = useState([]);

//   // Additional state for filtered zones and circles
//   const [filteredZones, setFilteredZones] = useState([]);
//   const [filteredCircles, setFilteredCircles] = useState([]);

//   // Snackbar state
//   const [snackbar, setSnackbar] = useState({
//     open: false,
//     message: "",
//     severity: "success", // 'success' | 'error' | 'warning' | 'info'
//   });

//   // State for form errors (basic validation)
//   const [errors, setErrors] = useState({});

//   // State to manage password visibility
//   const [showPassword, setShowPassword] = useState(false);

//   // Load dropdown data from localStorage
//   useEffect(() => {
//     if (localData) {
//       setSites(localData.sites || []);
//       setCircles(localData.circles || []);
//       setZones(localData.zones || []);
//       setDepartments(localData.user_departments || []);
//       setRoles(localData.user_roles || []);

//       // If editing, set filtered zones and circles based on initial data
//       if (isEditMode) {
//         if (formData.site) {
//           const initialFilteredZones = (localData.zones || []).filter(
//             (zone) => zone.siteId === formData.site
//           );
//           setFilteredZones(initialFilteredZones);
//         }

//         if (formData.zone) {
//           const initialFilteredCircles = (localData.circles || []).filter(
//             (circle) => circle.zoneId === formData.zone
//           );
//           setFilteredCircles(initialFilteredCircles);
//         }
//       }
//     }
//   }, [localData, isEditMode, formData.site, formData.zone]);

//   const handleDrawerToggle = () => {
//     setOpen(!open);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;

//     // Determine if the field is an ID that should be stored as a number
//     const idFields = ["role", "site", "zone", "circle", "departmentId"];
//     const isIdField = idFields.includes(name);

//     const parsedValue = isIdField && value ? Number(value) : value;

//     // If role changes, reset dependent fields
//     if (name === "role") {
//       setFormData((prev) => ({
//         ...prev,
//         role: parsedValue,
//         site: "",
//         zone: "",
//         circle: "",
//       }));
//       setFilteredZones([]);
//       setFilteredCircles([]);
//       return;
//     }

//     // If site changes, reset zone and circle
//     if (name === "site") {
//       const selectedSiteId = parsedValue;
//       const filtered = zones.filter((zone) => zone.siteId === selectedSiteId);
//       setFilteredZones(filtered);
//       setFormData((prev) => ({
//         ...prev,
//         site: selectedSiteId,
//         zone: "",
//         circle: "",
//       }));
//       setFilteredCircles([]);
//       return;
//     }

//     // If zone changes, reset circle
//     if (name === "zone") {
//       const selectedZoneId = parsedValue;
//       const filtered = circles.filter((circle) => circle.zoneId === selectedZoneId);
//       setFilteredCircles(filtered);
//       setFormData((prev) => ({
//         ...prev,
//         zone: selectedZoneId,
//         circle: "",
//       }));
//       return;
//     }

//     // Handle other changes
//     setFormData((prev) => ({
//       ...prev,
//       [name]: parsedValue,
//     }));

//     // Simple validation (e.g., email format)
//     if (name === "email") {
//       const emailValid = /\S+@\S+\.\S+/.test(parsedValue);
//       setErrors((prev) => ({
//         ...prev,
//         email: emailValid ? "" : "Invalid email address",
//       }));
//     }
//   };

//   const validateForm = () => {
//     let tempErrors = {};

//     // Basic required fields
//     if (!formData.firstName.trim()) tempErrors.firstName = "First Name is required.";
//     if (!formData.lastName.trim()) tempErrors.lastName = "Last Name is required.";
//     if (!isEditMode && !formData.password.trim()) tempErrors.password = "Password is required.";
//     if (!formData.email.trim()) tempErrors.email = "Email is required.";
//     if (formData.email && !/\S+@\S+\.\S+/.test(formData.email))
//       tempErrors.email = "Invalid email address.";
//     if (!formData.mobileNo.trim()) tempErrors.mobileNo = "Mobile Number is required.";
//     if (!formData.address.trim()) tempErrors.address = "Address is required.";
//     if (!formData.department.trim()) tempErrors.department = "Department is required.";
//     if (!formData.role) tempErrors.role = "Role is required.";

//     // Conditional fields based on role
//     const roleId = formData.role;
//     if ([1, 2, 5, 6].includes(roleId) && !formData.site) {
//       tempErrors.site = "Site selection is required.";
//     }
//     if ([5, 6].includes(roleId) && !formData.zone) {
//       tempErrors.zone = "Zone selection is required.";
//     }
//     if ([5, 6].includes(roleId) && !formData.circle) {
//       tempErrors.circle = "Circle selection is required.";
//     }

//     setErrors(tempErrors);

//     // Return true if no errors
//     return Object.keys(tempErrors).length === 0;
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validate form before submission
//     if (!validateForm()) {
//       setSnackbar({
//         open: true,
//         message: "Please fix the errors in the form.",
//         severity: "error",
//       });
//       return;
//     }

//     // Determine if we're updating or registering
//     if (isEditMode) {
//       // Update existing user
//       const payload = {
//         id: formData.id,
//         firstName: formData.firstName,
//         lastName: formData.lastName,
//         // Exclude password when updating via this form
//         department: formData.department,
//         email: formData.email,
//         currentReportingTo: formData.currentReportingTo,
//         roleId: formData.role,
//         createdBy: userID,
//         mobileNo: formData.mobileNo,
//         address: formData.address,
//         siteId: formData.site || null,
//         circleId: formData.circle || null,
//         zoneId: formData.zone || null,
//         departmentId: formData.departmentId || null,
//       };

//       console.log("Updating user with payload:", payload);

//       try {
//         const response = await axios.put(
//           `${API_BASE_URL}C01UpdateUserDetails`,
//           payload,
//           {
//             headers: {
//               Authorization: `Bearer ${jwtToken}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         console.log("Update user response:", response);

//         if (response.status === 200) {
//           const responseData = response.data;

//           if (responseData.DATA && responseData.DATA.success) {
//             setSnackbar({
//               open: true,
//               message: "User updated successfully!",
//               severity: "success",
//             });
//             // Navigate back to UserReports after a short delay to allow users to see the snackbar
//             setTimeout(() => {
//               navigate("/userreport");
//             }, 2000);
//           } else {
//             const errorMsg = responseData.DATA.message || "Failed to update user.";
//             setSnackbar({
//               open: true,
//               message: errorMsg,
//               severity: "error",
//             });
//           }
//         } else {
//           const errorMsg = response.data.SYSTEM_MESSAGE || "Failed to update user.";
//           setSnackbar({
//             open: true,
//             message: errorMsg,
//             severity: "error",
//           });
//         }
//       } catch (error) {
//         const errorMsg = error.response?.data?.DATA?.message || "Error updating user.";
//         setSnackbar({
//           open: true,
//           message: errorMsg,
//           severity: "error",
//         });
//         console.error("Error updating user:", error);
//       }
//     } else {
//       // Register new user
//       const payload = {
//         password: formData.password,
//         firstName: formData.firstName,
//         lastName: formData.lastName,
//         department: formData.department,
//         email: formData.email,
//         currentReportingTo: formData.currentReportingTo,
//         roleId: formData.role,
//         createdBy: userID,
//         mobileNo: formData.mobileNo,
//         address: formData.address,
//         siteId: formData.site || null,
//         circleId: formData.circle || null,
//         zoneId: formData.zone || null,
//         departmentId: formData.departmentId || null,
//       };

//       console.log("Registering new user with payload:", payload);

//       try {
//         const response = await axios.post(
//           `${API_BASE_URL}C01RegisterNewUser`,
//           payload,
//           {
//             headers: {
//               Authorization: `Bearer ${jwtToken}`,
//               "Content-Type": "application/json",
//             },
//           }
//         );

//         console.log("Register user response:", response);

//         if (response.status === 200) {
//           const responseData = response.data;

//           if (responseData.DATA && responseData.DATA.success) {
//             setSnackbar({
//               open: true,
//               message: "User created successfully!",
//               severity: "success",
//             });
//             // Reset the form
//             setFormData({
//               id: "",
//               firstName: "",
//               lastName: "",
//               password: "",
//               email: "",
//               mobileNo: "",
//               address: "",
//               department: "",
//               role: "",
//               site: "",
//               circle: "",
//               zone: "",
//               departmentId: "",
//               currentReportingTo: "",
//             });
//             setFilteredZones([]);
//             setFilteredCircles([]);
//           } else {
//             const errorMsg = responseData.DATA.message || "Failed to submit data.";
//             setSnackbar({
//               open: true,
//               message: errorMsg,
//               severity: "error",
//             });
//           }
//         } else {
//           const errorMsg = response.data.SYSTEM_MESSAGE || "Failed to submit data.";
//           setSnackbar({
//             open: true,
//             message: errorMsg,
//             severity: "error",
//           });
//         }
//       } catch (error) {
//         const errorMsg = error.response?.data?.DATA?.message || "Error submitting data.";
//         setSnackbar({
//           open: true,
//           message: errorMsg,
//           severity: "error",
//         });
//         console.error("Error submitting data:", error);
//       }
//     }
//   };

//   // Handle Snackbar close
//   const handleSnackbarClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setSnackbar((prev) => ({ ...prev, open: false }));
//   };

//   // Handle password visibility toggle
//   const handleClickShowPassword = () => {
//     setShowPassword((prev) => !prev);
//   };

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   // Determine which fields to show based on role
//   const selectedRole = roles.find((role) => role.id === formData.role);

//   const showSite = [1, 2, 5, 6].includes(formData.role);
//   const showZone = [5, 6].includes(formData.role) && formData.site;
//   const showCircle = [5, 6].includes(formData.role) && formData.zone;

//   return (
//     <Box sx={{ display: "flex" }}>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           "& .MuiDrawer-paper": {
//             width: drawerWidth,
//             boxSizing: "border-box",
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
//         <SideBar isOpen={open} />
//       </Drawer>

//       <Main open={open}>
//         <TopBar toggleSidebar={handleDrawerToggle} />

//         <Box sx={{ padding: "20px" }}>
//           <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "6px" }}>
//             <Typography color="textPrimary">Registration</Typography>
//           </Breadcrumbs>

//           <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: "center" }}>
//             {isEditMode ? "Edit User" : "Registration Form"}
//           </Typography>

//           <FormSection elevation={3}>
//             <form onSubmit={handleSubmit}>
//               <Grid container spacing={2}>
//                 {/* First Name */}
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="First Name"
//                     name="firstName"
//                     value={formData.firstName}
//                     onChange={handleChange}
//                     required
//                     variant="outlined"
//                     size="small"
//                     error={Boolean(errors.firstName)}
//                     helperText={errors.firstName}
//                   />
//                 </Grid>
//                 {/* Last Name */}
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Last Name"
//                     name="lastName"
//                     value={formData.lastName}
//                     onChange={handleChange}
//                     required
//                     variant="outlined"
//                     size="small"
//                     error={Boolean(errors.lastName)}
//                     helperText={errors.lastName}
//                   />
//                 </Grid>

//                 {/* Password Field: Only show when creating a new user */}
//                 {!isEditMode && (
//                   <Grid item xs={12} sm={6}>
//                     <TextField
//                       fullWidth
//                       label="Password"
//                       name="password"
//                       type={showPassword ? "text" : "password"}
//                       value={formData.password}
//                       onChange={handleChange}
//                       required={!isEditMode}
//                       variant="outlined"
//                       size="small"
//                       error={Boolean(errors.password)}
//                       helperText={errors.password}
//                       InputProps={{
//                         endAdornment: (
//                           <InputAdornment position="end">
//                             <IconButton
//                               aria-label="toggle password visibility"
//                               onClick={handleClickShowPassword}
//                               onMouseDown={handleMouseDownPassword}
//                               edge="end"
//                             >
//                               {showPassword ? <VisibilityOff /> : <Visibility />}
//                             </IconButton>
//                           </InputAdornment>
//                         ),
//                       }}
//                     />
//                   </Grid>
//                 )}

//                 {/* Email */}
//                 <Grid item xs={12} sm={isEditMode ? 6 : 6}>
//                   <TextField
//                     fullWidth
//                     label="Email"
//                     name="email"
//                     type="email"
//                     value={formData.email}
//                     onChange={handleChange}
//                     required
//                     variant="outlined"
//                     size="small"
//                     error={Boolean(errors.email)}
//                     helperText={errors.email}
//                   />
//                 </Grid>

//                 {/* Mobile No */}
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Mobile No"
//                     name="mobileNo"
//                     value={formData.mobileNo}
//                     onChange={handleChange}
//                     required
//                     variant="outlined"
//                     size="small"
//                     error={Boolean(errors.mobileNo)}
//                     helperText={errors.mobileNo}
//                   />
//                 </Grid>

//                 {/* Address */}
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Address"
//                     name="address"
//                     value={formData.address}
//                     onChange={handleChange}
//                     required
//                     variant="outlined"
//                     size="small"
//                     error={Boolean(errors.address)}
//                     helperText={errors.address}
//                   />
//                 </Grid>

//                 {/* Roles Dropdown */}
//                 <Grid item xs={12}>
//                   <FormControl
//                     fullWidth
//                     variant="outlined"
//                     size="small"
//                     required
//                     error={Boolean(errors.role)}
//                   >
//                     <InputLabel>Role</InputLabel>
//                     <Select
//                       name="role"
//                       value={formData.role}
//                       onChange={handleChange}
//                       label="Role"
//                     >
//                       {roles.map((role) => (
//                         <MenuItem key={role.id} value={role.id}>
//                           {role.label}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                     {errors.role && (
//                       <Typography variant="caption" color="error">
//                         {errors.role}
//                       </Typography>
//                     )}
//                   </FormControl>
//                 </Grid>

//                 {/* Conditional Rendering Based on Role */}
//                 {showSite && (
//                   <Grid item xs={12}>
//                     <FormControl
//                       fullWidth
//                       variant="outlined"
//                       size="small"
//                       required
//                       error={Boolean(errors.site)}
//                     >
//                       <InputLabel>Select Site</InputLabel>
//                       <Select
//                         name="site"
//                         value={formData.site}
//                         onChange={handleChange}
//                         label="Select Site"
//                       >
//                         {sites.map((site) => (
//                           <MenuItem key={site.id} value={site.id}>
//                             {site.label}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                       {errors.site && (
//                         <Typography variant="caption" color="error">
//                           {errors.site}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   </Grid>
//                 )}

//                 {showZone && (
//                   <Grid item xs={12}>
//                     <FormControl
//                       fullWidth
//                       variant="outlined"
//                       size="small"
//                       required
//                       error={Boolean(errors.zone)}
//                     >
//                       <InputLabel>Select Zone</InputLabel>
//                       <Select
//                         name="zone"
//                         value={formData.zone}
//                         onChange={handleChange}
//                         label="Select Zone"
//                       >
//                         {filteredZones.map((zone) => (
//                           <MenuItem key={zone.id} value={zone.id}>
//                             {zone.label}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                       {errors.zone && (
//                         <Typography variant="caption" color="error">
//                           {errors.zone}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   </Grid>
//                 )}

//                 {showCircle && (
//                   <Grid item xs={12}>
//                     <FormControl
//                       fullWidth
//                       variant="outlined"
//                       size="small"
//                       required
//                       error={Boolean(errors.circle)}
//                     >
//                       <InputLabel>Select Circle</InputLabel>
//                       <Select
//                         name="circle"
//                         value={formData.circle}
//                         onChange={handleChange}
//                         label="Select Circle"
//                       >
//                         {filteredCircles.map((circle) => (
//                           <MenuItem key={circle.id} value={circle.id}>
//                             {circle.label}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                       {errors.circle && (
//                         <Typography variant="caption" color="error">
//                           {errors.circle}
//                         </Typography>
//                       )}
//                     </FormControl>
//                   </Grid>
//                 )}

//                 {/* Department Dropdown */}
//                 <Grid item xs={12}>
//                   <FormControl
//                     fullWidth
//                     variant="outlined"
//                     size="small"
//                     required
//                     error={Boolean(errors.department)}
//                   >
//                     <InputLabel>Department</InputLabel>
//                     <Select
//                       name="department"
//                       value={formData.department}
//                       onChange={handleChange}
//                       label="Department"
//                     >
//                       {departments.map((department, index) => (
//                         <MenuItem key={index} value={department}>
//                           {department}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                     {errors.department && (
//                       <Typography variant="caption" color="error">
//                         {errors.department}
//                       </Typography>
//                     )}
//                   </FormControl>
//                 </Grid>

//                 {/* Current Reporting To */}
//                 <Grid item xs={12} sm={6}>
//                   <TextField
//                     fullWidth
//                     label="Current Reporting To"
//                     name="currentReportingTo"
//                     value={formData.currentReportingTo}
//                     onChange={handleChange}
//                     variant="outlined"
//                     size="small"
//                   />
//                 </Grid>

//                 {/* Submit Button */}
//                 <Grid item xs={12}>
//                   <CustomButton type="submit" variant="contained">
//                     {isEditMode ? "Update" : "Register"}
//                   </CustomButton>
//                 </Grid>
//               </Grid>
//             </form>
//           </FormSection>
//         </Box>

//         {/* Snackbar for Feedback Messages */}
//         <Snackbar
//           open={snackbar.open}
//           autoHideDuration={6000}
//           onClose={handleSnackbarClose}
//           anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//         >
//           <Alert
//             onClose={handleSnackbarClose}
//             severity={snackbar.severity}
//             sx={{ width: "100%" }}
//           >
//             {snackbar.message}
//           </Alert>
//         </Snackbar>
//       </Main>
//     </Box>
//   );
// };

// export default RegistrationForm;


import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Box,
  Typography,
  Drawer,
  Breadcrumbs,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import { styled } from "@mui/material/styles";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const API_BASE_URL = "http://3.71.87.205:8083/CaneRateApp/";

const drawerWidth = 240;

// Styled Main component for layout
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const CustomButton = styled(Button)({
  backgroundColor: "#4caf50",
  color: "#fff",
  fontWeight: "bold",
  padding: "10px 30px",
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#45a049",
  },
  transition: "background-color 0.3s ease",
});

const FormSection = styled(Paper)({
  padding: "20px",
  margin: "20px auto",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  borderRadius: "15px",
  maxWidth: "900px",
  backgroundColor: "#f9f9f9",
});

const RegistrationForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Determine if we're editing based on presence of user data in location state
  const userToEdit = location.state?.user || null;
  const isEditMode = Boolean(userToEdit);

  // Function to safely retrieve and parse localStorage data
  const getLocalStorageData = () => {
    const dataString = localStorage.getItem("UserDetailData");
    if (dataString) {
      try {
        const parsedData = JSON.parse(dataString);
        if (parsedData && parsedData.jwt_token && parsedData.sites) {
          return parsedData;
        } else {
          console.error("Missing jwt_token or sites in UserDetailData.");
          return null;
        }
      } catch (err) {
        console.error("Error parsing UserDetailData from localStorage:", err);
        return null;
      }
    }
    console.error("UserDetailData is not found in localStorage.");
    return null;
  };

  const localData = getLocalStorageData();
  const jwtToken = localData ? localData.jwt_token : "";
  const userID = localData ? localData.user_id : null;

  // Initialize form data based on mode (edit or register)
  const initialFormData = isEditMode
    ? {
        id: userToEdit.id || "",
        firstName: userToEdit.firstName || "",
        lastName: userToEdit.lastName || "",
        password: "", // Password is not set when editing
        email: userToEdit.email || "",
        mobileNo: userToEdit.mobileNo || "",
        address: userToEdit.address || "",
        department: userToEdit.department || "",
        role: userToEdit.roleId || "",
        site: userToEdit.siteId || "",
        circle: userToEdit.circleId || "",
        zone: userToEdit.zoneId || "",
        departmentId: userToEdit.departmentId || "",
        currentReportingTo: userToEdit.currentReportingTo || "",
      }
    : {
        id: "",
        firstName: "",
        lastName: "",
        password: "",
        email: "",
        mobileNo: "",
        address: "",
        department: "",
        role: "",
        site: "",
        circle: "",
        zone: "",
        departmentId: "",
        currentReportingTo: "",
      };

  const [formData, setFormData] = useState(initialFormData);

  const [open, setOpen] = useState(true);
  const [sites, setSites] = useState([]);
  const [circles, setCircles] = useState([]);
  const [zones, setZones] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);

  // Additional state for filtered zones and circles
  const [filteredZones, setFilteredZones] = useState([]);
  const [filteredCircles, setFilteredCircles] = useState([]);

  // Snackbar state
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success", // 'success' | 'error' | 'warning' | 'info'
  });

  // State for form errors (basic validation)
  const [errors, setErrors] = useState({});

  // State to manage password visibility
  const [showPassword, setShowPassword] = useState(false);

  // Load dropdown data from localStorage
  useEffect(() => {
    if (localData) {
      setSites(localData.sites || []);
      setCircles(localData.circles || []);
      setZones(localData.zones || []);
      setDepartments(localData.user_departments || []);
      setRoles(localData.user_roles || []);

      // If editing, set filtered zones and circles based on initial data
      if (isEditMode) {
        if (formData.site) {
          const initialFilteredZones = (localData.zones || []).filter(
            (zone) => zone.siteId === formData.site
          );
          setFilteredZones(initialFilteredZones);
        }

        if (formData.zone && formData.site) {
          const initialFilteredCircles = (localData.circles || []).filter(
            (circle) =>
              circle.zoneId === formData.zone && circle.siteId === formData.site
          );
          setFilteredCircles(initialFilteredCircles);
        }
      }
    }
  }, [localData, isEditMode, formData.site, formData.zone]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Determine if the field is an ID that should be stored as a number
    const idFields = ["role", "site", "zone", "circle", "departmentId"];
    const isIdField = idFields.includes(name);

    const parsedValue = isIdField && value ? Number(value) : value;

    // If role changes, reset dependent fields
    if (name === "role") {
      setFormData((prev) => ({
        ...prev,
        role: parsedValue,
        site: "",
        zone: "",
        circle: "",
      }));
      setFilteredZones([]);
      setFilteredCircles([]);
      return;
    }

    // If site changes, reset zone and circle
    if (name === "site") {
      const selectedSiteId = parsedValue;
      const filtered = zones.filter((zone) => zone.siteId === selectedSiteId);
      setFilteredZones(filtered);
      setFormData((prev) => ({
        ...prev,
        site: selectedSiteId,
        zone: "",
        circle: "",
      }));
      setFilteredCircles([]);
      return;
    }

    // If zone changes, reset circle
    if (name === "zone") {
      const selectedZoneId = parsedValue;
      const selectedSiteId = formData.site; // Ensure we have the selected site ID
      const filtered = circles.filter(
        (circle) =>
          circle.zoneId === selectedZoneId && circle.siteId === selectedSiteId
      );
      setFilteredCircles(filtered);
      setFormData((prev) => ({
        ...prev,
        zone: selectedZoneId,
        circle: "",
      }));
      return;
    }

    // Handle other changes
    setFormData((prev) => ({
      ...prev,
      [name]: parsedValue,
    }));

    // Simple validation (e.g., email format)
    if (name === "email") {
      const emailValid = /\S+@\S+\.\S+/.test(parsedValue);
      setErrors((prev) => ({
        ...prev,
        email: emailValid ? "" : "Invalid email address",
      }));
    }
  };

  const validateForm = () => {
    let tempErrors = {};

    // Basic required fields
    if (!formData.firstName.trim()) tempErrors.firstName = "First Name is required.";
    if (!formData.lastName.trim()) tempErrors.lastName = "Last Name is required.";
    if (!isEditMode && !formData.password.trim()) tempErrors.password = "Password is required.";
    if (!formData.email.trim()) tempErrors.email = "Email is required.";
    if (formData.email && !/\S+@\S+\.\S+/.test(formData.email))
      tempErrors.email = "Invalid email address.";
    if (!formData.mobileNo.trim()) tempErrors.mobileNo = "Mobile Number is required.";
    if (!formData.address.trim()) tempErrors.address = "Address is required.";
    if (!formData.department.trim()) tempErrors.department = "Department is required.";
    if (!formData.role) tempErrors.role = "Role is required.";

    // Conditional fields based on role
    const roleId = formData.role;
    if ([1, 2, 5, 6].includes(roleId) && !formData.site) {
      tempErrors.site = "Site selection is required.";
    }
    if ([5, 6].includes(roleId) && !formData.zone) {
      tempErrors.zone = "Zone selection is required.";
    }
    if ([5, 6].includes(roleId) && !formData.circle) {
      tempErrors.circle = "Circle selection is required.";
    }

    setErrors(tempErrors);

    // Return true if no errors
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) {
      setSnackbar({
        open: true,
        message: "Please fix the errors in the form.",
        severity: "error",
      });
      return;
    }

    // Determine if we're updating or registering
    if (isEditMode) {
      // Update existing user
      const payload = {
        id: formData.id,
        firstName: formData.firstName,
        lastName: formData.lastName,
        // Exclude password when updating via this form
        department: formData.department,
        email: formData.email,
        currentReportingTo: formData.currentReportingTo,
        roleId: formData.role,
        createdBy: userID,
        mobileNo: formData.mobileNo,
        address: formData.address,
        siteId: formData.site || null,
        circleId: formData.circle || null,
        zoneId: formData.zone || null,
        departmentId: formData.departmentId || null,
      };

      console.log("Updating user with payload:", payload);

      try {
        const response = await axios.put(
          `${API_BASE_URL}C01UpdateUserDetails`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Update user response:", response);

        if (response.status === 200) {
          const responseData = response.data;

          if (responseData.DATA && responseData.DATA.success) {
            setSnackbar({
              open: true,
              message: "User updated successfully!",
              severity: "success",
            });
            // Navigate back to UserReports after a short delay to allow users to see the snackbar
            setTimeout(() => {
              navigate("/userreport");
            }, 2000);
          } else {
            const errorMsg = responseData.DATA.message || "Failed to update user.";
            setSnackbar({
              open: true,
              message: errorMsg,
              severity: "error",
            });
          }
        } else {
          const errorMsg = response.data.SYSTEM_MESSAGE || "Failed to update user.";
          setSnackbar({
            open: true,
            message: errorMsg,
            severity: "error",
          });
        }
      } catch (error) {
        const errorMsg = error.response?.data?.DATA?.message || "Error updating user.";
        setSnackbar({
          open: true,
          message: errorMsg,
          severity: "error",
        });
        console.error("Error updating user:", error);
      }
    } else {
      // Register new user
      const payload = {
        password: formData.password,
        firstName: formData.firstName,
        lastName: formData.lastName,
        department: formData.department,
        email: formData.email,
        currentReportingTo: formData.currentReportingTo,
        roleId: formData.role,
        createdBy: userID,
        mobileNo: formData.mobileNo,
        address: formData.address,
        siteId: formData.site || null,
        circleId: formData.circle || null,
        zoneId: formData.zone || null,
        departmentId: formData.departmentId || null,
      };

      console.log("Registering new user with payload:", payload);

      try {
        const response = await axios.post(
          `${API_BASE_URL}C01RegisterNewUser`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Register user response:", response);

        if (response.status === 200) {
          const responseData = response.data;

          if (responseData.DATA && responseData.DATA.success) {
            setSnackbar({
              open: true,
              message: "User created successfully!",
              severity: "success",
            });
            // Reset the form
            setFormData({
              id: "",
              firstName: "",
              lastName: "",
              password: "",
              email: "",
              mobileNo: "",
              address: "",
              department: "",
              role: "",
              site: "",
              circle: "",
              zone: "",
              departmentId: "",
              currentReportingTo: "",
            });
            setFilteredZones([]);
            setFilteredCircles([]);
          } else {
            const errorMsg = responseData.DATA.message || "Failed to submit data.";
            setSnackbar({
              open: true,
              message: errorMsg,
              severity: "error",
            });
          }
        } else {
          const errorMsg = response.data.SYSTEM_MESSAGE || "Failed to submit data.";
          setSnackbar({
            open: true,
            message: errorMsg,
            severity: "error",
          });
        }
      } catch (error) {
        const errorMsg = error.response?.data?.DATA?.message || "Error submitting data.";
        setSnackbar({
          open: true,
          message: errorMsg,
          severity: "error",
        });
        console.error("Error submitting data:", error);
      }
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  // Handle password visibility toggle
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Determine which fields to show based on role
  const selectedRole = roles.find((role) => role.id === formData.role);

  // Updated logic to specifically handle Circle Officer (Role ID: 5)
  const isCircleOfficer = formData.role === 5;

  const showSite = [1, 2, 5, 6].includes(formData.role);
  const showZone = [5, 6].includes(formData.role) && formData.site;
  const showCircle = [5, 6].includes(formData.role) && formData.zone;

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>

      <Main open={open}>
        <TopBar toggleSidebar={handleDrawerToggle} />

        <Box sx={{ padding: "20px" }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: "6px" }}>
            <Typography color="textPrimary">Registration</Typography>
          </Breadcrumbs>

          <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: "center" }}>
            {isEditMode ? "Edit User" : "Registration Form"}
          </Typography>

          <FormSection elevation={3}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {/* First Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.firstName)}
                    helperText={errors.firstName}
                  />
                </Grid>
                {/* Last Name */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.lastName)}
                    helperText={errors.lastName}
                  />
                </Grid>

                {/* Password Field: Only show when creating a new user */}
                {!isEditMode && (
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Password"
                      name="password"
                      type={showPassword ? "text" : "password"}
                      value={formData.password}
                      onChange={handleChange}
                      required={!isEditMode}
                      variant="outlined"
                      size="small"
                      error={Boolean(errors.password)}
                      helperText={errors.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                )}

                {/* Email */}
                <Grid item xs={12} sm={isEditMode ? 6 : 6}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                  />
                </Grid>

                {/* Mobile No */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Mobile No"
                    name="mobileNo"
                    value={formData.mobileNo}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.mobileNo)}
                    helperText={errors.mobileNo}
                  />
                </Grid>

                {/* Address */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Address"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    required
                    variant="outlined"
                    size="small"
                    error={Boolean(errors.address)}
                    helperText={errors.address}
                  />
                </Grid>

                {/* Roles Dropdown */}
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.role)}
                  >
                    <InputLabel>Role</InputLabel>
                    <Select
                      name="role"
                      value={formData.role}
                      onChange={handleChange}
                      label="Role"
                    >
                      {roles.map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.role && (
                      <Typography variant="caption" color="error">
                        {errors.role}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Conditional Rendering Based on Role */}
                {showSite && (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      required
                      error={Boolean(errors.site)}
                    >
                      <InputLabel>Select Site</InputLabel>
                      <Select
                        name="site"
                        value={formData.site}
                        onChange={handleChange}
                        label="Select Site"
                      >
                        {sites.map((site) => (
                          <MenuItem key={site.id} value={site.id}>
                            {site.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.site && (
                        <Typography variant="caption" color="error">
                          {errors.site}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                )}

                {showZone && (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      required
                      error={Boolean(errors.zone)}
                    >
                      <InputLabel>Select Zone</InputLabel>
                      <Select
                        name="zone"
                        value={formData.zone}
                        onChange={handleChange}
                        label="Select Zone"
                      >
                        {filteredZones.map((zone) => (
                          <MenuItem key={zone.id} value={zone.id}>
                            {zone.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.zone && (
                        <Typography variant="caption" color="error">
                          {errors.zone}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                )}

                {showCircle && (
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      size="small"
                      required
                      error={Boolean(errors.circle)}
                    >
                      <InputLabel>Select Circle</InputLabel>
                      <Select
                        name="circle"
                        value={formData.circle}
                        onChange={handleChange}
                        label="Select Circle"
                      >
                        {filteredCircles.map((circle) => (
                          <MenuItem key={circle.id} value={circle.id}>
                            {circle.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.circle && (
                        <Typography variant="caption" color="error">
                          {errors.circle}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                )}

                {/* Department Dropdown */}
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    required
                    error={Boolean(errors.department)}
                  >
                    <InputLabel>Department</InputLabel>
                    <Select
                      name="department"
                      value={formData.department}
                      onChange={handleChange}
                      label="Department"
                    >
                      {departments.map((department, index) => (
                        <MenuItem key={index} value={department}>
                          {department}
                        </MenuItem>
                      ))}
                    </Select>
                    {errors.department && (
                      <Typography variant="caption" color="error">
                        {errors.department}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>

                {/* Current Reporting To */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Current Reporting To"
                    name="currentReportingTo"
                    value={formData.currentReportingTo}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <CustomButton type="submit" variant="contained">
                    {isEditMode ? "Update" : "Register"}
                  </CustomButton>
                </Grid>
              </Grid>
            </form>
          </FormSection>
        </Box>

        {/* Snackbar for Feedback Messages */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Main>
    </Box>
  );
};

export default RegistrationForm;
