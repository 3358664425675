// import React from "react";
// import { Navigate, Outlet } from "react-router-dom";

// const PrivateRoute = ({ allowedRoles }) => {
//   const userData = JSON.parse(localStorage.getItem("UserDetailData"));

//   if (!userData) {
//     return <Navigate to="/caneApp/unauthorized" />; // Redirect to login if not authenticated
//   }

//   return allowedRoles.includes(userData.user_role) ? <Outlet /> : <Navigate to="/caneApp/unauthorized" />;
// };

// export default PrivateRoute;


// src/Components/PrivateRoute.js

import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ allowedRoles }) => {
  // Retrieve the user data from localStorage
  const userDataString = localStorage.getItem("UserDetailData");
  let userData = null;

  try {
    userData = userDataString ? JSON.parse(userDataString) : null;
  } catch (error) {
    console.error("Error parsing UserDetailData from localStorage:", error);
    // If parsing fails, treat the user as unauthenticated
    userData = null;
  }

  // If userData is null, the user is not authenticated
  if (!userData) {
    return <Navigate to="/caneApp" replace />;
  }

  // If the user's role is not in the allowedRoles array, redirect to unauthorized
  if (!allowedRoles.includes(userData.user_role)) {
    return <Navigate to="/caneApp/unauthorized" replace />;
  }

  // If authenticated and authorized, render the child components
  return <Outlet />;
};

export default PrivateRoute;
