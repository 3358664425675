import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const FixedTable = React.memo(({ rows, columns, columnGroupingModel }) => {
  return (
    <DataGrid
      disableColumnSorting
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      disableColumnMenu={true}
      hideFooter
      autoHeight
      experimentalFeatures={{ columnGrouping: true }}
      sx={{
        '& .MuiDataGrid-cell': {
          textAlign: 'center',
        },
      }}
    />
  );
});

export default FixedTable;
