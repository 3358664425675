import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  Breadcrumbs,
  Typography,
  Drawer,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Tooltip,
  DialogTitle,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  Pagination,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import TopBar from './TopBar';
import SideBar from './SideBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import loaderImage from '../assects/Images/Logos/CRVlogo.png';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config';

// Define the width of the drawer (sidebar)
const drawerWidth = 240;

// Styled Main component for layout
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DeclinedRequests = () => {
  // Define a greenish and blue theme
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#81c784', // Light Green
          },
          secondary: {
            main: '#64b5f6', // Blue
          },
        },
      }),
    []
  );

  const [open, setOpen] = useState(true);
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false);
  const [openNotificationModal, setOpenNotificationModal] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const checkNotificationPermission = async () => {
      const permission = Notification.permission;
      if (permission === 'granted') {
        setNotificationPermissionGranted(true);
      } else {
        setOpenNotificationModal(true);
      }
    };

    checkNotificationPermission();
  }, []);

  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  };

  const [trackingIds, setTrackingIds] = useState([]);
  const [expandedTrackingIds, setExpandedTrackingIds] = useState({});
  const [requestDetails, setRequestDetails] = useState({});
  const [expandedRequests, setExpandedRequests] = useState({});
  const [competitorsData, setCompetitorsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // State for comments
  const [siteCaneHeadComments, setSiteCaneHeadComments] = useState({});
  const [auditComments, setAuditComments] = useState({});

  // State Variables for Search and Pagination
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);

  const navigate = useNavigate();

  useEffect(() => {
    fetchDeclinedTrackingIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchDeclinedTrackingIds = async () => {
    try {
      setLoading(true);
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setSnackbarMessage('Authentication token missing. Please log in again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const response = await axios.post(
        `${API_BASE_URL}C01RequestsList`,
        { request_type: 3 }, // Request type 3 for declined requests
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request_list) {
        // No need to filter, since request_type: 3 returns declined requests
        setTrackingIds(response.data.DATA.request_list);
      } else {
        console.warn('No request_list found in response.');
      }
    } catch (error) {
      console.error('Failed to fetch tracking IDs:', error);
      setSnackbarMessage('Failed to fetch tracking IDs. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const fetchRequestDetails = async (tracking_id) => {
    try {
      setLoading(true);
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setSnackbarMessage('Authentication token missing. Please log in again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const response = await axios.post(
        `${API_BASE_URL}C01TrackIdRequestDetail`,
        { request_type: 3, track_id: tracking_id }, // Use request_type: 3 for declined request details
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request_list) {
        // The response contains the request_list for the tracking_id
        const requestList = response.data.DATA.request_list;

        setRequestDetails((prev) => ({
          ...prev,
          [tracking_id]: {
            request_list: requestList,
            site_cane_head_comments: response.data.DATA.site_cane_head_comments,
            audit_comments: response.data.DATA.audit_comments,
          },
        }));

        // Initialize comments state
        setSiteCaneHeadComments((prev) => ({
          ...prev,
          [tracking_id]: response.data.DATA.site_cane_head_comments || '',
        }));
        setAuditComments((prev) => ({
          ...prev,
          [tracking_id]: response.data.DATA.audit_comments || '',
        }));
      } else {
        console.warn(`No request_list found for tracking_id: ${tracking_id}`);
      }
    } catch (error) {
      console.error('Failed to fetch request details:', error);
      setSnackbarMessage('Failed to fetch request details. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const handleExpandTrackingId = (tracking_id) => {
    setExpandedTrackingIds((prev) => ({
      ...prev,
      [tracking_id]: !prev[tracking_id],
    }));
    if (!expandedTrackingIds[tracking_id]) {
      fetchRequestDetails(tracking_id);
    }
  };

  const handleExpandRequest = (tracking_id, requestIndex) => {
    const key = `${tracking_id}-${requestIndex}`;
    setExpandedRequests((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleViewCompetitors = (competitors) => {
    setCompetitorsData(competitors || []);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

 // Handle Chat Icon Click
 const handleChatClick = (tracking_id, request_status_id,request_type=3) => {
  console.log('Tracking ID:', tracking_id);
  console.log('Request Status ID:', request_status_id);
   // Check values here
  navigate(`/managementchat?trackingId=${tracking_id}&requestStatusId=${request_status_id}&requestType=${request_type}`);
};

  // Handle Search Query Changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1); // Reset to first page on search
  };

  // Handle Items Per Page Change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1); // Reset to first page on items per page change
  };

  // Filtered Tracking IDs based on Search Query
  const filteredTrackingIds = trackingIds.filter((tracking) => {
    const search = searchQuery.toLowerCase();
    return (
      tracking.tracking_id?.toString().includes(search) ||
      tracking.site_name.toLowerCase().includes(search) ||
      tracking.created_by_name.toLowerCase().includes(search)
    );
  });

  // Pagination Logic
  const totalPages = Math.ceil(filteredTrackingIds.length / itemsPerPage);
  const currentTrackingIds = filteredTrackingIds.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle Page Change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        {/* SideBar */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <SideBar isOpen={open} />
        </Drawer>
        {/* Main Content */}
        <Main open={open}>
          {/* TopBar */}
          <TopBar toggleSidebar={handleDrawerToggle} />
          <Box sx={{ padding: '20px' }}>
            {/* Breadcrumbs */}
            <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '6px' }}>
              <Typography color="textPrimary">Declined Requests</Typography>
            </Breadcrumbs>
            <br />
            {/* Search and Items Per Page Controls */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                flexWrap: 'wrap',
                gap: 2, // Adds gap between elements on smaller screens
              }}
            >
              <TextField
                label="Search Tracking IDs"
                variant="outlined"
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Search by Tracking ID, Site Name, or Created By"
                sx={{ width: 300 }}
              />
              <Select
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                size="small"
                sx={{ minWidth: 120 }}
              >
                {[25, 50, 100, 250, 500].map((count) => (
                  <MenuItem key={count} value={count}>
                    {count} per page
                  </MenuItem>
                ))}
              </Select>
            </Box>

            {/* Declined Requests Table */}
            <Box sx={{ position: 'relative' }}>
              <TableContainer
                component={Paper}
                sx={{
                  margin: '20px',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  padding: 2,
                  width: 'auto',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme.palette.primary.light }}>
                      <TableCell align="center" sx={{ padding: '12px', fontWeight: 'bold' }}>
                        Site Name
                      </TableCell>
                      <TableCell align="center" sx={{ padding: '12px', fontWeight: 'bold' }}>
                        Created By
                      </TableCell>
                      <TableCell align="center" sx={{ padding: '12px', fontWeight: 'bold' }}>
                        Created On
                      </TableCell>
                      <TableCell align="center" sx={{ padding: '12px', fontWeight: 'bold' }}>
                        Status
                      </TableCell>
                      <TableCell align="center" sx={{ padding: '12px', fontWeight: 'bold' }}>
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentTrackingIds.map((tracking) => (
                      <React.Fragment key={tracking.tracking_id}>
                        {/* Tracking ID Header Row */}
                        <TableRow>
                          <TableCell colSpan={5} sx={{ backgroundColor: '#e0f2f1', padding: '8px 16px' }}>
                            <Box
                              display="flex"
                              alignItems="center"
                              onClick={() => handleExpandTrackingId(tracking.tracking_id)}
                              sx={{ cursor: 'pointer' }}
                            >
                              <IconButton size="small">
                                {expandedTrackingIds[tracking.tracking_id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                              </IconButton>
                              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                Tracking ID: {tracking.tracking_id}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>

                        {/* Tracking Details Row */}
                        <TableRow
                          hover
                          sx={{
                            backgroundColor: expandedTrackingIds[tracking.tracking_id]
                              ? 'action.hover'
                              : 'inherit',
                          }}
                        >
                          <TableCell align="center" sx={{ padding: '12px' }}>
                            {tracking.site_name}
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '12px' }}>
                            {tracking.created_by_name}
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '12px' }}>
                            {tracking.created_on}
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '12px' }}>
                            <Typography
                              style={{
                                color: tracking.request_status === 'Declined' ? 'red' : 'black',
                                fontWeight: 'bold',
                              }}
                            >
                              {tracking.request_status}
                            </Typography>
                          </TableCell>
                          <TableCell align="center" sx={{ padding: '12px' }}>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleExpandTrackingId(tracking.tracking_id)}
                              sx={{ mr: 0.5, padding: '6px 12px', textTransform: 'none', fontSize: '0.875rem' }}
                            >
                              {expandedTrackingIds[tracking.tracking_id] ? 'Hide Details' : 'Show Details'}
                            </Button>
                            <Tooltip title="Chat">
                              <IconButton
                                color="secondary"
                                onClick={() => handleChatClick(tracking.tracking_id,tracking.request_status_id,tracking.request_type)}
                                sx={{ padding: '6px' }}
                              >
                                <ChatIcon />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>

                        {/* Collapse for Tracking Details */}
                        <TableRow>
                          <TableCell colSpan={5} sx={{ padding: 0 }}>
                            <Collapse
                              in={expandedTrackingIds[tracking.tracking_id]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box sx={{ padding: 2, backgroundColor: 'background.paper' }}>
                                {/* Comments Section */}
                                <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      label="Site Cane Head Comment"
                                      multiline
                                      fullWidth
                                      variant="outlined"
                                      value={siteCaneHeadComments[tracking.tracking_id] || ''}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{ backgroundColor: '#f5f5f5' }}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <TextField
                                      label="Audit Comment"
                                      multiline
                                      fullWidth
                                      variant="outlined"
                                      value={auditComments[tracking.tracking_id] || ''}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                      sx={{ backgroundColor: '#f5f5f5' }}
                                    />
                                  </Grid>
                                </Grid>

                                {/* Requests Table */}
                                {requestDetails[tracking.tracking_id]?.request_list && (
                                  <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                                    <Table size="small">
                                      <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                        <TableRow>
                                          {/* Changed "Request ID" to "Sr No." */}
                                          <TableCell
                                            align="center"
                                            sx={{ padding: '8px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                          >
                                            Sr No.
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            sx={{ padding: '8px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                          >
                                            Request Status
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            sx={{ padding: '8px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                          >
                                            Created On
                                          </TableCell>
                                          <TableCell
                                            align="center"
                                            sx={{ padding: '8px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                          >
                                            Actions
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {requestDetails[tracking.tracking_id]?.request_list?.map(
                                          (request, requestIndex) => (
                                            <React.Fragment key={request.request_detail[0]?.request_id}>
                                              <TableRow
                                                hover
                                                sx={{
                                                  backgroundColor: expandedRequests[
                                                    `${tracking.tracking_id}-${requestIndex}`
                                                  ]
                                                    ? 'action.hover'
                                                    : 'inherit',
                                                }}
                                              >
                                                {/* Display Serial Number Instead of Request ID */}
                                                <TableCell
                                                  align="center"
                                                  sx={{ fontWeight: 'bold', padding: '8px', fontSize: '0.875rem' }}
                                                >
                                                  {requestIndex + 1}
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '8px', fontSize: '0.875rem' }}>
                                                  {request.request_status}
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '8px', fontSize: '0.875rem' }}>
                                                  {request.request_detail[0]?.created_on}
                                                </TableCell>
                                                <TableCell align="center" sx={{ padding: '8px', fontSize: '0.875rem' }}>
                                                  <Button
                                                    variant="text"
                                                    size="small"
                                                    onClick={() =>
                                                      handleExpandRequest(tracking.tracking_id, requestIndex)
                                                    }
                                                    sx={{
                                                      textTransform: 'none',
                                                      mr: 0.5,
                                                      padding: '4px 8px',
                                                      fontSize: '0.75rem',
                                                    }}
                                                  >
                                                    {expandedRequests[`${tracking.tracking_id}-${requestIndex}`]
                                                      ? 'Hide Details'
                                                      : 'Show Details'}
                                                  </Button>
                                                </TableCell>
                                              </TableRow>

                                              {/* Centers Data */}
                                              <TableRow>
                                                <TableCell colSpan={5} sx={{ padding: 0 }}>
                                                  <Collapse
                                                    in={expandedRequests[
                                                      `${tracking.tracking_id}-${requestIndex}`
                                                    ]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <Box sx={{ margin: 0.5 }}>
                                                      <Table size="small">
                                                        <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                          <TableRow>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Center Name
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Circle
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Current Trolly Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Current Farsha Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Revised Trolly Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Revised Farsha Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '8px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Actions
                                                            </TableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                          {request.request_detail?.map(
                                                            (center, centerIndex) => (
                                                              <TableRow key={centerIndex}>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  {center.center_name}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  {center.circle_label}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  {center.present_trolly_rate}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  {center.present_farsha_rate}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  {center.revised_trolly_rate}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  {center.revised_farsha_rate}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding: '8px',
                                                                    fontSize: '0.75rem',
                                                                  }}
                                                                >
                                                                  <Tooltip title="View Competitors">
                                                                    <IconButton
                                                                      color="primary"
                                                                      size="small"
                                                                      onClick={() =>
                                                                        handleViewCompetitors(center.Competitors)
                                                                      }
                                                                      sx={{ padding: '4px' }}
                                                                    >
                                                                      <VisibilityIcon fontSize="small" />
                                                                    </IconButton>
                                                                  </Tooltip>
                                                                </TableCell>
                                                              </TableRow>
                                                            )
                                                          )}
                                                        </TableBody>
                                                      </Table>
                                                    </Box>
                                                  </Collapse>
                                                </TableCell>
                                              </TableRow>
                                            </React.Fragment>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination Controls */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '16px',
                }}
              >
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  color="primary"
                />
              </Box>

              {/* Loading Overlay */}
              {loading && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '20px',
                    left: '20px',
                    right: '20px',
                    bottom: '20px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    zIndex: 9999,
                  }}
                >
                  <img
                    src={loaderImage}
                    alt="Loading..."
                    style={{
                      width: '100px',
                      height: '100px',
                      borderRadius: '50%',
                      animation: 'spin 2s linear infinite',
                    }}
                  />
                  <style>
                    {`
                      @keyframes spin {
                        0% { transform: rotate(0deg); }
                        100% { transform: rotate(360deg); }
                      }
                    `}
                  </style>
                </Box>
              )}
            </Box>
          </Box>
        </Main>

        {/* Modal for Notification Permission */}
        <Dialog
          open={openNotificationModal}
          onClose={handleCloseNotificationModal}
          aria-labelledby="notification-permission-dialog"
        >
          <DialogContent>
            <Typography variant="h6" gutterBottom>
              Notification Permission Denied
            </Typography>
            <Typography variant="body2">
              Please enable notifications in your browser settings:
              <ol>
                <li>Click on the lock icon near the address bar.</li>
                <li>Go to 'Site settings' or 'Notifications'.</li>
                <li>Find and enable notifications for this site.</li>
              </ol>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseNotificationModal}
              sx={{
                background: 'linear-gradient(to right, #a8e063, #56ab2f)',
                color: 'white',
                '&:hover': {
                  background: 'linear-gradient(to right, #56ab2f, #a8e063)',
                },
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Competitors Data Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>Competitors Data</DialogTitle>
          <DialogContent>
            {competitorsData && competitorsData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Competitor Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Current Trolly Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Revised Trolly Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Current Farsha Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Revised Farsha Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {competitorsData.map((competitor, index) => (
                      <TableRow key={index}>
                        <TableCell>{competitor.competitor_label}</TableCell>
                        <TableCell>{competitor.present_trolly_rate}</TableCell>
                        <TableCell>{competitor.revised_trolly_rate}</TableCell>
                        <TableCell>{competitor.present_farsha_rate}</TableCell>
                        <TableCell>{competitor.revised_farsha_rate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No competitors data available.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for Notifications */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default DeclinedRequests;
