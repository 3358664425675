import React, { useState, useEffect } from 'react';
import {
  Box,
  Breadcrumbs,
  Typography,
  Link,
  Drawer,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
  IconButton,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TopBar from './TopBar';
import SideBar from './SideBar';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import API_BASE_URL from '../config';
import swal from 'sweetalert';


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const SpecialRequestTable = () => {
  const [open, setOpen] = useState(true);
  const [formValues, setFormValues] = useState({
    growerName: '',
    passBook: '',
    cnic: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [comment, setComment] = useState('');
  const [competitors, setCompetitors] = useState([]);
  const [selectedCompetitor, setSelectedCompetitor] = useState('');
  const [trolly, setTrolly] = useState('');
  const [farsha, setFarsha] = useState('');
  const [competitorEntries, setCompetitorEntries] = useState([]);
  const [loadingCompetitors, setLoadingCompetitors] = useState(false);
  const [apiError, setApiError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [editingCompetitorId, setEditingCompetitorId] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const fetchCompetitorsFromLocalStorage = () => {
      setLoadingCompetitors(true);
      try {
        const userDetailData = JSON.parse(localStorage.getItem('UserDetailData')) || {};
        if (userDetailData && userDetailData.competitors) {
          setCompetitors(userDetailData.competitors);
        } else {
          throw new Error('No competitors data found in local storage');
        }
      } catch (error) {
        setApiError(error.message);
      } finally {
        setLoadingCompetitors(false);
      }
    };

    fetchCompetitorsFromLocalStorage();
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // If the input is for CNIC, enforce digit-only and max length
    if (name === 'cnic') {
      // Remove any non-digit characters
      const sanitizedValue = value.replace(/\D/g, '');

      // Limit to 13 digits
      if (sanitizedValue.length > 13) {
        return;
      }

      setFormValues({ ...formValues, [name]: sanitizedValue });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const validateForm = () => {
    const errors = {};

    if (!formValues.growerName.trim() || !/^[a-zA-Z\s]+$/.test(formValues.growerName)) {
      errors.growerName = 'Grower Name is required and must contain only letters';
    }

    if (!formValues.passBook && !formValues.cnic) {
      errors.passBook = 'Either Passbook No or CNIC No is required';
      errors.cnic = 'Either Passbook No or CNIC No is required';
    }

    if (formValues.cnic) {
      if (!/^\d{13}$/.test(formValues.cnic)) {
        errors.cnic = 'CNIC must be exactly 13 digits without dashes';
      }
    }

    if ((!selectedCompetitor && (trolly || farsha))) {
      errors.competitor = 'You must select a competitor to add Trolly or Farsha values';
    }

    if (selectedCompetitor && ((!trolly || Number(trolly) <= 0) && (!farsha || Number(farsha) <= 0))) {
      errors.competitor = 'Trolly or Farsha must be greater than zero';
    }

    // **Updated Validation for Strictly Positive Values**
    if (trolly && Number(trolly) <= 0) {
      errors.trolly = 'Trolly value must be greater than 0';
    }

    if (farsha && Number(farsha) <= 0) {
      errors.farsha = 'Farsha value must be greater than 0';
    }

    // **Mandatory Comment Validation**
    if (!comment.trim()) {
      errors.comment = 'Comment is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleAddCompetitor = () => {
    if (validateForm()) {
      const competitor = competitors.find((comp) => comp.id === selectedCompetitor);
      if (competitor) {
        const newEntry = {
          id: selectedCompetitor,
          name: competitor.label,
          trolly: trolly || '-',
          farsha: farsha || '-',
        };

        if (editingCompetitorId) {
          // Update existing competitor entry
          setCompetitorEntries((prevEntries) =>
            prevEntries.map((entry) =>
              entry.id === editingCompetitorId ? newEntry : entry
            )
          );
          setEditingCompetitorId(null); // Reset after editing
        } else {
          // Add new competitor entry
          if (competitorEntries.some((entry) => entry.id === newEntry.id)) {
            setSnackbar({
              open: true,
              message: 'Competitor already added',
              severity: 'warning',
            });
            return;
          }
          setCompetitorEntries([...competitorEntries, newEntry]);
        }

        setSelectedCompetitor('');
        setTrolly('');
        setFarsha('');
        setFormErrors(prevErrors => {
          const { trolly, farsha, ...rest } = prevErrors;
          return rest;
        });
      }
    } else {
      setSnackbar({
        open: true,
        message: 'Please fix the errors in the form',
        severity: 'error',
      });
    }
  };

  const handleRemoveCompetitor = (id) => {
    setCompetitorEntries(competitorEntries.filter((entry) => entry.id !== id));
  };

  const handleEditCompetitor = (id) => {
    const competitorToEdit = competitorEntries.find((entry) => entry.id === id);
    if (competitorToEdit) {
      setSelectedCompetitor(competitorToEdit.id);
      setTrolly(competitorToEdit.trolly !== '-' ? competitorToEdit.trolly : '');
      setFarsha(competitorToEdit.farsha !== '-' ? competitorToEdit.farsha : '');
      setEditingCompetitorId(id);
    }
  };

  const handleSendToCaneHead = () => {
    const isFormValid = validateForm();
  
    if (isFormValid) {
      // Add SweetAlert confirmation dialog
      swal({
        title: 'Are you sure?',
        text: 'Once submitted, you will not be able to recover this data!',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
      }).then((willSubmit) => {
        if (willSubmit) {
          setSubmitting(true);
          const userData = JSON.parse(localStorage.getItem('UserDetailData'));
          const jwtToken = userData?.jwt_token;
          const userId = userData?.user_id;
  
          if (!jwtToken || !userId) {
            setSnackbar({
              open: true,
              message: 'User authentication details are missing. Please log in again.',
              severity: 'error',
            });
            setSubmitting(false);
            return;
          }
  
          const payload = [{
            growerName: formValues.growerName,
            passBook: formValues.passBook,
            cnic: formValues.cnic,
            comment: comment || null,
            competitors: competitorEntries.map((entry) => ({
              id: entry.id,
              trolly: entry.trolly === "" || entry.trolly === "-" ? null : entry.trolly,
              farsha: entry.farsha === "" || entry.farsha === "-" ? null : entry.farsha,
            })),
          }];
  
          fetch(`${API_BASE_URL}C01ProgramOfficerSpecialRequest`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${jwtToken}`,
            },
            body: JSON.stringify(payload),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error('Failed to send data to API');
              }
              return response.json();
            })
            .then((data) => {
              const apiMessage = data.DATA?.message || 'Data sent successfully';
              // Show success message in SweetAlert
              swal('Success', apiMessage, 'success');
              setFormValues({
                growerName: '',
                passBook: '',
                cnic: '',
              });
              setComment('');
              setCompetitorEntries([]);
            })
            .catch((error) => {
              setSnackbar({
                open: true,
                message: error.message,
                severity: 'error',
              });
            })
            .finally(() => {
              setSubmitting(false);
            });
        }
      });
    } else {
      setSnackbar({
        open: true,
        message: 'Please fix the errors in the form',
        severity: 'error',
      });
    }
  };
  
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>
      <Main open={open}>
        <TopBar toggleSidebar={handleDrawerOpen} />
        <Box sx={{ padding: '20px' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '10px' }}>
            <Link color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="textPrimary">Special Requests</Typography>
          </Breadcrumbs>
          <br />
          <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Grower Name"
                fullWidth
                name="growerName"
                value={formValues.growerName}
                onChange={handleInputChange}
                error={!!formErrors.growerName}
                helperText={formErrors.growerName}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Passbook No"
                fullWidth
                name="passBook"
                value={formValues.passBook}
                onChange={handleInputChange}
                error={!!formErrors.passBook}
                helperText={formErrors.passBook}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="CNIC No"
                fullWidth
                type="number" // Changed from 'number' to 'text'
                name="cnic"
                value={formValues.cnic}
                onChange={handleInputChange}
                error={!!formErrors.cnic}
                helperText={
                  formErrors.cnic
                    ? formErrors.cnic
                    : `${formValues.cnic.length}/13 characters`
                }
                inputProps={{
                  maxLength: 13, // Limit input to 13 characters
                  inputMode: 'numeric', // Show numeric keyboard on mobile
                  pattern: '[0-9]*', // Allow only digits
                }}
                placeholder="Enter 13-digit CNIC without dashes"
                required
              />
            </Grid>
          </Grid>

          <Box sx={{ marginBottom: '20px' }}>
            <TextField
              label="Comments"
              fullWidth
              multiline
              minRows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter your comments"
              error={!!formErrors.comment}
              helperText={formErrors.comment}
              required
            />
          </Box>

          <Box sx={{ marginBottom: '20px' }}>
            <Grid container spacing={2} alignItems="flex-end">
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel id="competitor-label">Select Competitor</InputLabel>
                  <Select
                    labelId="competitor-label"
                    value={selectedCompetitor}
                    label="Select Competitor"
                    onChange={(e) => setSelectedCompetitor(e.target.value)}
                    endAdornment={
                      selectedCompetitor && (
                        <IconButton
                          onClick={() => setSelectedCompetitor('')}
                          edge="end"
                          sx={{ marginRight: '8px' }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )
                    }
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    {loadingCompetitors ? (
                      <MenuItem value="">
                        <em>Loading...</em>
                      </MenuItem>
                    ) : apiError ? (
                      <MenuItem value="">
                        <em>Error loading competitors</em>
                      </MenuItem>
                    ) : (
                      competitors.map((comp) => (
                        <MenuItem key={comp.id} value={comp.id}>
                          {comp.label}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  {formErrors.competitor && (
                    <Typography color="error" variant="caption">
                      {formErrors.competitor}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Trolly"
                  fullWidth
                  value={trolly}
                  onChange={(e) => setTrolly(e.target.value)}
                  type="number"
                  inputProps={{ min: 0.01, step: 0.01 }}
                  error={!!formErrors.trolly}
                  helperText={formErrors.trolly}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <TextField
                  label="Farsha"
                  fullWidth
                  value={farsha}
                  onChange={(e) => setFarsha(e.target.value)}
                  type="number"
                  inputProps={{ min: 0.01, step: 0.01 }}
                  error={!!formErrors.farsha}
                  helperText={formErrors.farsha}
                />
              </Grid>

              <Grid item xs={12} sm={2} style={{ marginBottom: '10px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleAddCompetitor}
                  sx={{ background: 'linear-gradient(to right, #56ab2f, #a8e063)' }}
                >
                  {editingCompetitorId ? 'Update' : 'Add'}
                </Button>
              </Grid>

            </Grid>
          </Box>

          {competitorEntries.length > 0 && (
            <TableContainer component={Paper} sx={{ marginBottom: '20px' }}>
              <Table aria-label="competitors table">
                <TableHead>
                  <TableRow >
                    <TableCell sx={{ textAlign: 'center'}}>Competitor Name</TableCell>
                    <TableCell sx={{ textAlign: 'center'}}>Trolly</TableCell>
                    <TableCell sx={{ textAlign: 'center'}}>Farsha</TableCell>
                    <TableCell align="center" >Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {competitorEntries.map((row) => (

                    <TableRow key={row.id}>
                      <TableCell sx={{ textAlign: 'center'}}>{row.name}</TableCell>
                      <TableCell sx={{ textAlign: 'center'}}>{row.trolly}</TableCell>
                      <TableCell sx={{ textAlign: 'center'}}>{row.farsha}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="edit"
                          color="primary"
                          onClick={() => handleEditCompetitor(row.id)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleRemoveCompetitor(row.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Button
            variant="contained"
            sx={{
              background: 'linear-gradient(to right, #56ab2f, #a8e063)',
              color: 'white',
              marginBottom: '20px',
            }}
            onClick={handleSendToCaneHead}
            disabled={submitting}
          >
            {submitting ? <CircularProgress size={24} color="inherit" /> : 'Send to Cane Head'}
          </Button>
        </Box>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Main>
    </Box>
  );
};

export default SpecialRequestTable;
