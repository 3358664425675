import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Typography, Drawer, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import TopBar from './TopBar';
import SideBar from './SideBar';
import AuditTable from './AuditTable';
import { styled } from '@mui/material/styles';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const Audit = () => {
  const [open, setOpen] = useState(true);
  const [notificationPermissionGranted, setNotificationPermissionGranted] = useState(false); // Notification permission state
  const [openNotificationModal, setOpenNotificationModal] = useState(false); // Modal state for notification permission

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  // Function to check notification permission
  useEffect(() => {
    const checkNotificationPermission = async () => {
      const permission = Notification.permission;
      if (permission === 'granted') {
        setNotificationPermissionGranted(true);
      } else {
        setOpenNotificationModal(true); // Show modal if permission is denied
      }
    };

    checkNotificationPermission(); // Check notification permission on page load
  }, []);

  // Handle closing of the notification modal
  const handleCloseNotificationModal = () => {
    setOpenNotificationModal(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>
      <Main open={open}>
        <TopBar toggleSidebar={handleDrawerOpen} />
        <Box sx={{ padding: '20px' }}>
          {/* Breadcrumbs */}
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '6px' }}>
            <Typography color="textPrimary">Requests</Typography>
          </Breadcrumbs>
          <br />
          <AuditTable />
        </Box>
      </Main>

      {/* Modal for Notification Permission */}
      <Dialog
        open={openNotificationModal}
        onClose={handleCloseNotificationModal}
        aria-labelledby="notification-permission-dialog"
      >
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Notification Permission Denied
          </Typography>
          <Typography variant="body2">
            Please enable notifications in your browser settings:
            <ol>
              <li>Click on the lock icon near the address bar.</li>
              <li>Go to 'Site settings' or 'Notifications'.</li>
              <li>Find and enable notifications for this site.</li>
            </ol>
          </Typography>
        </DialogContent>
        
        <DialogActions>
          <Button 
            onClick={handleCloseNotificationModal} 
            sx={{
              background: 'linear-gradient(to right, #a8e063, #56ab2f)',
              color: 'white',
              '&:hover': {
                background: 'linear-gradient(to right, #56ab2f, #a8e063)',
              },
            }}
            >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Audit;
