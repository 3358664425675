

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Chip,
  Tooltip,
  Pagination,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChatIcon from '@mui/icons-material/Chat';
import loaderImage from '../assects/Images/Logos/CRVlogo.png'; // Ensure this path is correct
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config'; // Ensure this is correctly set
import { format } from 'date-fns';

const AuditSpecialTable = () => {
  const [requests, setRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [requestStatuses, setRequestStatuses] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [parentComments, setParentComments] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [expandedRequests, setExpandedRequests] = useState({});
  const [loading, setLoading] = useState(true);
  const [submittingRequests, setSubmittingRequests] = useState({});
  const [requestDetails, setRequestDetails] = useState({});
  const navigate = useNavigate();

  // Fetch requests from API
  const fetchRequests = async () => {
    const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
    const jwtToken = userDetailData?.jwt_token;

    if (!jwtToken) {
      console.error('No JWT token found');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${API_BASE_URL}C01SpecialRequestsList`,
        { request_type: 1 },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request) {
        setRequests(response.data.DATA.request);
        setTotalCount(response.data.DATA.total_count);

        const initialStatuses = {};
        response.data.DATA.request.forEach((req) => {
          initialStatuses[req.request_id] = req.request_status || 'To be reviewed';
        });

        setRequestStatuses(initialStatuses);
      }
    } catch (error) {
      console.error('Failed to fetch requests:', error);
      setSnackbarMessage('Failed to fetch requests. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  // Fetch request details based on request ID
  const fetchRequestDetails = async (request_id) => {
    const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
    const jwtToken = userDetailData?.jwt_token;

    try {
      const response = await axios.post(
        `${API_BASE_URL}C01SpecialRequestDetail`,
        { request_id },
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA) {
        setRequestDetails((prevDetails) => ({
          ...prevDetails,
          [request_id]: response.data.DATA, // Store details by request ID
        }));
      }
    } catch (error) {
      console.error('Failed to fetch request details:', error);
    }
  };

  // Handle expand/collapse
  const handleExpandClick = (request_id) => {
    setExpandedRequests((prev) => ({
      ...prev,
      [request_id]: !prev[request_id],
    }));

    // Fetch request details when expanding
    if (!expandedRequests[request_id]) {
      fetchRequestDetails(request_id); // Only fetch if expanding
    }
  };

  // Handle live search functionality
  const filteredRequests = requests.filter((request) => {
    const search = searchQuery.toLowerCase();
    const siteLabel = request.site_label ? request.site_label.toLowerCase() : '';
    return (
      request.request_id?.toString().includes(search) ||
      request.submitted_by_name.toLowerCase().includes(search) ||
      request.request_title.toLowerCase().includes(search) ||
      siteLabel.includes(search)
    );
  });

  // Group requests by site label
  const groupedRequests = {};
  filteredRequests.forEach((request) => {
    const siteLabel = request.site_label || 'Unknown Site';
    if (!groupedRequests[siteLabel]) {
      groupedRequests[siteLabel] = [];
    }
    groupedRequests[siteLabel].push(request);
  });

  // Pagination logic
  const totalPages = Math.ceil(filteredRequests.length / itemsPerPage);
  const currentRequests = filteredRequests.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to first page
  };

  // Handle parent comment change
  const handleParentCommentChange = (e, request_id) => {
    const { value } = e.target;
    setParentComments((prev) => ({
      ...prev,
      [request_id]: value,
    }));
  };

  // Handle submitting audit comments
  const handleSubmitRequest = async (request_id) => {
    const auditComment = parentComments[request_id]?.trim();

    // Validation: Ensure comment is provided
    if (!auditComment) {
      setSnackbarMessage('Please enter a comment before submitting.');
      setSnackbarSeverity('error'); // Set severity to error for validation
      setOpenSnackbar(true);
      return;
    }

    setSubmittingRequests((prev) => ({ ...prev, [request_id]: true }));

    const payload = {
      action_id: 3, // Assuming action_id 3 corresponds to 'Send to Manager'
      special_request_id: request_id,
      audit_comment: auditComment,
    };

    try {
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        throw new Error('JWT token is missing.');
      }

      await axios.post(
        `${API_BASE_URL}C01SpecialRequestAction`,
        payload,
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      // Update status to 'Submitted for Manager Review'
      setRequestStatuses((prevStatuses) => ({
        ...prevStatuses,
        [request_id]: 'Submitted for Manager Review',
      }));

      setSnackbarMessage('Request submitted successfully.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);

      // Optionally, refresh the request list to get the latest statuses
      fetchRequests();
    } catch (error) {
      console.error('Failed to submit request:', error);
      if (error.response && error.response.status === 404) {
        setSnackbarMessage('Endpoint not found (404). Please check the API URL.');
      } else if (error.message === 'JWT token is missing.') {
        setSnackbarMessage('Authentication token is missing. Please log in again.');
      } else {
        setSnackbarMessage('Failed to submit the request. Please try again.');
      }
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setSubmittingRequests((prev) => ({ ...prev, [request_id]: false }));
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const formatDateTime = (dateTime) => {
    return dateTime ? format(new Date(dateTime), 'dd MMM yy HH:mm') : 'N/A';
  };

  const handleChatClick = (request_id, isSpecial) => {
    const modifiedTrackingId = isSpecial ? `${request_id}S` : request_id;
    navigate(`/auditchat?trackingId=${modifiedTrackingId}`);
  };
  

  return (
    <div>
      {/* Search Field and Items Per Page Container */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
          flexWrap: 'wrap',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <TextField
            label="Search Requests"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search Requests"
            sx={{ width: 300 }}
          />
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            sx={{ minWidth: 70, height: 40 }}
          >
            {[25, 50, 100, 250, 500].map((count) => (
              <MenuItem key={count} value={count}>
                {count}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {/* Loader Image Overlay */}
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed', // Fixed to overlay entire screen
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Semi-transparent background
            zIndex: 9999,
          }}
        >
          <img
            src={loaderImage}
            alt="Loading..."
            style={{
              width: '100px',
              height: '100px',
              borderRadius: '50%',
              animation: 'spin 2s linear infinite',
            }}
          />
          <style>
            {`
              @keyframes spin {
                0% { transform: rotate(0deg); }
                100% { transform: rotate(360deg); }
              }
            `}
          </style>
        </Box>
      )}

      {/* Requests Table */}
      {!loading && (
        <TableContainer
          component={Paper}
          sx={{
            maxHeight: '700px',
            overflowY: 'auto',
            '::-webkit-scrollbar': { display: 'none' },
            scrollbarWidth: 'none',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Site Label
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Request ID
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Submitted By
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Request Date
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Status
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRequests.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No pending requests found.
                  </TableCell>
                </TableRow>
              ) : (
                Object.entries(groupedRequests).map(([siteLabel, requests]) => (
                  <React.Fragment key={siteLabel}>
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1.2em',
                          textAlign: 'center',
                          backgroundColor: '#f0f0f0',
                        }}
                      >
                        {siteLabel}
                      </TableCell>
                    </TableRow>
                    {requests.map((request) => (
                      <React.Fragment key={request.request_id}>
                        <TableRow>
                          <TableCell align="center">{siteLabel}</TableCell>
                          <TableCell align="center">{request.request_id}</TableCell>
                          <TableCell align="center">{request.submitted_by_name}</TableCell>
                          <TableCell align="center">
                            {formatDateTime(request.request_date_time)}
                          </TableCell>
                          <TableCell align="center">
                            <Chip
                              label={requestStatuses[request.request_id]}
                              color={
                                requestStatuses[request.request_id]?.toLowerCase() === 'submitted for manager review'
                                  ? 'success'
                                  : requestStatuses[request.request_id]?.toLowerCase() === 'to be reviewed'
                                  ? 'warning'
                                  : 'default'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Tooltip
                              title={expandedRequests[request.request_id] ? 'Collapse' : 'Expand'}
                            >
                              <IconButton
                                onClick={() => handleExpandClick(request.request_id)}
                                size="small"
                                sx={{ padding: '4px' }}
                              >
                                {expandedRequests[request.request_id] ? (
                                  <ExpandLessIcon fontSize="small" />
                                ) : (
                                  <ExpandMoreIcon fontSize="small" />
                                )}
                              </IconButton>
                            </Tooltip>
                            {/* Chat Button */}
                            <Tooltip title="Chat">
                              <IconButton
                                onClick={() => handleChatClick(request.request_id)}
                                size="small"
                                sx={{ padding: '4px', marginLeft: 1 }}
                              >
                                <ChatIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>

                        {/* Expanded Row */}
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Collapse in={expandedRequests[request.request_id]}>
                              <Box sx={{ padding: 2 }}>
                                {/* Display Grower Details */}
                                <TextField
                                  label="Grower Name"
                                  value={
                                    requestDetails[request.request_id]?.request_detail?.[0]
                                      ?.grower_name || ''
                                  }
                                  multiline
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{ backgroundColor: '#f5f5f5' }}
                                />

                                <TextField
                                  label="Passbook"
                                  value={
                                    requestDetails[request.request_id]?.request_detail?.[0]?.pass_book ||
                                    ''
                                  }
                                  multiline
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{ backgroundColor: '#f5f5f5' }}
                                />

                                <TextField
                                  label="CNIC"
                                  value={
                                    requestDetails[request.request_id]?.request_detail?.[0]?.cnic || ''
                                  }
                                  multiline
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{ backgroundColor: '#f5f5f5' }}
                                />

                                {/* Display Site Cane Head Comment */}
                                <TextField
                                  label="Site Cane Head Comment"
                                  value={
                                    requestDetails[request.request_id]?.request_detail?.[0]?.comment ||
                                    ''
                                  }
                                  multiline
                                  fullWidth
                                  margin="normal"
                                  variant="outlined"
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                  sx={{ backgroundColor: '#f5f5f5' }}
                                />

                                {/* Display Auditor's Comment only when status is 'Audited' */}
                                {requestStatuses[request.request_id]?.toLowerCase() ===
                                  'audited' && (
                                  <TextField
                                    label="Audit Comment"
                                    value={
                                      requestDetails[request.request_id]?.request_detail?.[0]
                                        ?.audit_comments || ''
                                    }
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    inputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{ backgroundColor: '#f5f5f5' }}
                                  />
                                )}

                                {/* Comment Submission Section */}
                                <Box sx={{ marginTop: 2 }}>
                                  <TextField
                                    label="Add Audit Comment"
                                    value={parentComments[request.request_id] || ''}
                                    onChange={(e) => handleParentCommentChange(e, request.request_id)}
                                    placeholder="Enter your comment for this request"
                                    multiline
                                    fullWidth
                                    margin="normal"
                                    required
                                    disabled={
                                      requestStatuses[request.request_id].toLowerCase() ===
                                      'submitted for manager review'
                                    }
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleSubmitRequest(request.request_id)}
                                    disabled={
                                      requestStatuses[request.request_id].toLowerCase() ===
                                        'submitted for manager review' ||
                                      submittingRequests[request.request_id]
                                    }
                                    sx={{
                                      background: 'linear-gradient(to right, #00c6ff, #0072ff)',
                                      color: 'white',
                                      marginTop: 1,
                                    }}
                                  >
                                    {submittingRequests[request.request_id]
                                      ? 'Submitting...'
                                      : 'Send to Manager'}
                                  </Button>
                                </Box>

                                {/* Competitor Data Display */}

                                {/* Competitor Data Display with Heading */}
                                <Typography variant="h6" sx={{ marginTop: 2 }}>
                                  Competitors
                                </Typography>

                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 2,
                                    overflowX: 'auto',
                                    whiteSpace: 'nowrap',
                                    marginTop: 2,
                                  }}
                                >
                                  {requestDetails[request.request_id]?.request_detail?.[0]
                                    ?.Competitors?.length > 0 ? (
                                    requestDetails[request.request_id]?.request_detail?.[0]
                                      ?.Competitors.map((competitor) => (
                                        <Box
                                          key={competitor.competitor_id}
                                          sx={{
                                            border: '1px solid #ccc',
                                            minWidth: '250px',
                                            borderRadius: '12px',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              background: '#bdbdbd',
                                              color: 'white',
                                              padding: '10px',
                                              textAlign: 'center',
                                            }}
                                          >
                                            <strong
                                              style={{
                                                fontSize: 'large',
                                                fontFamily: 'sans-serif',
                                              }}
                                            >
                                              {competitor.competitor_label}
                                            </strong>
                                          </Box>
                                          <Box sx={{ padding: 2, textAlign: 'center' }}>
                                            <div>
                                              <strong style={{ color: 'black' }}>
                                                Requested Rates:
                                              </strong>
                                              <div style={{ color: 'gray' }}>
                                                Trolly: {competitor.present_trolly_rate} | Farsha:{' '}
                                                {competitor.present_farsha_rate}
                                              </div>
                                            </div>
                                          </Box>
                                        </Box>
                                      ))
                                  ) : (
                                    <Typography>No competitors data available.</Typography>
                                  )}
                                </Box>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Pagination Controls */}
      {!loading && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          sx={{ marginTop: 2, display: 'flex', justifyContent: 'center' }}
        />
      )}

      {/* Success/Error Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AuditSpecialTable;
