


// import React from 'react';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import LoginForm from './Components/LoginPage';
// import Dashboard from './Components/Dashboard';
// import Requests from './Components/Requests';
// import SpecialRequestTable from './Components/SpecialRequestTable';
// import Audit from './Components/Audit';
// import PrivateRoute from './Components/PrivateRoute';
// import PendingRequests from './Components/PendingRequests';
// import AuditActivity from './Components/AuditActivity';
// import { messaging } from './Notifications/firebase';
// import { getToken, onMessage } from 'firebase/messaging';
// import AuditSpecial from './Components/AuditSpecial'
// import RegistrationFrom from './Components/RegistrationForm'
// import UserReport from './Components/UserReport';
// import AuditChat from './Components/AuditChat';
// import Management from './Components/management';
// import ManagementChat from './Components/ManagementChat'
// import ManangerSpecialReq from './Components/ManagerSpecialReq';
// import ManagerApproved from './Components/ManagerApproved';
// import ManagerDecline from "./Components/ManagerDecline";
// import ManagerSpecialApproved from './Components/ManagerSpecialApproved'
// import ManagerSpecialDeclined from './Components/ManagerSpecialDeclined'
// import SpecialChat from './Components/SpecialChat';


// function App() {
//   // useEffect(() => {
//   //   Notification.requestPermission().then((permission) => {
//   //     if (permission === 'granted' && messaging) {
//   //       console.log('Notification permission granted.');

//   //       getToken(messaging, {
//   //         vapidKey: 'BMbK-KD6krobPAPA53iGQmjwYX4BegBFwTYplEumU0UNgT3ofO4oMvVbziI4RYYZf27tiexyIUVQOXxYQyQ1TW0',
//   //       })
//   //         .then((currentToken) => {
//   //           if (currentToken) {
//   //             console.log('fcm_token:', currentToken);
//   //             localStorage.setItem('fcm_token', currentToken);
//   //           } else {
//   //             console.log('No registration token available.');
//   //           }
//   //         })
//   //         .catch((err) => {
//   //           console.error('Error retrieving token:', err);
//   //         });

//   //       onMessage(messaging, (payload) => {
//   //         console.log('Message received:', payload);
//   //         const { title, body } = payload.notification;
//   //         new Notification(title, { body });
//   //       });
//   //     } else {
//   //       console.log('Notification permission denied.');
//   //     }
//   //   });
//   // }, []);

//   return (
//     <Router>
//       <Routes>

// <Route path='/caneApp'>
//         <Route path="/" element={<LoginForm />} />


//         <Route element={<PrivateRoute allowedRoles={[1]} />}>
//           <Route path="/caneApp/dashboard" element={<Dashboard />} />
//           <Route path="/caneApp/pendingrequests" element={<PendingRequests />} />
//           <Route path="/caneApp/requests" element={<Requests />} />
//           <Route path="/caneApp/specialrequests" element={<SpecialRequestTable />} />
//         </Route>

//         <Route element={<PrivateRoute allowedRoles={[3]} />}>
//          <Route path="/caneApp/management" element={<Management/>} />
//           <Route path='/caneApp/managementspecailreq' element={<ManangerSpecialReq/>} />
//           <Route path='/caneApp/managementapproved' element={<ManagerApproved/>} />
//           <Route path='/caneApp/managementdecline' element={<ManagerDecline/>} />
//           <Route path='/caneApp/managementspecialapproved' element={<ManagerSpecialApproved/>} />
//           <Route path='/caneApp/managementspecialdeclined' element={<ManagerSpecialDeclined/>} />
//         </Route>


//         <Route element={<PrivateRoute allowedRoles={[4]} />}>
//           {/* <Route path="/auditdashboard" element={<AuditDashboard />} /> */}
//           <Route path="/caneApp/audit" element={<Audit />} />
//           <Route path="/caneApp/auditactivity" element={<AuditActivity />} />
//           <Route path="/caneApp/auditspecial" element={<AuditSpecial/>} />
//           {/* <Route path='/auditchat' element={<AuditChat/>} /> */}
//         </Route>


//         <Route element={<PrivateRoute allowedRoles={[9]} />}>
//         <Route path="/caneApp/registrationform" element={<RegistrationFrom/>} />
//         <Route path="/caneApp/userreport" element={<UserReport/>} />
//         </Route>
        
      
//         <Route path="/caneApp/unauthorized" element={<h3>You don't have authorization to view this page. Please contact your administrator if you believe this is an error.</h3>} />
//         </Route>
//       </Routes>

//     </Router>
//   );
// }

// export default App;

// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LoginForm from './Components/LoginPage';
import Dashboard from './Components/Dashboard';
import Requests from './Components/Requests';
import SpecialRequestTable from './Components/SpecialRequestTable';
import Audit from './Components/Audit';
import PrivateRoute from './Components/PrivateRoute';
import PendingRequests from './Components/PendingRequests';
import AuditActivity from './Components/AuditActivity';
import AuditSpecial from './Components/AuditSpecial';
import RegistrationFrom from './Components/RegistrationForm';
import UserReport from './Components/UserReport';
import AuditChat from './Components/AuditChat';
import Management from './Components/management'
import ManagementChat from './Components/ManagementChat';
import ManangerSpecialReq from './Components/ManagerSpecialReq';
import ManagerApproved from './Components/ManagerApproved';
import ManagerDecline from "./Components/ManagerDecline";
import ManagerSpecialApproved from './Components/ManagerSpecialApproved';
import ManagerSpecialDeclined from './Components/ManagerSpecialDeclined';
import SpecialChat from './Components/SpecialChat';

function App() {
  return (
    <Router>
      <Routes>
        {/* Redirect root path to /caneApp */}
        <Route path="/" element={<Navigate to="/caneApp" replace />} />

        {/* Login Route */}
        <Route path="/caneApp" element={<LoginForm />} />

        {/* Protected Routes for Role 1 */}
        <Route element={<PrivateRoute allowedRoles={[1]} />}>
          <Route path="/caneApp/dashboard" element={<Dashboard />} />
          <Route path="/caneApp/pendingrequests" element={<PendingRequests />} />
          <Route path="/caneApp/requests" element={<Requests />} />
          <Route path="/caneApp/specialrequests" element={<SpecialRequestTable />} />
        </Route>

        {/* Protected Routes for Role 3 */}
        <Route element={<PrivateRoute allowedRoles={[3]} />}>
          <Route path="/caneApp/management" element={<Management />} />
          <Route path="/caneApp/managementspecialreq" element={<ManangerSpecialReq />} />
          <Route path="/caneApp/managementapproved" element={<ManagerApproved />} />
          <Route path="/caneApp/managementdecline" element={<ManagerDecline />} />
          <Route path="/caneApp/managementspecialapproved" element={<ManagerSpecialApproved />} />
          <Route path="/caneApp/managementspecialdeclined" element={<ManagerSpecialDeclined />} />
        </Route>

        {/* Protected Routes for Role 4 */}
        <Route element={<PrivateRoute allowedRoles={[4]} />}>
          <Route path="/caneApp/audit" element={<Audit />} />
          <Route path="/caneApp/auditactivity" element={<AuditActivity />} />
          <Route path="/caneApp/auditspecial" element={<AuditSpecial />} />
          {/* Uncomment if needed */}
          {/* <Route path="/caneApp/auditchat" element={<AuditChat />} /> */}
        </Route>

        {/* Protected Routes for Role 9 */}
        <Route element={<PrivateRoute allowedRoles={[9]} />}>
          <Route path="/caneApp/registrationform" element={<RegistrationFrom />} />
          <Route path="/caneApp/userreport" element={<UserReport />} />
        </Route>

        {/* Unauthorized Access Route */}
        <Route path="/caneApp/unauthorized" element={
          <div style={{ padding: '20px', textAlign: 'center' }}>
            <h3>You don't have authorization to view this page. Please contact your administrator if you believe this is an error.</h3>
          </div>
        } />

        {/* Catch-All Route for 404 - Not Found */}
        <Route path="*" element={<Navigate to="/caneApp/unauthorized" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
