// // ScrollableTable.js
// import React from 'react';
// import { DataGrid } from '@mui/x-data-grid';

// const ScrollableTable = React.memo(({ rows, columns, columnGroupingModel, onRowUpdate }) => {
//   // console.log('ScrollableTable Rendered'); // For debugging re-renders
//   return (
//     <DataGrid
//       disableColumnSorting
//       getRowId={(row) => row.id}
//       rows={rows}
//       columns={columns}
//       columnGroupingModel={columnGroupingModel}
//       disableColumnMenu={true}
//       hideFooter
//       autoHeight
//       editMode="cell"
//       processRowUpdate={onRowUpdate}
//       experimentalFeatures={{ columnGrouping: true }}
//       sx={{
//         '& .MuiDataGrid-cell--editable': {
//           border: '1px solid #D9D9D9',
//           textAlign: 'center',
//         },
//         '& .MuiDataGrid-cell': {
//           '&:focus': {
//             outline: 'none',
//             boxShadow: '0 0 5px 2px rgba(0,123,255,0.5)',
//           },
//           textAlign: 'center',
//           width: '100px',
//         },
//         '& .MuiDataGrid-columnHeaders': {
//           position: 'sticky',
//           top: 0,
//           backgroundColor: '#f4f4f4',
//           zIndex: 1,
//         },
//         '& .MuiDataGrid-columnHeader': {
//           textAlign: 'center',
//           width: '100px',
//         },
//       }}
//     />
//   );
// });

// export default ScrollableTable;




// ScrollableTable.js
import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import swal from 'sweetalert';

const ScrollableTable = React.memo(({ rows, columns, columnGroupingModel, onRowUpdate }) => {
  return (
    <DataGrid
      disableColumnSorting
      getRowId={(row) => row.id}
      rows={rows}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      disableColumnMenu={true}
      hideFooter
      autoHeight
      editMode="cell"
      processRowUpdate={onRowUpdate}
      onProcessRowUpdateError={(error) => {
        console.error('Row update error:', error);
        swal('Error', 'Failed to update the row.', 'error');
      }}
      experimentalFeatures={{ columnGrouping: true }}
      sx={{
        '& .MuiDataGrid-cell--editable': {
          border: '1px solid #D9D9D9',
          textAlign: 'center',
        },
        '& .MuiDataGrid-cell': {
          '&:focus': {
            outline: 'none',
            boxShadow: '0 0 5px 2px rgba(0,123,255,0.5)',
          },
          textAlign: 'center',
          width: '100px',
        },
        '& .MuiDataGrid-columnHeaders': {
          position: 'sticky',
          top: 0,
          backgroundColor: '#f4f4f4',
          zIndex: 1,
        },
        '& .MuiDataGrid-columnHeader': {
          textAlign: 'center',
          width: '100px',
        },
      }}
    />
  );
});

export default ScrollableTable;
