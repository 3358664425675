import React from 'react';
import { Box, Breadcrumbs, Typography, Link } from '@mui/material';
import TopBar from './TopBar';
import SideBar from './SideBar';
import RequestsTable from './PendingRequestsTable';
import { styled } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const PendingRequests = () => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>
      <Main open={open}>
        <TopBar toggleSidebar={handleDrawerOpen} />
        <Box sx={{ padding: '20px' }}>
          {/* Breadcrumbs */}
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '10px' }}>
             <Link color="inherit" href="/dashboard">
              Home
             </Link>
            <Typography color="textPrimary">Requests</Typography>
          </Breadcrumbs>

          <br />
          {/* Requests Table */}
          <RequestsTable />
        </Box>
      </Main>
    </Box>
  );
};

export default PendingRequests;



