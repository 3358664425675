import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import '../Styles/TopBar.css';
import defaultProfilePic from '../assects/Images/Logos/topbar-pic.avif';
import swal from 'sweetalert';
import API_BASE_URL from '../config';

const TopBar = ({ toggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const retrievedObject = JSON.parse(localStorage.getItem('UserDetailData')) || {};

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    swal({
      title: 'Are you sure?',
      text: 'You will be logged out from your account.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willLogout) => {
      if (willLogout) {

        fetch(`${API_BASE_URL}C01CaneAppUserLogout`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            user_id: retrievedObject?.user_id,
            device_id: '123456',

          }),
        })
          .then((response) => {
            if (response.ok) {

              console.log('Logged out');
              localStorage.removeItem('UserDetailData');
              // localStorage.clear();
              navigate('/');
            } else {

              console.log('Logout failed');
              swal('Error', 'Failed to logout. Please try again.', 'error');
            }
          })
          .catch((error) => {
            console.log('Logout error:', error);
            swal('Error', 'Failed to logout. Please try again.', 'error');
          });
      } else {
        console.log('Logout cancelled');
      }
    });
  };

  return (
    <AppBar
      position="static"
      sx={{
        background: 'linear-gradient(to right, #a8e063, #56ab2f)',
      }}
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>

        </Typography>
        <Box>
          <IconButton onClick={handleMenuOpen}>
            <Avatar
              alt="Profile Picture"
              src={retrievedObject?.profilePicture || defaultProfilePic}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              sx: {
                padding: 2,
                borderRadius: 2,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
              },
            }}
          >
            {retrievedObject?.display_name && (
              <Box sx={{ textAlign: 'center', mb: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {retrievedObject.display_name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  {retrievedObject.designation}
                </Typography>
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  {retrievedObject.site_label}
                </Typography>
              </Box>
            )}
            <Divider sx={{ my: 1 }} />
            <MenuItem
              onClick={handleLogout}
              sx={{
                color: 'red',
                '&:hover': { backgroundColor: '#ffe6e6' },
                display: 'block',
                textAlign: 'center',
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
