


import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Tooltip,
  TextField,
  IconButton,
  Snackbar,
  Alert,
  MenuItem,
  Pagination,
  DialogTitle,
} from '@mui/material';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ChatIcon from '@mui/icons-material/Chat';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import loaderImage from '../assects/Images/Logos/CRVlogo.png'; // Ensure this path is correct
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../config'; // Ensure this is correctly set
import { format } from 'date-fns';

// Styled Main component with theming
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${240}px`, // Removed dynamic 'drawerWidth' as TopBar and SideBar are removed
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AuditTable = () => {
  // Define a consistent theme
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'light',
          primary: {
            main: '#81c784', // Light Green
          },
          secondary: {
            main: '#64b5f6', // Blue
          },
          error: {
            main: '#f44336', // Red for errors
          },
          warning: {
            main: '#ff9800', // Orange for warnings
          },
          success: {
            main: '#4caf50', // Green for success
          },
        },
      }),
    []
  );

  // State Variables
  const [trackingIds, setTrackingIds] = useState([]);
  const [requestStatuses, setRequestStatuses] = useState({});
  const [auditComments, setAuditComments] = useState({});
  const [searchQuery, setSearchQuery] = useState(''); // Added searchQuery state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [expandedRequests, setExpandedRequests] = useState({});
  const [requestDetails, setRequestDetails] = useState({});
  const [competitorsData, setCompetitorsData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submittingRequests, setSubmittingRequests] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate();

  // Fetch audit requests from API
  useEffect(() => {
    const fetchRequests = async () => {
      try {
        setLoading(true);
        const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
        const jwtToken = userDetailData?.jwt_token;

        if (!jwtToken) {
          console.error('No JWT token found');
          setSnackbarMessage('Authentication token missing. Please log in again.');
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
          return;
        }

        const response = await axios.post(
          `${API_BASE_URL}C01RequestsList`,
          { request_type: 1 }, // Assuming request_type:1 corresponds to audit requests
          { headers: { Authorization: `Bearer ${jwtToken}` } }
        );

        if (response.data.DATA && response.data.DATA.request_list) {
          setTrackingIds(response.data.DATA.request_list);
          setTotalCount(response.data.DATA.total_count || response.data.DATA.request_list.length);

          const initialStatuses = {};
          response.data.DATA.request_list.forEach((req) => {
            initialStatuses[req.tracking_id] = req.request_status || 'To be reviewed';
          });

          setRequestStatuses(initialStatuses);
        }
      } catch (error) {
        console.error('Failed to fetch requests:', error);
        setSnackbarMessage('Failed to fetch requests. Please try again later.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchRequests();
  }, []);

  // Fetch request details based on tracking ID
  const fetchRequestDetails = async (tracking_id) => {
    try {
      setLoading(true);
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        console.error('No JWT token found');
        setSnackbarMessage('Authentication token missing. Please log in again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      const response = await axios.post(
        `${API_BASE_URL}C01TrackIdRequestDetail`,
        { request_type: 1, track_id: tracking_id }, // Use request_type:1 for audit request details
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      if (response.data.DATA && response.data.DATA.request_list) {
        const requestList = response.data.DATA.request_list;

        setRequestDetails((prev) => ({
          ...prev,
          [tracking_id]: {
            request_list: requestList,
            site_cane_head_comments: response.data.DATA.site_cane_head_comments,
            audit_comments: response.data.DATA.audit_comments,
          },
        }));
      } else {
        console.warn(`No request_list found for tracking_id: ${tracking_id}`);
        setRequestDetails((prev) => ({
          ...prev,
          [tracking_id]: {
            request_list: [],
            site_cane_head_comments: response.data.DATA.site_cane_head_comments || 'No comment available',
            audit_comments: response.data.DATA.audit_comments || '',
          },
        }));
      }
    } catch (error) {
      console.error('Failed to fetch request details:', error);
      setSnackbarMessage('Failed to fetch request details. Please try again later.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  // Handle expand/collapse and fetch request details
  const handleExpandClick = (tracking_id) => {
    setExpandedRequests((prev) => ({
      ...prev,
      [tracking_id]: !prev[tracking_id],
    }));
    if (!expandedRequests[tracking_id]) {
      fetchRequestDetails(tracking_id);
    }
  };

  // Handle expand/collapse for individual requests
  const handleExpandRequest = (tracking_id, requestIndex) => {
    const key = `${tracking_id}-${requestIndex}`;
    setExpandedRequests((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  // Handle audit comment change
  const handleAuditCommentChange = (e, tracking_id) => {
    const { value } = e.target;
    setAuditComments((prev) => ({
      ...prev,
      [tracking_id]: value,
    }));
  };

  // Handle submit request
  const handleSubmitRequest = async (tracking_id) => {
    const auditComment = auditComments[tracking_id]?.trim();

    if (!auditComment) {
      setSnackbarMessage('Please enter a comment before submitting.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    const requestDetail = requestDetails[tracking_id];
    const siteCaneHeadComment = requestDetail?.site_cane_head_comments || 'No comment available';
    const requestList = requestDetail?.request_list;

    if (!requestList || requestList.length === 0) {
      setSnackbarMessage('No request details available to submit.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    // Extract all request_ids from the request_list
    const doActionRequests = requestList.map((req) => {
      const requestId = req?.request_detail?.[0]?.request_id;
      if (!requestId) {
        console.warn(`Request ID missing for tracking_id: ${tracking_id}`);
        return null;
      }
      return {
        action_id: 3, // Assuming action_id:3 corresponds to 'send back with comments'
        request_id: requestId,
        auditComment,
        siteCaneHeadComment,
        // Removed revise_rates as audit cannot change rates
      };
    }).filter(item => item !== null); // Remove any null entries due to missing request_ids

    if (doActionRequests.length === 0) {
      setSnackbarMessage('No valid request IDs found to submit.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    setSubmittingRequests((prev) => ({ ...prev, [tracking_id]: true }));

    const payload = {
      tracking_id,
      site_cane_head_comments: siteCaneHeadComment,
      audit_comments: auditComment, // Send the audit comment provided by the user
      do_action_requests: doActionRequests,
    };

    try {
      const userDetailData = JSON.parse(localStorage.getItem('UserDetailData'));
      const jwtToken = userDetailData?.jwt_token;

      if (!jwtToken) {
        throw new Error('JWT token is missing.');
      }

      await axios.post(
        `${API_BASE_URL}C01FinalRequestAction`,
        payload,
        { headers: { Authorization: `Bearer ${jwtToken}` } }
      );

      // Update status to 'Submitted' for all relevant tracking_ids
      setRequestStatuses((prevStatuses) => ({
        ...prevStatuses,
        [tracking_id]: 'Submitted',
      }));

      setSnackbarMessage('Request submitted successfully.');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Failed to submit request:', error);
      setSnackbarMessage('Failed to submit the request. Please try again.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    } finally {
      setSubmittingRequests((prev) => ({ ...prev, [tracking_id]: false }));
    }
  };

  // Handle View Competitors
  const handleViewCompetitors = (competitors) => {
    setCompetitorsData(competitors || []);
    setOpenDialog(true);
  };

  // Handle close Competitors Dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Handle Chat Icon Click
  const handleChatClick = async (tracking_id) => {
    await fetchRequestDetails(tracking_id); // Ensure request details are fetched before navigating
    navigate(`/auditchat?trackingId=${tracking_id}`);
  };

  // Handle close snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  // Format date and time
  const formatDateTime = (dateTime) => {
    return dateTime ? format(new Date(dateTime), 'dd MMM yy HH:mm') : 'N/A';
  };

  // Filtered and Paginated Requests
  const filteredRequests = useMemo(() => {
    return trackingIds.filter((request) =>
      request.site_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      request.tracking_id.toString().includes(searchQuery) ||
      request.created_by_name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [trackingIds, searchQuery]);

  const paginatedRequests = useMemo(() => {
    return filteredRequests.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [filteredRequests, currentPage, itemsPerPage]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: '20px' }}>
        {/* Search Field and Items Per Page */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
            flexWrap: 'wrap',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <TextField
              label="Search Requests"
              variant="outlined"
              size="small"
              value={searchQuery} // Now defined
              onChange={(e) => setSearchQuery(e.target.value)} // Now defined
              placeholder="Search by Site Name, Tracking ID, or Created By"
              sx={{ width: 300 }}
            />
            <TextField
              select
              label="Items per page"
              value={itemsPerPage}
              onChange={(e) => {
                setItemsPerPage(Number(e.target.value));
                setCurrentPage(1); // Reset to first page on items per page change
              }}
              variant="outlined"
              size="small"
              sx={{ width: 150 }}
            >
              {[25, 50, 100, 250, 500].map((count) => (
                <MenuItem key={count} value={count}>
                  {count}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>

        {/* Loader Image Overlay */}
        {loading && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              zIndex: 9999,
            }}
          >
            <img
              src={loaderImage}
              alt="Loading..."
              style={{
                width: '100px',
                height: '100px',
                borderRadius: '50%',
                animation: 'spin 2s linear infinite',
              }}
            />
            <style>
              {`
                @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
                }
              `}
            </style>
          </Box>
        )}

        {/* Audit Requests Table */}
        {!loading && (
          <TableContainer component={Paper} sx={{ maxHeight: 'auto', overflowY: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Site Name</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Tracking ID</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Created By</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Created On</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Status</TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold' }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRequests.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No pending requests found.
                    </TableCell>
                  </TableRow>
                ) : (
                  paginatedRequests.map((request) => (
                    <React.Fragment key={request.tracking_id}>
                      {/* Tracking ID Header Row */}
                      <TableRow>
                        <TableCell colSpan={6} sx={{ backgroundColor: '#e0f2f1', padding: '4px 8px' }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            onClick={() => handleExpandClick(request.tracking_id)}
                            sx={{ cursor: 'pointer' }}
                          >
                            <IconButton size="small">
                              {expandedRequests[request.tracking_id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                              Tracking ID: {request.tracking_id}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>

                      {/* Tracking Details Row */}
                      <TableRow
                        hover
                        sx={{
                          backgroundColor: expandedRequests[request.tracking_id]
                            ? 'action.hover'
                            : 'inherit',
                        }}
                      >
                        <TableCell align="center" sx={{ padding: '6px' }}>
                          {request.site_name}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '6px' }}>
                          {request.tracking_id}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '6px' }}>
                          {request.created_by_name}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '6px' }}>
                          {formatDateTime(request.created_on)}
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '6px' }}>
                          <Typography
                            style={{
                              color: requestStatuses[request.tracking_id] === 'Submitted'
                                ? theme.palette.success.main
                                : requestStatuses[request.tracking_id] === 'Declined'
                                  ? theme.palette.error.main
                                  : 'black',
                              fontWeight: 'bold',
                            }}
                          >
                            {requestStatuses[request.tracking_id]}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" sx={{ padding: '6px' }}>
                          <Tooltip title={expandedRequests[request.tracking_id] ? 'Hide Details' : 'Show Details'}>
                            {/* Show/Hide Details button is already handled by header row click */}
                          </Tooltip>
                          <Tooltip title="Chat">
                            <IconButton
                              color="secondary"
                              onClick={() => handleChatClick(request.tracking_id)}
                              sx={{ padding: '4px' }}
                            >
                              <ChatIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Submit Request">
                            <span>
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CheckCircleIcon />}
                                onClick={() => handleSubmitRequest(request.tracking_id)}
                                disabled={requestStatuses[request.tracking_id]?.toLowerCase?.() === 'submitted'}
                                sx={{
                                  background: 'linear-gradient(to right, #00c6ff, #0072ff)',
                                  color: 'white',
                                  marginLeft: '8px',
                                  textTransform: 'none',
                                  fontSize: '0.875rem',
                                }}
                              >
                                Submit
                              </Button>
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>

                      {/* Collapse for Tracking Details */}
                      <TableRow>
                        <TableCell colSpan={6} sx={{ padding: 0 }}>
                          <Collapse
                            in={expandedRequests[request.tracking_id]}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box sx={{ padding: 1, backgroundColor: 'background.paper' }}>
                              {/* Comments Section */}
                              <Grid container spacing={2} sx={{ marginBottom: 2 }}>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    label="Audit Comment"
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    value={auditComments[request.tracking_id] || ''}
                                    onChange={(e) => handleAuditCommentChange(e, request.tracking_id)}
                                    placeholder="Enter your comment for this request"
                                    InputProps={{
                                      readOnly: requestStatuses[request.tracking_id]?.toLowerCase?.() === 'submitted',
                                    }}
                                    disabled={requestStatuses[request.tracking_id]?.toLowerCase?.() === 'submitted'}
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <TextField
                                    label="Site Cane Head Comment"
                                    multiline
                                    fullWidth
                                    variant="outlined"
                                    value={requestDetails[request.tracking_id]?.site_cane_head_comments || 'No comment available'}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                    sx={{ backgroundColor: '#f5f5f5' }} // Light gray background for read-only field
                                  />
                                </Grid>
                              </Grid>

                              {/* Requests Table */}
                              {requestDetails[request.tracking_id]?.request_list && (
                                <TableContainer component={Paper} variant="outlined" sx={{ mb: 1 }}>
                                  <Table size="small">
                                    <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                      <TableRow>
                                        {/* Changed "Request ID" to "Sr No." */}
                                        <TableCell
                                          align="center"
                                          sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                        >
                                          Sr No.
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                        >
                                          Request Status
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                        >
                                          Created On
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          sx={{ padding: '4px', fontWeight: 'bold', fontSize: '0.875rem' }}
                                        >
                                          Actions
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {requestDetails[request.tracking_id]?.request_list?.length > 0 ? (
                                        requestDetails[request.tracking_id]?.request_list?.map(
                                          (req, reqIndex) => (
                                            <React.Fragment key={req.request_detail?.[0]?.request_id || reqIndex}>
                                              <TableRow
                                                hover
                                                sx={{
                                                  backgroundColor: expandedRequests[
                                                    `${request.tracking_id}-${reqIndex}`
                                                  ]
                                                    ? 'action.hover'
                                                    : 'inherit',
                                                }}
                                              >
                                                {/* Display Serial Number Instead of Request ID */}
                                                <TableCell
                                                  align="center"
                                                  sx={{ fontWeight: 'bold', padding: '4px', fontSize: '0.875rem' }}
                                                >
                                                  {reqIndex + 1}
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                                                  {req.request_status}
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                                                  {formatDateTime(req.request_detail?.[0]?.created_on)}
                                                </TableCell>
                                                <TableCell sx={{ padding: '4px', fontSize: '0.875rem' }}>
                                                  <Button
                                                    variant="text"
                                                    size="small"
                                                    onClick={() =>
                                                      handleExpandRequest(request.tracking_id, reqIndex)
                                                    }
                                                    sx={{
                                                      textTransform: 'none',
                                                      mr: 0.5,
                                                      padding: '2px 4px',
                                                      fontSize: '0.75rem',
                                                    }}
                                                    disabled={requestStatuses[request.tracking_id]?.toLowerCase?.() === 'submitted'}
                                                  >
                                                    {expandedRequests[`${request.tracking_id}-${reqIndex}`]
                                                      ? 'Hide Details'
                                                      : 'Show Details'}
                                                  </Button>
                                                </TableCell>
                                              </TableRow>

                                              {/* Centers Data */}
                                              <TableRow>
                                                <TableCell colSpan={4} sx={{ padding: 0 }}>
                                                  <Collapse
                                                    in={expandedRequests[
                                                      `${request.tracking_id}-${reqIndex}`
                                                    ]}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <Box sx={{ margin: 0.5 }}>
                                                      <Table size="small">
                                                        <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                                                          <TableRow>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Center Name
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Circle
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Current Trolly Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Current Farsha Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Revised Trolly Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Revised Farsha Rate
                                                            </TableCell>
                                                            <TableCell
                                                              sx={{
                                                                padding: '4px',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.75rem',
                                                              }}
                                                            >
                                                              Competitors
                                                            </TableCell>
                                                          </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                          {req.request_detail?.length > 0 ? (
                                                            req.request_detail?.map(
                                                              (center, centerIndex) => (
                                                                <TableRow key={center.center_id || centerIndex}>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    {center.center_name || 'N/A'}
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    {center.circle_label || 'N/A'}
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    {center.present_trolly_rate || 'N/A'}
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    {center.present_farsha_rate || 'N/A'}
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    {center.revised_trolly_rate || 'N/A'}
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    {center.revised_farsha_rate || 'N/A'}
                                                                  </TableCell>
                                                                  <TableCell sx={{ padding: '4px', fontSize: '0.75rem' }}>
                                                                    <Tooltip title="View Competitors">
                                                                      <IconButton
                                                                        color="primary"
                                                                        size="small"
                                                                        onClick={() =>
                                                                          handleViewCompetitors(center.Competitors)
                                                                        }
                                                                        sx={{ padding: '2px' }}
                                                                      >
                                                                        <VisibilityIcon fontSize="small" />
                                                                      </IconButton>
                                                                    </Tooltip>
                                                                  </TableCell>
                                                                </TableRow>
                                                              )
                                                            )
                                                          ) : (
                                                            <TableRow>
                                                              <TableCell colSpan={7} align="center">
                                                                No center details available.
                                                              </TableCell>
                                                            </TableRow>
                                                          )}
                                                        </TableBody>
                                                      </Table>
                                                    </Box>
                                                  </Collapse>
                                                </TableCell>
                                              </TableRow>
                                            </React.Fragment>
                                          )
                                        )
                                      ) : (
                                        <TableRow>
                                          <TableCell colSpan={4} align="center">
                                            No request details available.
                                          </TableCell>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Pagination Controls */}
        {!loading && filteredRequests.length > 0 && (
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Pagination
              count={Math.ceil(totalCount / itemsPerPage)}
              page={currentPage}
              onChange={(event, value) => setCurrentPage(value)}
              variant="outlined"
              shape="rounded"
            />
          </Box>
        )}

        {/* Competitors Data Dialog */}
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
          <DialogTitle>Competitors Data</DialogTitle>
          <DialogContent>
            {competitorsData && competitorsData.length > 0 ? (
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead sx={{ backgroundColor: theme.palette.primary.light }}>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Competitor Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Current Trolly Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Revised Trolly Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Current Farsha Rate</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Revised Farsha Rate</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {competitorsData.map((competitor, index) => (
                      <TableRow key={index}>
                        <TableCell>{competitor.competitor_label || 'N/A'}</TableCell>
                        <TableCell>{competitor.present_trolly_rate || 'N/A'}</TableCell>
                        <TableCell>{competitor.revised_trolly_rate || 'N/A'}</TableCell>
                        <TableCell>{competitor.present_farsha_rate || 'N/A'}</TableCell>
                        <TableCell>{competitor.revised_farsha_rate || 'N/A'}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No competitors data available.</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success/Error Snackbar */}
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default AuditTable;
