// import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
// import SideBar from './SideBar';
// import TopBar from './TopBar';
// import '../Styles/Dashboard.css';
// import axios from 'axios';
// import swal from 'sweetalert';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Breadcrumbs, Typography, Link, TextField } from '@mui/material'; // Imported TextField
// import API_BASE_URL from '../config';
// import FixedTable from './FixedTable';
// import ScrollableTable from './ScrollableTable';
// import loaderImage from '../assects/Images/Logos/CRVlogo.png'; // Ensure this path is correct

// const drawerWidth = 240;

// // Styled main content area
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const Requests = () => {
//   const getObjectFromLocal = (key) => {
//     const jsonString = localStorage.getItem(key);
//     return JSON.parse(jsonString);
//   };

//   const retrievedObject = getObjectFromLocal('UserDetailData') || {};
//   const jwtToken = retrievedObject.jwt_token;
//   const userID = retrievedObject.user_id;

//   const [open, setOpen] = useState(true);
//   const [fixedColumns, setFixedColumns] = useState([]);
//   const [scrollableColumns, setScrollableColumns] = useState([]);
//   const [fixedRows, setFixedRows] = useState([]);
//   const [editableRows, setEditableRows] = useState([]);
//   const [fixedColumnGroupingModel, setFixedColumnGroupingModel] = useState([]);
//   const [scrollableGroupingModel, setScrollableGroupingModel] = useState([]);
//   const [competitors, setCompetitors] = useState([]);
//   const [isLoading, setLoading] = useState(true);
//   const [searchInput, setSearchInput] = useState('');
//   const [lastEditedCell, setLastEditedCell] = useState(null);
//   const scrollableTableRef = useRef(null);
//   const originalEditableRowsRef = useRef([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true); // Start loading


        
//         // const response = await axios.post(
//         //   `${API_BASE_URL}C01ProgramOfficerGetCentersData`,
//         //   {},
//         //   {
//         //     headers: {
//         //       Authorization: `Bearer ${jwtToken}`,
//         //       'Content-Type': 'application/json',
//         //     },
//         //   }
//         // );



//         const response = await axios.get(`${API_BASE_URL}C01ProgramOfficerGetCentersData`, {
//           headers: {
//             Authorization: `Bearer ${jwtToken}`, 
//             'Content-Type': 'application/json',// Include the token in the Authorization header
//           },
//         });



//         if (response.status === 200) {
//           const fetchedData = response.data.DATA;
//           const userDetailData = JSON.parse(localStorage.getItem('UserDetailData')) || {};

//           userDetailData.centers = fetchedData.centers;
//           userDetailData.competitors = fetchedData.competitors;

//           localStorage.setItem('UserDetailData', JSON.stringify(userDetailData));

//           setCompetitors(fetchedData.competitors);
//           setLoading(false);

//           generateColumnsForCompetitors(fetchedData.competitors);
//           const generatedRows = generateRowsForData(fetchedData);
//           setFixedRows(generatedRows.map((row) => ({ ...row })));
//           setEditableRows(generatedRows.map((row) => ({ ...row })));
//           originalEditableRowsRef.current = generatedRows.map((row) => ({ ...row }));
//         } else {
//           console.error('API call failed:', response.data.SYSTEM_MESSAGE);
//           toast.error('Failed to fetch data from the server.', { autoClose: 5000 });
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         toast.error('Error fetching data. Please try again later.', { autoClose: 5000 });
//       } finally {
//         setLoading(false); // Stop loading
//       }
//     };

//     fetchData();
//   }, [jwtToken]);

//   useEffect(() => {
//     if (lastEditedCell && scrollableTableRef.current) {
//       const cell = scrollableTableRef.current.querySelector(
//         `[data-id='${lastEditedCell.id}'] [data-field='${lastEditedCell.field}']`
//       );
//       if (cell) {
//         cell.scrollIntoView({ behavior: 'smooth', block: 'center' });
//         // cell.focus();
//       }
//     }
//   }, [lastEditedCell]);

//   // Function to generate fixed and scrollable columns based on competitors
//   const generateColumnsForCompetitors = useCallback((competitors) => {

//     // Define fixed columns
//     const fixedCols = [
//       {
//         field: 'zone',
//         headerName: 'Zone',
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//         width: 120,
//         resizable: false,
//       },
//       {
//         field: 'circle',
//         headerName: 'Circle',
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//         width: 120,
//         resizable: false,
//       },
//       {
//         field: 'center',
//         headerName: 'Center',
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//         width: 120,
//         resizable: false,
//       },
//       {
//         field: 'current_rates_trolly',
//         headerName: 'Trolly',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         width: 100,
//         resizable: false,
//       },
//       {
//         field: 'current_rates_farsha',
//         headerName: 'Farsha',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         width: 100,
//         resizable: false,
//       },
//     ];

//     // Define scrollable columns
//     let scrollableCols = [
//       {
//         field: 'revised_rates_trolly',
//         headerName: 'Trolly',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         editable: true,
//         width: 100,
//         resizable: false,
//         valueParser: (value) => parseFloat(value),
//       },
//       {
//         field: 'revised_rates_farsha',
//         headerName: 'Farsha',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         editable: true,
//         width: 100,
//         resizable: false,
//         valueParser: (value) => parseFloat(value),
//       },
//     ];

//     // Dynamically add competitor-specific columns
//     competitors.forEach((competitor) => {
//       const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//       scrollableCols.push(
//         {
//           field: `revised_rates_${sanitizedLabel}_trolly`,
//           headerName: 'Trolly',
//           type: 'number',
//           headerClassName: 'colum-color',
//           headerAlign: 'center',
//           editable: true,
//           width: 100,
//           resizable: false,
//           valueParser: (value) => parseFloat(value),
//         },
//         {
//           field: `revised_rates_${sanitizedLabel}_farsha`,
//           headerName: 'Farsha',
//           type: 'number',
//           headerClassName: 'colum-color',
//           headerAlign: 'center',
//           editable: true,
//           width: 100,
//           resizable: false,
//           valueParser: (value) => parseFloat(value),
//         }
//       );
//     });

//     // Define column grouping for fixed columns
//     const fixedGroupingModel = [
//       {
//         groupId: 'details',
//         headerName: 'Details',
//         children: [{ field: 'zone' }, { field: 'circle' }, { field: 'center' }],
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//       },
//       {
//         groupId: 'currentRates',
//         headerName: 'Current Rates',
//         children: [{ field: 'current_rates_trolly' }, { field: 'current_rates_farsha' }],
//         headerClassName: 'rate-color-bg',
//         headerAlign: 'center',
//       },
//     ];

//     // Define column grouping for scrollable grid
//     const scrollableGroupingModel = [
//       {
//         groupId: 'revisedRates',
//         headerName: 'Revised Rates',
//         children: [{ field: 'revised_rates_trolly' }, { field: 'revised_rates_farsha' }],
//         headerClassName: 'rate-color-bg',
//         headerAlign: 'center',
//       },
//       // Dynamically add grouping for each competitor
//       ...competitors.map((competitor, index) => ({
//         groupId: `competitor_${index}`,
//         headerName: competitor.label,
//         children: [
//           { field: `revised_rates_${competitor.label.replace(/\s+/g, '_').toLowerCase()}_trolly` },
//           { field: `revised_rates_${competitor.label.replace(/\s+/g, '_').toLowerCase()}_farsha` },
//         ],
//         headerClassName: 'zone-color2',
//         headerAlign: 'center',
//       })),
//     ];

//     // Update state with generated columns and grouping models
//     setFixedColumns(fixedCols);
//     setScrollableColumns(scrollableCols);
//     setFixedColumnGroupingModel(fixedGroupingModel);
//     setScrollableGroupingModel(scrollableGroupingModel);
//   }, []);

//   const generateRowsForData = useCallback((data) => {
//     const { centers, competitors } = data || { centers: [], competitors: [] };
//     let dynamicRows = [];

//     if (!Array.isArray(centers)) {
//       console.error('Centers data is not an array:', centers);
//       return [];
//     }

//     centers.forEach((center) => {
//       let row = {
//         id: center.id, // Use center.id as unique and stable id
//         zone_id: center.zone_id,
//         circle_id: center.circle_id,
//         center_id: center.id,
//         zone: center.zone_label,
//         circle: center.circle_label,
//         center: center.label,
//         current_rates_trolly: center.current_trolly_rate,
//         current_rates_farsha: center.current_farsha_rate,
//         revised_rates_trolly: center.revised_trolly_rate || null,
//         revised_rates_farsha: center.revised_farsha_rate || null,
//       };

//       competitors.forEach((competitor) => {
//         const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();

//         row[`revised_rates_${sanitizedLabel}_trolly`] = center[`revised_rates_${sanitizedLabel}_trolly`] || null;
//         row[`revised_rates_${sanitizedLabel}_farsha`] = center[`revised_rates_${sanitizedLabel}_farsha`] || null;
//       });

//       dynamicRows.push(row);
//     });

//     return dynamicRows;
//   }, []);

//   const handleRowUpdate = useCallback((newRow) => {
//     // Removed negative value validation logic

//     setEditableRows((prevRows) => {
//       const index = prevRows.findIndex((row) => row.id === newRow.id);
//       if (index === -1) return prevRows;
//       const updatedRows = [...prevRows];
//       updatedRows[index] = newRow;

//       setLastEditedCell({
//         id: newRow.id,
//         field: Object.keys(newRow).find((key) => newRow[key] !== prevRows[index][key]),
//       });

//       return updatedRows;
//     });
//     return newRow;
//   }, []);

//   const formatNumber = (value) => {
//     const floatValue = parseFloat(value);
//     if (Number.isNaN(floatValue)) {
//       return 0.0;
//     }
//     if (Number.isInteger(floatValue)) {
//       return parseFloat(floatValue.toFixed(1));
//     }
//     return floatValue;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Find rows that have changed by comparing editableRows with originalEditableRowsRef
//     const updatedRowsToProcess = editableRows.filter((row) => {
//       const originalRow = originalEditableRowsRef.current.find((origRow) => origRow.id === row.id);

//       // Check if row has no changes or if the user deleted the data
//       const hasRevisedRates = (row.revised_rates_trolly && row.revised_rates_trolly > 0) ||
//         (row.revised_rates_farsha && row.revised_rates_farsha > 0);

//       const hasCompetitorRates = competitors.some((competitor) => {
//         const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//         return (
//           (row[`revised_rates_${sanitizedLabel}_trolly`] &&
//             row[`revised_rates_${sanitizedLabel}_trolly`] > 0) ||
//           (row[`revised_rates_${sanitizedLabel}_farsha`] &&
//             row[`revised_rates_${sanitizedLabel}_farsha`] > 0)
//         );
//       });

//       // If the row has no revised rates and no competitor rates, exclude it from submission
//       if (!hasRevisedRates && !hasCompetitorRates) {
//         return false;
//       }

//       // Only include rows that are actually different from the original rows
//       return JSON.stringify(row) !== JSON.stringify(originalRow);
//     });

//     if (updatedRowsToProcess.length === 0) {
//       toast.error('No changes to submit.', { autoClose: 5000 });
//       return;
//     }

//     let isValid = true;

//     // Validation logic for negative values
//     for (let row of updatedRowsToProcess) {
//       // Check if revised rates are negative
//       if (
//         (row.revised_rates_trolly && parseFloat(row.revised_rates_trolly) < 0) ||
//         (row.revised_rates_farsha && parseFloat(row.revised_rates_farsha) < 0)
//       ) {
//         isValid = false;
//         toast.error('Revised rates cannot be negative.', { autoClose: 5000 });
//         break;
//       }

//       // Check if any competitor's rates are negative
//       const competitorRatesNegative = competitors.some((competitor) => {
//         const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//         return (
//           (row[`revised_rates_${sanitizedLabel}_trolly`] &&
//             parseFloat(row[`revised_rates_${sanitizedLabel}_trolly`]) < 0) ||
//           (row[`revised_rates_${sanitizedLabel}_farsha`] &&
//             parseFloat(row[`revised_rates_${sanitizedLabel}_farsha`]) < 0)
//         );
//       });

//       if (competitorRatesNegative) {
//         isValid = false;
//         toast.error('Competitor rates cannot be negative.', { autoClose: 5000 });
//         break;
//       }

//       // Additional validation: Ensure that revised rates and competitor rates are entered together
//       const revisedRatesEntered =
//         (row.revised_rates_trolly && parseFloat(row.revised_rates_trolly) > 0) ||
//         (row.revised_rates_farsha && parseFloat(row.revised_rates_farsha) > 0);

//       const competitorRatesEntered = competitors.some((competitor) => {
//         const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//         return (
//           (row[`revised_rates_${sanitizedLabel}_trolly`] &&
//             parseFloat(row[`revised_rates_${sanitizedLabel}_trolly`]) > 0) ||
//           (row[`revised_rates_${sanitizedLabel}_farsha`] &&
//             parseFloat(row[`revised_rates_${sanitizedLabel}_farsha`]) > 0)
//         );
//       });

//       if (revisedRatesEntered && !competitorRatesEntered) {
//         isValid = false;
//         toast.error('Please enter at least one competitor rate when entering Revised Rate Trolly or Farsha.', {
//           autoClose: 5000,
//         });
//         break;
//       }

//       if (competitorRatesEntered && !revisedRatesEntered) {
//         isValid = false;
//         toast.error('Please enter Revised Rate Trolly or Farsha when entering competitor rates.', {
//           autoClose: 5000,
//         });
//         break;
//       }
//     }

//     if (!isValid) {
//       return; // Stop submission if validation fails
//     }

//     // Confirmation before submission
//     swal({
//       title: 'Are you sure?',
//       text: 'Once submitted, you will not be able to recover this data!',
//       icon: 'warning',
//       buttons: true,
//       dangerMode: true,
//     }).then((willSubmit) => {
//       if (willSubmit) {
//         saveData(updatedRowsToProcess);
//       }
//     });
//   };
//       const saveData = async (changedRows) => {
//       const formattedData = changedRows.map((row) => {
//       const updatedCompetitors = [];

//       competitors.forEach((competitor) => {
//         const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//         const revisedTrolly = row[`revised_rates_${sanitizedLabel}_trolly`];
//         const revisedFarsha = row[`revised_rates_${sanitizedLabel}_farsha`];

//         if (
//           (revisedTrolly !== undefined && revisedTrolly !== null && revisedTrolly !== 0) ||
//           (revisedFarsha !== undefined && revisedFarsha !== null && revisedFarsha !== 0)
//         ) {
//           updatedCompetitors.push({
//             id: competitor.id,
//             trolly: revisedTrolly !== null && revisedTrolly !== undefined ? formatNumber(revisedTrolly) : 0.0,
//             farsha: revisedFarsha !== null && revisedFarsha !== undefined ? formatNumber(revisedFarsha) : 0.0,
//           });
//         }
//       });

//       return {
//         center: row.center_id,
//         userId: userID,
//         site: retrievedObject.user_site,
//         zone: row.zone_id,
//         circle: row.circle_id,
//         trolly: row.revised_rates_trolly ? formatNumber(row.revised_rates_trolly) : 0.0,
//         farsha: row.revised_rates_farsha ? formatNumber(row.revised_rates_farsha) : 0.0,
//         reviseFarshaDBVal: formatNumber(row.current_rates_farsha),
//         reviseTrollyDBVal: formatNumber(row.current_rates_trolly),
//         competitors: updatedCompetitors,
//       };
//     });

//     try {
//       const response = await axios.post(
//         `${API_BASE_URL}C01ProgramOfficerRequest`,
//         formattedData,
//         {
//           headers: {
//             Authorization: `Bearer ${jwtToken}`,
//             'Content-Type': 'application/json',
//           },
//         }
//       );

//       if (response.status === 200) {
//         toast.success('Data saved successfully!', { autoClose: 5000 });
//         resetUserEnteredValues();
//         originalEditableRowsRef.current = editableRows.map((row) => ({ ...row }));
//         setFixedRows(editableRows.map((row) => ({ ...row })));
//       } else {
//         toast.error('Failed to save data! ' + (response.data.SYSTEM_MESSAGE || ''), { autoClose: 5000 });
//       }
//     } catch (error) {
//       console.error('Error during data save:', error);
//       toast.error('Error during data save!', { autoClose: 5000 });
//     }
//   };

//   const resetUserEnteredValues = () => {
//     setEditableRows((prevRows) => {
//       const resetRows = prevRows.map((row) => {
//         const resetRow = { ...row };
//         resetRow.revised_rates_trolly = null;
//         resetRow.revised_rates_farsha = null;

//         competitors.forEach((competitor) => {
//           const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//           resetRow[`revised_rates_${sanitizedLabel}_trolly`] = null;
//           resetRow[`revised_rates_${sanitizedLabel}_farsha`] = null;
//         });

//         return resetRow;
//       });
//       return resetRows;
//     });
//   };

//   // Filtering both fixed and editable rows based on search input
//   const filteredFixedRows = useMemo(() => {
//     if (!searchInput) return fixedRows;

//     const searchStr = searchInput.toLowerCase();

//     return fixedRows.filter((row) => {
//       const zoneMatch = row.zone.toLowerCase().includes(searchStr);
//       const circleMatch = row.circle.toLowerCase().includes(searchStr);
//       const centerMatch = row.center.toLowerCase().includes(searchStr);
//       const currentTrollyMatch = String(row.current_rates_trolly || '').toLowerCase().includes(searchStr);
//       const currentFarshaMatch = String(row.current_rates_farsha || '').toLowerCase().includes(searchStr);

//       return zoneMatch || circleMatch || centerMatch || currentTrollyMatch || currentFarshaMatch;
//     });
//   }, [fixedRows, searchInput]);

//   const filteredEditableRows = useMemo(() => {
//     if (!searchInput) return editableRows;

//     const searchStr = searchInput.toLowerCase();

//     return editableRows.filter((row) => {
//       const zoneMatch = row.zone.toLowerCase().includes(searchStr);
//       const circleMatch = row.circle.toLowerCase().includes(searchStr);
//       const centerMatch = row.center.toLowerCase().includes(searchStr);
//       const currentTrollyMatch = String(row.current_rates_trolly || '').toLowerCase().includes(searchStr);
//       const currentFarshaMatch = String(row.current_rates_farsha || '').toLowerCase().includes(searchStr);

//       return zoneMatch || circleMatch || centerMatch || currentTrollyMatch || currentFarshaMatch;
//     });
//   }, [editableRows, searchInput]);

//   const memoizedFixedColumns = useMemo(() => fixedColumns, [fixedColumns]);
//   const memoizedScrollableColumns = useMemo(() => scrollableColumns, [scrollableColumns]);
//   const memoizedFixedGroupingModel = useMemo(() => fixedColumnGroupingModel, [fixedColumnGroupingModel]);
//   const memoizedScrollableGroupingModel = useMemo(() => scrollableGroupingModel, [scrollableGroupingModel]);

//   // Loader Overlay
//   const LoaderOverlay = () => (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100%',
//         backgroundColor: 'rgba(255, 255, 255, 0.8)',
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         zIndex: 9999,
//       }}
//     >
//       <img
//         src={loaderImage}
//         alt="Loading..."
//         style={{
//           width: '100px',
//           height: '100px',
//           borderRadius: '50%',
//           animation: 'spin 2s linear infinite',
//         }}
//       />
//       <style>
//         {`
//           @keyframes spin {
//             0% { transform: rotate(0deg); }
//             100% { transform: rotate(360deg); }
//           }
//         `}
//       </style>
//     </Box>
//   );

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
//         <SideBar isOpen={open} />
//       </Drawer>

//       <Main open={open}>
//         <TopBar toggleSidebar={() => setOpen(!open)} />

//         <Box sx={{ padding: '20px', flex: '1', overflowY: 'auto' }}>
//           <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '10px' }}>
//             <Link color="inherit" href="/dashboard">
//               Home
//             </Link>
//             <Typography color="textPrimary">New Request</Typography>
//           </Breadcrumbs>

//           <ToastContainer />


//           {/* Added Search Field */}
//           <Grid item xs={12}>
//             <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
//               <TextField
//                 label="Search by Zone, Circle, or Center"
//                 variant="outlined"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//                 size="small"
//                 sx={{ width: '300px' }}
//               />
//               {/* Optional: Add a clear button */}
//               {searchInput && (
//                 <Button
//                   variant="text"
//                   onClick={() => setSearchInput('')}
//                   sx={{ marginLeft: '10px' }}
//                 >
//                   Clear
//                 </Button>
//               )}
//             </Box>

//             <Grid item xs={12}>
//               <Paper
//                 sx={{
//                   boxShadow: 'none',
//                   borderRadius: '0',
//                   border: '1px solid #D9D9D9',
//                   display: 'grid',
//                   width: '100%',
//                   position: 'relative', // Position for loader overlay
//                 }}
//               >
//                 <div style={{  overflow: 'hidden', zIndex: 0 }}>
//                   <div style={{ display: 'flex' }}>
//                     <div
//                       style={{
//                         overflow: 'hidden',
//                         width: fixedColumns.reduce((sum, col) => sum + (col.width || 150), 0),
//                       }}
//                     >
//                       <FixedTable
//                         rows={filteredFixedRows}
//                         columns={memoizedFixedColumns}
//                         columnGroupingModel={memoizedFixedGroupingModel}
//                       />
//                     </div>

//                     <div style={{ flexGrow: 1, overflowX: 'auto', width: '50%' }} ref={scrollableTableRef}>
//                       <ScrollableTable
//                         rows={filteredEditableRows} // Updated to use filteredEditableRows
//                         columns={memoizedScrollableColumns}
//                         columnGroupingModel={memoizedScrollableGroupingModel}
//                         onRowUpdate={handleRowUpdate}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 {isLoading && <LoaderOverlay />} {/* Show loader only over the table */}
//               </Paper>

//               <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px' }}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleSubmit}
//                   sx={{
//                     background: 'linear-gradient(to right, #a8e063, #56ab2f)',
//                     color: 'white',
//                   }}
//                 >
//                   Submit
//                 </Button>
//               </div>
//             </Grid>
//           </Grid>
//         </Box>
//       </Main>
//     </Box>
//   );
// };

// export default Requests;



//abc

// import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// import { styled } from '@mui/material/styles';
// import Box from '@mui/material/Box';
// import Drawer from '@mui/material/Drawer';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Button from '@mui/material/Button';
// import SideBar from './SideBar';
// import TopBar from './TopBar';
// import '../Styles/Dashboard.css';
// import axios from 'axios';
// import swal from 'sweetalert';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import { Breadcrumbs, Typography, Link, TextField } from '@mui/material'; // Imported TextField
// import API_BASE_URL from '../config';
// import FixedTable from './FixedTable';
// import ScrollableTable from './ScrollableTable';
// import loaderImage from '../assects/Images/Logos/CRVlogo.png'; // Ensure this path is correct

// const drawerWidth = 240;

// // Styled main content area
// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   })
// );

// const Requests = () => {
//   const getObjectFromLocal = (key) => {
//     const jsonString = localStorage.getItem(key);
//     return JSON.parse(jsonString);
//   };

//   const retrievedObject = getObjectFromLocal('UserDetailData') || {};
//   const jwtToken = retrievedObject.jwt_token;
//   const userID = retrievedObject.user_id;

//   const [open, setOpen] = useState(true);
//   const [fixedColumns, setFixedColumns] = useState([]);
//   const [scrollableColumns, setScrollableColumns] = useState([]);
//   const [fixedRows, setFixedRows] = useState([]);
//   const [editableRows, setEditableRows] = useState([]);
//   const [fixedColumnGroupingModel, setFixedColumnGroupingModel] = useState([]);
//   const [scrollableGroupingModel, setScrollableGroupingModel] = useState([]);
//   const [competitors, setCompetitors] = useState([]);
//   const [isLoading, setLoading] = useState(true);
//   const [searchInput, setSearchInput] = useState('');
//   const [lastEditedCell, setLastEditedCell] = useState(null);
//   const scrollableTableRef = useRef(null);
//   const originalEditableRowsRef = useRef([]);

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true); // Start loading


        
//         // const response = await axios.post(
//         //   `${API_BASE_URL}C01ProgramOfficerGetCentersData`,
//         //   {},
//         //   {
//         //     headers: {
//         //       Authorization: `Bearer ${jwtToken}`,
//         //       'Content-Type': 'application/json',
//         //     },
//         //   }
//         // );



//         const response = await axios.get(`${API_BASE_URL}C01ProgramOfficerGetCentersData`, {
//           headers: {
//             Authorization: `Bearer ${jwtToken}`, 
//             'Content-Type': 'application/json',// Include the token in the Authorization header
//           },
//         });



//         if (response.status === 200) {
//           const fetchedData = response.data.DATA;
//           const userDetailData = JSON.parse(localStorage.getItem('UserDetailData')) || {};

//           userDetailData.centers = fetchedData.centers;
//           userDetailData.competitors = fetchedData.competitors;

//           localStorage.setItem('UserDetailData', JSON.stringify(userDetailData));

//           setCompetitors(fetchedData.competitors);
//           setLoading(false);

//           generateColumnsForCompetitors(fetchedData.competitors);
//           const generatedRows = generateRowsForData(fetchedData);
//           setFixedRows(generatedRows.map((row) => ({ ...row })));
//           setEditableRows(generatedRows.map((row) => ({ ...row })));
//           originalEditableRowsRef.current = generatedRows.map((row) => ({ ...row }));
//         } else {
//           console.error('API call failed:', response.data.SYSTEM_MESSAGE);
//           toast.error('Failed to fetch data from the server.', { autoClose: 5000 });
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         toast.error('Error fetching data. Please try again later.', { autoClose: 5000 });
//       } finally {
//         setLoading(false); // Stop loading
//       }
//     };

//     fetchData();
//   }, [jwtToken]);

//   useEffect(() => {
//     if (lastEditedCell && scrollableTableRef.current) {
//       const cell = scrollableTableRef.current.querySelector(
//         `[data-id='${lastEditedCell.id}'] [data-field='${lastEditedCell.field}']`
//       );
//       if (cell) {
//         cell.scrollIntoView({ behavior: 'smooth', block: 'center' });
//         // cell.focus();
//       }
//     }
//   }, [lastEditedCell]);

//   // Function to generate fixed and scrollable columns based on competitors
//   const generateColumnsForCompetitors = useCallback((competitors) => {

//     // Define fixed columns
//     const fixedCols = [
//       {
//         field: 'zone',
//         headerName: 'Zone',
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//         width: 120,
//         resizable: false,
//       },
//       {
//         field: 'circle',
//         headerName: 'Circle',
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//         width: 120,
//         resizable: false,
//       },
//       {
//         field: 'center',
//         headerName: 'Center',
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//         width: 120,
//         resizable: false,
//       },
//       {
//         field: 'current_rates_trolly',
//         headerName: 'Trolly',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         width: 100,
//         resizable: false,
//       },
//       {
//         field: 'current_rates_farsha',
//         headerName: 'Farsha',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         width: 100,
//         resizable: false,
//       },
//     ];

//     // Define scrollable columns
//     let scrollableCols = [
//       {
//         field: 'revised_rates_trolly',
//         headerName: 'Trolly',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         editable: true,
//         width: 100,
//         resizable: false,
//         valueParser: (value) => parseFloat(value),
//       },
//       {
//         field: 'revised_rates_farsha',
//         headerName: 'Farsha',
//         type: 'number',
//         headerClassName: 'colum-color',
//         headerAlign: 'center',
//         editable: true,
//         width: 100,
//         resizable: false,
//         valueParser: (value) => parseFloat(value),
//       },
//     ];

//     // Dynamically add competitor-specific columns
//     competitors.forEach((competitor) => {
//       const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//       scrollableCols.push(
//         {
//           field: `revised_rates_${sanitizedLabel}_trolly`,
//           headerName: 'Trolly',
//           type: 'number',
//           headerClassName: 'colum-color',
//           headerAlign: 'center',
//           editable: true,
//           width: 100,
//           resizable: false,
//           valueParser: (value) => parseFloat(value),
//         },
//         {
//           field: `revised_rates_${sanitizedLabel}_farsha`,
//           headerName: 'Farsha',
//           type: 'number',
//           headerClassName: 'colum-color',
//           headerAlign: 'center',
//           editable: true,
//           width: 100,
//           resizable: false,
//           valueParser: (value) => parseFloat(value),
//         }
//       );
//     });

//     // Define column grouping for fixed columns
//     const fixedGroupingModel = [
//       {
//         groupId: 'details',
//         headerName: 'Details',
//         children: [{ field: 'zone' }, { field: 'circle' }, { field: 'center' }],
//         headerClassName: 'zone-color',
//         headerAlign: 'center',
//       },
//       {
//         groupId: 'currentRates',
//         headerName: 'Current Rates',
//         children: [{ field: 'current_rates_trolly' }, { field: 'current_rates_farsha' }],
//         headerClassName: 'rate-color-bg',
//         headerAlign: 'center',
//       },
//     ];

//     // Define column grouping for scrollable grid
//     const scrollableGroupingModel = [
//       {
//         groupId: 'revisedRates',
//         headerName: 'Revised Rates',
//         children: [{ field: 'revised_rates_trolly' }, { field: 'revised_rates_farsha' }],
//         headerClassName: 'rate-color-bg',
//         headerAlign: 'center',
//       },
//       // Dynamically add grouping for each competitor
//       ...competitors.map((competitor, index) => ({
//         groupId: `competitor_${index}`,
//         headerName: competitor.label,
//         children: [
//           { field: `revised_rates_${competitor.label.replace(/\s+/g, '_').toLowerCase()}_trolly` },
//           { field: `revised_rates_${competitor.label.replace(/\s+/g, '_').toLowerCase()}_farsha` },
//         ],
//         headerClassName: 'zone-color2',
//         headerAlign: 'center',
//       })),
//     ];

//     // Update state with generated columns and grouping models
//     setFixedColumns(fixedCols);
//     setScrollableColumns(scrollableCols);
//     setFixedColumnGroupingModel(fixedGroupingModel);
//     setScrollableGroupingModel(scrollableGroupingModel);
//   }, []);

//   const generateRowsForData = useCallback((data) => {
//     const { centers, competitors } = data || { centers: [], competitors: [] };
//     let dynamicRows = [];

//     if (!Array.isArray(centers)) {
//       console.error('Centers data is not an array:', centers);
//       return [];
//     }

//     centers.forEach((center) => {
//       let row = {
//         id: center.id, // Use center.id as unique and stable id
//         zone_id: center.zone_id,
//         circle_id: center.circle_id,
//         center_id: center.id,
//         zone: center.zone_label,
//         circle: center.circle_label,
//         center: center.label,
//         current_rates_trolly: center.current_trolly_rate,
//         current_rates_farsha: center.current_farsha_rate,
//         revised_rates_trolly: center.revised_trolly_rate || null,
//         revised_rates_farsha: center.revised_farsha_rate || null,
//       };

//       competitors.forEach((competitor) => {
//         const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();

//         row[`revised_rates_${sanitizedLabel}_trolly`] = center[`revised_rates_${sanitizedLabel}_trolly`] || null;
//         row[`revised_rates_${sanitizedLabel}_farsha`] = center[`revised_rates_${sanitizedLabel}_farsha`] || null;
//       });

//       dynamicRows.push(row);
//     });

//     return dynamicRows;
//   }, []);

//   const handleRowUpdate = useCallback((newRow) => {
//     // Removed negative value validation logic

//     setEditableRows((prevRows) => {
//       const index = prevRows.findIndex((row) => row.id === newRow.id);
//       if (index === -1) return prevRows;
//       const updatedRows = [...prevRows];
//       updatedRows[index] = newRow;

//       setLastEditedCell({
//         id: newRow.id,
//         field: Object.keys(newRow).find((key) => newRow[key] !== prevRows[index][key]),
//       });

//       return updatedRows;
//     });
//     return newRow;
//   }, []);

//   const formatNumber = (value) => {
//     const floatValue = parseFloat(value);
//     if (Number.isNaN(floatValue)) {
//       return 0.0;
//     }
//     if (Number.isInteger(floatValue)) {
//       return parseFloat(floatValue.toFixed(1));
//     }
//     return floatValue;
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     // Find rows that have changed by comparing editableRows with originalEditableRowsRef
//     const updatedRowsToProcess = editableRows.filter((row) => {
//         const originalRow = originalEditableRowsRef.current.find((origRow) => origRow.id === row.id);

//         // Check if row has no changes or if the user deleted the data
//         const hasRevisedRates = (row.revised_rates_trolly && row.revised_rates_trolly > 0) ||
//             (row.revised_rates_farsha && row.revised_rates_farsha > 0);

//         const hasCompetitorRates = competitors.some((competitor) => {
//             const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//             return (
//                 (row[`revised_rates_${sanitizedLabel}_trolly`] &&
//                     row[`revised_rates_${sanitizedLabel}_trolly`] > 0) ||
//                 (row[`revised_rates_${sanitizedLabel}_farsha`] &&
//                     row[`revised_rates_${sanitizedLabel}_farsha`] > 0)
//             );
//         });

//         // If the row has no revised rates and no competitor rates, exclude it from submission
//         if (!hasRevisedRates && !hasCompetitorRates) {
//             return false;
//         }

//         // Only include rows that are actually different from the original rows
//         return JSON.stringify(row) !== JSON.stringify(originalRow);
//     });

//     if (updatedRowsToProcess.length === 0) {
//         swal('No changes to submit.', '', 'warning');
//         return;
//     }

//     let isValid = true;

//     // Validation logic for negative values
//     for (let row of updatedRowsToProcess) {
//         // Check if revised rates are negative
//         if (
//             (row.revised_rates_trolly && parseFloat(row.revised_rates_trolly) < 0) ||
//             (row.revised_rates_farsha && parseFloat(row.revised_rates_farsha) < 0)
//         ) {
//             isValid = false;
//             swal('Error', 'Revised rates cannot be negative.', 'error');
//             break;
//         }

//         // Check if any competitor's rates are negative
//         const competitorRatesNegative = competitors.some((competitor) => {
//             const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//             return (
//                 (row[`revised_rates_${sanitizedLabel}_trolly`] &&
//                     parseFloat(row[`revised_rates_${sanitizedLabel}_trolly`]) < 0) ||
//                 (row[`revised_rates_${sanitizedLabel}_farsha`] &&
//                     parseFloat(row[`revised_rates_${sanitizedLabel}_farsha`]) < 0)
//             );
//         });

//         if (competitorRatesNegative) {
//             isValid = false;
//             swal('Error', 'Competitor rates cannot be negative.', 'error');
//             break;
//         }

//         // Additional validation: Ensure that revised rates and competitor rates are entered together
//         const revisedRatesEntered =
//             (row.revised_rates_trolly && parseFloat(row.revised_rates_trolly) > 0) ||
//             (row.revised_rates_farsha && parseFloat(row.revised_rates_farsha) > 0);

//         const competitorRatesEntered = competitors.some((competitor) => {
//             const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//             return (
//                 (row[`revised_rates_${sanitizedLabel}_trolly`] &&
//                     parseFloat(row[`revised_rates_${sanitizedLabel}_trolly`]) > 0) ||
//                 (row[`revised_rates_${sanitizedLabel}_farsha`] &&
//                     parseFloat(row[`revised_rates_${sanitizedLabel}_farsha`]) > 0)
//             );
//         });

//         if (revisedRatesEntered && !competitorRatesEntered) {
//             isValid = false;
//             swal('Error', 'Please enter at least one competitor rate when entering Revised Rate Trolly or Farsha.', 'error');
//             break;
//         }

//         if (competitorRatesEntered && !revisedRatesEntered) {
//             isValid = false;
//             swal('Error', 'Please enter Revised Rate Trolly or Farsha when entering competitor rates.', 'error');
//             break;
//         }
//     }

//     if (!isValid) {
//         return; // Stop submission if validation fails
//     }

//     // Confirmation before submission
//     swal({
//         title: 'Are you sure?',
//         text: 'Once submitted, you will not be able to recover this data!',
//         icon: 'warning',
//         buttons: true,
//         dangerMode: true,
//     }).then((willSubmit) => {
//         if (willSubmit) {
//             saveData(updatedRowsToProcess);
//         }
//     });
// };
// const saveData = async (changedRows) => {
//   const formattedData = changedRows.map((row) => {
//       const updatedCompetitors = [];

//       competitors.forEach((competitor) => {
//           const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//           const revisedTrolly = row[`revised_rates_${sanitizedLabel}_trolly`];
//           const revisedFarsha = row[`revised_rates_${sanitizedLabel}_farsha`];

//           if (
//               (revisedTrolly !== undefined && revisedTrolly !== null && revisedTrolly !== 0) ||
//               (revisedFarsha !== undefined && revisedFarsha !== null && revisedFarsha !== 0)
//           ) {
//               updatedCompetitors.push({
//                   id: competitor.id,
//                   trolly: revisedTrolly !== null && revisedTrolly !== undefined ? formatNumber(revisedTrolly) : 0.0,
//                   farsha: revisedFarsha !== null && revisedFarsha !== undefined ? formatNumber(revisedFarsha) : 0.0,
//               });
//           }
//       });

//       return {
//           center: row.center_id,
//           userId: userID,
//           site: retrievedObject.user_site,
//           zone: row.zone_id,
//           circle: row.circle_id,
//           trolly: row.revised_rates_trolly ? formatNumber(row.revised_rates_trolly) : 0.0,
//           farsha: row.revised_rates_farsha ? formatNumber(row.revised_rates_farsha) : 0.0,
//           reviseFarshaDBVal: formatNumber(row.current_rates_farsha),
//           reviseTrollyDBVal: formatNumber(row.current_rates_trolly),
//           competitors: updatedCompetitors,
//       };
//   });

//   try {
//       const response = await axios.post(
//           `${API_BASE_URL}C01ProgramOfficerRequest`,
//           formattedData,
//           {
//               headers: {
//                   Authorization: `Bearer ${jwtToken}`,
//                   'Content-Type': 'application/json',
//               },
//           }
//       );

//       if (response.status === 200) {
//           // Show the success message from the API response
//           const apiMessage = response.data.DATA?.message || 'Data saved successfully!';
//           swal('Success', apiMessage, 'success');
//           resetUserEnteredValues();
//           originalEditableRowsRef.current = editableRows.map((row) => ({ ...row }));
//           setFixedRows(editableRows.map((row) => ({ ...row })));
//       } else {
//           swal('Warning', response.data.SYSTEM_MESSAGE || 'Failed to save data!', 'warning');
//       }
//   } catch (error) {
//       console.error('Error during data save:', error);
//       swal('Warning', 'Error during data save!', 'warning');
//   }
// };



//   const resetUserEnteredValues = () => {
//     setEditableRows((prevRows) => {
//       const resetRows = prevRows.map((row) => {
//         const resetRow = { ...row };
//         resetRow.revised_rates_trolly = null;
//         resetRow.revised_rates_farsha = null;

//         competitors.forEach((competitor) => {
//           const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
//           resetRow[`revised_rates_${sanitizedLabel}_trolly`] = null;
//           resetRow[`revised_rates_${sanitizedLabel}_farsha`] = null;
//         });

//         return resetRow;
//       });
//       return resetRows;
//     });
//   };

//   // Filtering both fixed and editable rows based on search input
//   const filteredFixedRows = useMemo(() => {
//     if (!searchInput) return fixedRows;

//     const searchStr = searchInput.toLowerCase();

//     return fixedRows.filter((row) => {
//       const zoneMatch = row.zone.toLowerCase().includes(searchStr);
//       const circleMatch = row.circle.toLowerCase().includes(searchStr);
//       const centerMatch = row.center.toLowerCase().includes(searchStr);
//       const currentTrollyMatch = String(row.current_rates_trolly || '').toLowerCase().includes(searchStr);
//       const currentFarshaMatch = String(row.current_rates_farsha || '').toLowerCase().includes(searchStr);

//       return zoneMatch || circleMatch || centerMatch || currentTrollyMatch || currentFarshaMatch;
//     });
//   }, [fixedRows, searchInput]);

//   const filteredEditableRows = useMemo(() => {
//     if (!searchInput) return editableRows;

//     const searchStr = searchInput.toLowerCase();

//     return editableRows.filter((row) => {
//       const zoneMatch = row.zone.toLowerCase().includes(searchStr);
//       const circleMatch = row.circle.toLowerCase().includes(searchStr);
//       const centerMatch = row.center.toLowerCase().includes(searchStr);
//       const currentTrollyMatch = String(row.current_rates_trolly || '').toLowerCase().includes(searchStr);
//       const currentFarshaMatch = String(row.current_rates_farsha || '').toLowerCase().includes(searchStr);

//       return zoneMatch || circleMatch || centerMatch || currentTrollyMatch || currentFarshaMatch;
//     });
//   }, [editableRows, searchInput]);

//   const memoizedFixedColumns = useMemo(() => fixedColumns, [fixedColumns]);
//   const memoizedScrollableColumns = useMemo(() => scrollableColumns, [scrollableColumns]);
//   const memoizedFixedGroupingModel = useMemo(() => fixedColumnGroupingModel, [fixedColumnGroupingModel]);
//   const memoizedScrollableGroupingModel = useMemo(() => scrollableGroupingModel, [scrollableGroupingModel]);

//   // Loader Overlay
//   const LoaderOverlay = () => (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         height: '100%',
//         backgroundColor: 'rgba(255, 255, 255, 0.8)',
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         zIndex: 9999,
//       }}
//     >
//       <img
//         src={loaderImage}
//         alt="Loading..."
//         style={{
//           width: '100px',
//           height: '100px',
//           borderRadius: '50%',
//           animation: 'spin 2s linear infinite',
//         }}
//       />
//       <style>
//         {`
//           @keyframes spin {
//             0% { transform: rotate(0deg); }
//             100% { transform: rotate(360deg); }
//           }
//         `}
//       </style>
//     </Box>
//   );

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <Drawer
//         sx={{
//           width: drawerWidth,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//             width: drawerWidth,
//             boxSizing: 'border-box',
//           },
//         }}
//         variant="persistent"
//         anchor="left"
//         open={open}
//       >
//         <SideBar isOpen={open} />
//       </Drawer>

//       <Main open={open}>
//         <TopBar toggleSidebar={() => setOpen(!open)} />

//         <Box sx={{ padding: '20px', flex: '1', overflowY: 'auto' }}>
//           <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '10px' }}>
//             <Link color="inherit" href="/dashboard">
//               Home
//             </Link>
//             <Typography color="textPrimary">New Request</Typography>
//           </Breadcrumbs>

//           <ToastContainer />


//           {/* Added Search Field */}
//           <Grid item xs={12}>
//             <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
//               <TextField
//                 label="Search by Zone, Circle, or Center"
//                 variant="outlined"
//                 value={searchInput}
//                 onChange={(e) => setSearchInput(e.target.value)}
//                 size="small"
//                 sx={{ width: '300px' }}
//               />
//               {/* Optional: Add a clear button */}
//               {searchInput && (
//                 <Button
//                   variant="text"
//                   onClick={() => setSearchInput('')}
//                   sx={{ marginLeft: '10px' }}
//                 >
//                   Clear
//                 </Button>
//               )}
//             </Box>

//             <Grid item xs={12}>
//               <Paper
//                 sx={{
//                   boxShadow: 'none',
//                   borderRadius: '0',
//                   border: '1px solid #D9D9D9',
//                   display: 'grid',
//                   width: '100%',
//                   position: 'relative', // Position for loader overlay
//                 }}
//               >
//                 <div style={{  overflow: 'hidden', zIndex: 0 }}>
//                   <div style={{ display: 'flex' }}>
//                     <div
//                       style={{
//                         overflow: 'hidden',
//                         width: fixedColumns.reduce((sum, col) => sum + (col.width || 150), 0),
//                       }}
//                     >
//                       <FixedTable
//                         rows={filteredFixedRows}
//                         columns={memoizedFixedColumns}
//                         columnGroupingModel={memoizedFixedGroupingModel}
//                       />
//                     </div>

//                     <div style={{ flexGrow: 1, overflowX: 'auto', width: '50%' }} ref={scrollableTableRef}>
//                       <ScrollableTable
//                         rows={filteredEditableRows} // Updated to use filteredEditableRows
//                         columns={memoizedScrollableColumns}
//                         columnGroupingModel={memoizedScrollableGroupingModel}
//                         onRowUpdate={handleRowUpdate}
//                       />
//                     </div>
//                   </div>
//                 </div>
//                 {isLoading && <LoaderOverlay />} {/* Show loader only over the table */}
//               </Paper>

//               <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px' }}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleSubmit}
//                   sx={{
//                     background: 'linear-gradient(to right, #a8e063, #56ab2f)',
//                     color: 'white',
//                   }}
//                 >
//                   Submit
//                 </Button>
//               </div>
//             </Grid>
//           </Grid>
//         </Box>
//       </Main>
//     </Box>
//   );
// };

// export default Requests;



// Requests.js
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import SideBar from './SideBar';
import TopBar from './TopBar';
import '../Styles/Dashboard.css';
import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Breadcrumbs, Typography, Link, TextField } from '@mui/material';
import API_BASE_URL from '../config';
import FixedTable from './FixedTable';
import ScrollableTable from './ScrollableTable';
import loaderImage from '../assects/Images/Logos/CRVlogo.png';

const drawerWidth = 240;

// Styled main content area
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const Requests = () => {
  const getObjectFromLocal = (key) => {
    const jsonString = localStorage.getItem(key);
    return JSON.parse(jsonString);
  };

  const retrievedObject = getObjectFromLocal('UserDetailData') || {};
  const jwtToken = retrievedObject.jwt_token;
  const userID = retrievedObject.user_id;

  const [open, setOpen] = useState(true);
  const [fixedColumns, setFixedColumns] = useState([]);
  const [scrollableColumns, setScrollableColumns] = useState([]);
  const [fixedRows, setFixedRows] = useState([]);
  const [editableRows, setEditableRows] = useState([]);
  const [fixedColumnGroupingModel, setFixedColumnGroupingModel] = useState([]);
  const [scrollableGroupingModel, setScrollableGroupingModel] = useState([]);
  const [competitors, setCompetitors] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [searchInput, setSearchInput] = useState('');
  const [lastEditedCell, setLastEditedCell] = useState(null);
  const scrollableTableRef = useRef(null);
  const originalEditableRowsRef = useRef([]);

  // 1. Define generateColumnsForCompetitors
  const generateColumnsForCompetitors = useCallback((competitors) => {
    // Define fixed columns
    const fixedCols = [
      {
        field: 'zone',
        headerName: 'Zone',
        headerClassName: 'zone-color',
        headerAlign: 'center',
        width: 120,
        resizable: false,
      },
      {
        field: 'circle',
        headerName: 'Circle',
        headerClassName: 'zone-color',
        headerAlign: 'center',
        width: 120,
        resizable: false,
      },
      {
        field: 'center',
        headerName: 'Center',
        headerClassName: 'zone-color',
        headerAlign: 'center',
        width: 120,
        resizable: false,
      },
      {
        field: 'current_rates_trolly',
        headerName: 'Trolly',
        type: 'number',
        headerClassName: 'colum-color',
        headerAlign: 'center',
        width: 100,
        resizable: false,
      },
      {
        field: 'current_rates_farsha',
        headerName: 'Farsha',
        type: 'number',
        headerClassName: 'colum-color',
        headerAlign: 'center',
        width: 100,
        resizable: false,
      },
    ];

    // Define scrollable columns
    let scrollableCols = [
      {
        field: 'revised_rates_trolly',
        headerName: 'Trolly',
        type: 'number',
        headerClassName: 'colum-color',
        headerAlign: 'center',
        editable: true,
        width: 100,
        resizable: false,
        valueParser: (value) => parseFloat(value),
      },
      {
        field: 'revised_rates_farsha',
        headerName: 'Farsha',
        type: 'number',
        headerClassName: 'colum-color',
        headerAlign: 'center',
        editable: true,
        width: 100,
        resizable: false,
        valueParser: (value) => parseFloat(value),
      },
    ];

    // Dynamically add competitor-specific columns with unique field names
    competitors.forEach((competitor) => {
      const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
      scrollableCols.push(
        {
          field: `revised_rates_${competitor.id}_${sanitizedLabel}_trolly`,
          headerName: 'Trolly',
          type: 'number',
          headerClassName: 'colum-color',
          headerAlign: 'center',
          editable: true,
          width: 100,
          resizable: false,
          valueParser: (value) => parseFloat(value),
        },
        {
          field: `revised_rates_${competitor.id}_${sanitizedLabel}_farsha`,
          headerName: 'Farsha',
          type: 'number',
          headerClassName: 'colum-color',
          headerAlign: 'center',
          editable: true,
          width: 100,
          resizable: false,
          valueParser: (value) => parseFloat(value),
        }
      );
    });

    // Define column grouping for fixed columns
    const fixedGroupingModel = [
      {
        groupId: 'details',
        headerName: 'Details',
        children: [{ field: 'zone' }, { field: 'circle' }, { field: 'center' }],
        headerClassName: 'zone-color',
        headerAlign: 'center',
      },
      {
        groupId: 'currentRates',
        headerName: 'Current Rates',
        children: [{ field: 'current_rates_trolly' }, { field: 'current_rates_farsha' }],
        headerClassName: 'rate-color-bg',
        headerAlign: 'center',
      },
    ];

    // Define column grouping for scrollable grid with unique fields
    const scrollableGroupingModel = [
      {
        groupId: 'revisedRates',
        headerName: 'Revised Rates',
        children: [{ field: 'revised_rates_trolly' }, { field: 'revised_rates_farsha' }],
        headerClassName: 'rate-color-bg',
        headerAlign: 'center',
      },
      // Dynamically add grouping for each competitor with unique field names
      ...competitors.map((competitor) => ({
        groupId: `competitor_${competitor.id}`, // Use competitor.id for unique groupId
        headerName: competitor.label,
        children: [
          { field: `revised_rates_${competitor.id}_${competitor.label.replace(/\s+/g, '_').toLowerCase()}_trolly` },
          { field: `revised_rates_${competitor.id}_${competitor.label.replace(/\s+/g, '_').toLowerCase()}_farsha` },
        ],
        headerClassName: 'zone-color2',
        headerAlign: 'center',
      })),
    ];

    // Update state with generated columns and grouping models
    setFixedColumns(fixedCols);
    setScrollableColumns(scrollableCols);
    setFixedColumnGroupingModel(fixedGroupingModel);
    setScrollableGroupingModel(scrollableGroupingModel);
  }, []);

  // 2. Define generateRowsForData
  const generateRowsForData = useCallback((data) => {
    const { centers, competitors } = data || { centers: [], competitors: [] };
    let dynamicRows = [];

    if (!Array.isArray(centers)) {
      console.error('Centers data is not an array:', centers);
      return [];
    }

    centers.forEach((center) => {
      let row = {
        id: center.id, // Use center.id as unique and stable id
        zone_id: center.zone_id,
        circle_id: center.circle_id,
        center_id: center.id,
        zone: center.zone_label,
        circle: center.circle_label,
        center: center.label,
        current_rates_trolly: center.current_trolly_rate,
        current_rates_farsha: center.current_farsha_rate,
        revised_rates_trolly: center.revised_trolly_rate || null,
        revised_rates_farsha: center.revised_farsha_rate || null,
      };

      competitors.forEach((competitor) => {
        const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();

        row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`] = center[`revised_rates_${sanitizedLabel}_trolly`] || null;
        row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`] = center[`revised_rates_${sanitizedLabel}_farsha`] || null;
      });

      dynamicRows.push(row);
    });

    return dynamicRows;
  }, []);

  // 3. Define useEffect after helper functions
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Start loading

        const response = await axios.get(`${API_BASE_URL}C01ProgramOfficerGetCentersData`, {
          headers: {
            Authorization: `Bearer ${jwtToken}`, 
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          const fetchedData = response.data.DATA;
          const userDetailData = JSON.parse(localStorage.getItem('UserDetailData')) || {};

          userDetailData.centers = fetchedData.centers;
          userDetailData.competitors = fetchedData.competitors;

          localStorage.setItem('UserDetailData', JSON.stringify(userDetailData));

          setCompetitors(fetchedData.competitors);
          setLoading(false);

          generateColumnsForCompetitors(fetchedData.competitors);
          const generatedRows = generateRowsForData(fetchedData);
          setFixedRows(generatedRows.map((row) => ({ ...row })));
          setEditableRows(generatedRows.map((row) => ({ ...row })));
          originalEditableRowsRef.current = generatedRows.map((row) => ({ ...row }));
        } else {
          console.error('API call failed:', response.data.SYSTEM_MESSAGE);
          toast.error('Failed to fetch data from the server.', { autoClose: 5000 });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data. Please try again later.', { autoClose: 5000 });
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchData();
  }, [jwtToken, generateColumnsForCompetitors, generateRowsForData]); // Ensure dependencies are correct

  useEffect(() => {
    if (lastEditedCell && scrollableTableRef.current) {
      const cell = scrollableTableRef.current.querySelector(
        `[data-id='${lastEditedCell.id}'] [data-field='${lastEditedCell.field}']`
      );
      if (cell) {
        cell.scrollIntoView({ behavior: 'smooth', block: 'center' });
        // cell.focus();
      }
    }
  }, [lastEditedCell]);

  // 4. Define other functions (handleRowUpdate, handleSubmit, etc.)

  const handleRowUpdate = useCallback((newRow) => {
    console.log('handleRowUpdate called with:', newRow); // Debugging
    setEditableRows((prevRows) => {
      const index = prevRows.findIndex((row) => row.id === newRow.id);
      if (index === -1) return prevRows;
      const updatedRows = [...prevRows];
      updatedRows[index] = newRow;

      setLastEditedCell({
        id: newRow.id,
        field: Object.keys(newRow).find((key) => newRow[key] !== prevRows[index][key]),
      });

      console.log('Updated Rows:', updatedRows); // Debugging
      return updatedRows;
    });
    return newRow;
  }, []);

  const formatNumber = (value) => {
    const floatValue = parseFloat(value);
    if (Number.isNaN(floatValue)) {
      return 0.0;
    }
    if (Number.isInteger(floatValue)) {
      return parseFloat(floatValue.toFixed(1));
    }
    return floatValue;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Find rows that have changed by comparing editableRows with originalEditableRowsRef
    const updatedRowsToProcess = editableRows.filter((row) => {
      const originalRow = originalEditableRowsRef.current.find((origRow) => origRow.id === row.id);

      // Check if row has no changes or if the user deleted the data
      const hasRevisedRates = (row.revised_rates_trolly && row.revised_rates_trolly > 0) ||
        (row.revised_rates_farsha && row.revised_rates_farsha > 0);

      const hasCompetitorRates = competitors.some((competitor) => {
        const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
        return (
          (row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`] &&
            row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`] > 0) ||
          (row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`] &&
            row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`] > 0)
        );
      });

      // If the row has no revised rates and no competitor rates, exclude it from submission
      if (!hasRevisedRates && !hasCompetitorRates) {
        return false;
      }

      // Only include rows that are actually different from the original rows
      return JSON.stringify(row) !== JSON.stringify(originalRow);
    });

    if (updatedRowsToProcess.length === 0) {
      swal('No changes to submit.', '', 'warning');
      return;
    }

    let isValid = true;

    // Validation logic for negative values
    for (let row of updatedRowsToProcess) {
      // Check if revised rates are negative
      if (
        (row.revised_rates_trolly && parseFloat(row.revised_rates_trolly) < 0) ||
        (row.revised_rates_farsha && parseFloat(row.revised_rates_farsha) < 0)
      ) {
        isValid = false;
        swal('Error', 'Revised rates cannot be negative.', 'error');
        break;
      }

      // Check if any competitor's rates are negative
      const competitorRatesNegative = competitors.some((competitor) => {
        const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
        return (
          (row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`] &&
            parseFloat(row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`]) < 0) ||
          (row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`] &&
            parseFloat(row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`]) < 0)
        );
      });

      if (competitorRatesNegative) {
        isValid = false;
        swal('Error', 'Competitor rates cannot be negative.', 'error');
        break;
      }

      // Additional validation: Ensure that revised rates and competitor rates are entered together
      const revisedRatesEntered =
        (row.revised_rates_trolly && parseFloat(row.revised_rates_trolly) > 0) ||
        (row.revised_rates_farsha && parseFloat(row.revised_rates_farsha) > 0);

      const competitorRatesEntered = competitors.some((competitor) => {
        const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
        return (
          (row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`] &&
            parseFloat(row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`]) > 0) ||
          (row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`] &&
            parseFloat(row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`]) > 0)
        );
      });

      if (revisedRatesEntered && !competitorRatesEntered) {
        isValid = false;
        swal('Error', 'Please enter at least one competitor rate when entering Revised Rate Trolly or Farsha.', 'error');
        break;
      }

      if (competitorRatesEntered && !revisedRatesEntered) {
        isValid = false;
        swal('Error', 'Please enter Revised Rate Trolly or Farsha when entering competitor rates.', 'error');
        break;
      }
    }

    if (!isValid) {
      return; // Stop submission if validation fails
    }

    // Confirmation before submission
    swal({
      title: 'Are you sure?',
      text: 'Once submitted, you will not be able to recover this data!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willSubmit) => {
      if (willSubmit) {
        saveData(updatedRowsToProcess);
      }
    });
  };

  const saveData = async (changedRows) => {
    const formattedData = changedRows.map((row) => {
      const updatedCompetitors = [];

      competitors.forEach((competitor) => {
        const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
        const revisedTrolly = row[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`];
        const revisedFarsha = row[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`];

        if (
          (revisedTrolly !== undefined && revisedTrolly !== null && revisedTrolly !== 0) ||
          (revisedFarsha !== undefined && revisedFarsha !== null && revisedFarsha !== 0)
        ) {
          updatedCompetitors.push({
            id: competitor.id,
            trolly: revisedTrolly !== null && revisedTrolly !== undefined ? formatNumber(revisedTrolly) : 0.0,
            farsha: revisedFarsha !== null && revisedFarsha !== undefined ? formatNumber(revisedFarsha) : 0.0,
          });
        }
      });

      return {
        center: row.center_id,
        userId: userID,
        site: retrievedObject.user_site,
        zone: row.zone_id,
        circle: row.circle_id,
        trolly: row.revised_rates_trolly ? formatNumber(row.revised_rates_trolly) : 0.0,
        farsha: row.revised_rates_farsha ? formatNumber(row.revised_rates_farsha) : 0.0,
        reviseFarshaDBVal: formatNumber(row.current_rates_farsha),
        reviseTrollyDBVal: formatNumber(row.current_rates_trolly),
        competitors: updatedCompetitors,
      };
    });

    try {
      const response = await axios.post(
        `${API_BASE_URL}C01ProgramOfficerRequest`,
        formattedData,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 200) {
        // Show the success message from the API response
        const apiMessage = response.data.DATA?.message || 'Data saved successfully!';
        swal('Success', apiMessage, 'success');
        resetUserEnteredValues();
        originalEditableRowsRef.current = editableRows.map((row) => ({ ...row }));
        setFixedRows(editableRows.map((row) => ({ ...row })));
      } else {
        swal('Warning', response.data.SYSTEM_MESSAGE || 'Failed to save data!', 'warning');
      }
    } catch (error) {
      console.error('Error during data save:', error);
      swal('Warning', 'Error during data save!', 'warning');
    }
  };

  const resetUserEnteredValues = () => {
    setEditableRows((prevRows) => {
      const resetRows = prevRows.map((row) => {
        const resetRow = { ...row };
        resetRow.revised_rates_trolly = null;
        resetRow.revised_rates_farsha = null;

        competitors.forEach((competitor) => {
          const sanitizedLabel = competitor.label.replace(/\s+/g, '_').toLowerCase();
          resetRow[`revised_rates_${competitor.id}_${sanitizedLabel}_trolly`] = null;
          resetRow[`revised_rates_${competitor.id}_${sanitizedLabel}_farsha`] = null;
        });

        return resetRow;
      });
      return resetRows;
    });
  };

  // Filtering both fixed and editable rows based on search input
  const filteredFixedRows = useMemo(() => {
    if (!searchInput) return fixedRows;

    const searchStr = searchInput.toLowerCase();

    return fixedRows.filter((row) => {
      const zoneMatch = row.zone.toLowerCase().includes(searchStr);
      const circleMatch = row.circle.toLowerCase().includes(searchStr);
      const centerMatch = row.center.toLowerCase().includes(searchStr);
      const currentTrollyMatch = String(row.current_rates_trolly || '').toLowerCase().includes(searchStr);
      const currentFarshaMatch = String(row.current_rates_farsha || '').toLowerCase().includes(searchStr);

      return zoneMatch || circleMatch || centerMatch || currentTrollyMatch || currentFarshaMatch;
    });
  }, [fixedRows, searchInput]);

  const filteredEditableRows = useMemo(() => {
    if (!searchInput) return editableRows;

    const searchStr = searchInput.toLowerCase();

    return editableRows.filter((row) => {
      const zoneMatch = row.zone.toLowerCase().includes(searchStr);
      const circleMatch = row.circle.toLowerCase().includes(searchStr);
      const centerMatch = row.center.toLowerCase().includes(searchStr);
      const currentTrollyMatch = String(row.current_rates_trolly || '').toLowerCase().includes(searchStr);
      const currentFarshaMatch = String(row.current_rates_farsha || '').toLowerCase().includes(searchStr);

      return zoneMatch || circleMatch || centerMatch || currentTrollyMatch || currentFarshaMatch;
    });
  }, [editableRows, searchInput]);

  const memoizedFixedColumns = useMemo(() => fixedColumns, [fixedColumns]);
  const memoizedScrollableColumns = useMemo(() => scrollableColumns, [scrollableColumns]);
  const memoizedFixedGroupingModel = useMemo(() => fixedColumnGroupingModel, [fixedColumnGroupingModel]);
  const memoizedScrollableGroupingModel = useMemo(() => scrollableGroupingModel, [scrollableGroupingModel]);

  // Loader Overlay
  const LoaderOverlay = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: 9999,
      }}
    >
      <img
        src={loaderImage}
        alt="Loading..."
        style={{
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          animation: 'spin 2s linear infinite',
        }}
      />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <SideBar isOpen={open} />
      </Drawer>

      <Main open={open}>
        <TopBar toggleSidebar={() => setOpen(!open)} />

        <Box sx={{ padding: '20px', flex: '1', overflowY: 'auto' }}>
          <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '10px' }}>
            <Link color="inherit" href="/dashboard">
              Home
            </Link>
            <Typography color="textPrimary">New Request</Typography>
          </Breadcrumbs>

          <ToastContainer />

          {/* Added Search Field */}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '20px' }}>
              <TextField
                label="Search by Zone, Circle, or Center"
                variant="outlined"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                size="small"
                sx={{ width: '300px' }}
              />
              {/* Optional: Add a clear button */}
              {searchInput && (
                <Button
                  variant="text"
                  onClick={() => setSearchInput('')}
                  sx={{ marginLeft: '10px' }}
                >
                  Clear
                </Button>
              )}
            </Box>

            <Grid item xs={12}>
              <Paper
                sx={{
                  boxShadow: 'none',
                  borderRadius: '0',
                  border: '1px solid #D9D9D9',
                  display: 'grid',
                  width: '100%',
                  position: 'relative', // Position for loader overlay
                }}
              >
                <div style={{ overflow: 'hidden', zIndex: 0 }}>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        overflow: 'hidden',
                        width: fixedColumns.reduce((sum, col) => sum + (col.width || 150), 0),
                      }}
                    >
                      <FixedTable
                        rows={filteredFixedRows}
                        columns={memoizedFixedColumns}
                        columnGroupingModel={memoizedFixedGroupingModel}
                      />
                    </div>

                    <div style={{ flexGrow: 1, overflowX: 'auto', width: '50%' }} ref={scrollableTableRef}>
                      <ScrollableTable
                        rows={filteredEditableRows}
                        columns={memoizedScrollableColumns}
                        columnGroupingModel={memoizedScrollableGroupingModel}
                        onRowUpdate={handleRowUpdate}
                      />
                    </div>
                  </div>
                </div>
                {isLoading && <LoaderOverlay />} {/* Show loader only over the table */}
              </Paper>

              <div style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{
                    background: 'linear-gradient(to right, #a8e063, #56ab2f)',
                    color: 'white',
                  }}
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Main>
    </Box>
  );
};

export default Requests;
